import Axios from 'axios'
import appConfig from "./config"
import history from "services/history"
import jwt_decode from "jwt-decode";
import { logoutWhenTokenExp } from "services/logoutTokenExp";


let baseUrl = appConfig.apiUrl;
let privateApiUrl = appConfig.privateApiUrl;

let axios = Axios.create({
  withCredentials: true,
  baseURL: privateApiUrl,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

// Add request interceptor for authentication
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `jwt ${accessToken}`;
    }
    // Language
    const language = localStorage.getItem("language", "fi");
    config.headers["Accept-Language"] = language
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for token refresh handling
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // ... you can copy the token refresh logic from your api.js if needed ...
    return Promise.reject(error);
  }
);

/*
//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/refresh_token`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/auth/logout`, body);
  },
  getProtected: () => {
    return axios.get(`${baseUrl}/protected_resource`);
  },
};
*/

export default axios;
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';

import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { TableAuto } from "stories/tables"

import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"

import { getDate } from "services/datetime"

import { roundScaleDecimals } from "services/helpers"
import { date, ref } from "yup";





//use  noDefault={true}appcontext to get user data



const ImportChecktTransactionsV2 = ({match, history, checkTransactions, setCheckTransactions, basicDataRows, setBasicDataRows, setExchange_transactions, exchange_transactions, model="Transaction" }) => {
  
  const { t } = useTranslation();
  const { getTransactionType } = useAppContext()
  
  const [noSplitFound, setNoSplitFound] = useState(checkTransactions?.no_split_found)
  const [already_exists_younger_transactions, setAlready_exists_younger_transactions] = useState(checkTransactions?.already_exists_younger_transactions)
  const [already_imported_transactions, setAlready_imported_transactions] = useState(checkTransactions?.already_imported_transactions)
  const [allSplits, setAllSplits] = useState(checkTransactions?.all_splits)
  const [batchSalesOver, setBatchSalesOver] = useState(checkTransactions?.batch_sales_over)
  const [machingSplitFound, setMachingSplitFound] = useState(checkTransactions?.maching_splits_found)
  const [noMachingSplitFound, setNoMachingSplitFound] = useState(checkTransactions?.no_maching_splits_found)
//   const [exchange_transactions, setExchange_transactions] = useState(checkTransactions?.exchange_transactions)
  // const loading = useStoreState((state) => state.portfolio?.loading);

  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()


  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })

  useEffect(() => {
    setExchange_transactions(checkTransactions?.exchange_transactions)

  }, [checkTransactions?.exchange_transactions])

  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }

  const handleInvestmentTypeChange = (index) => {
    return (e) => {
      const { value } = e.target;
      const newRows = [...basicDataRows];
      newRows[index].security_type = value;
      setBasicDataRows(newRows);
    }
  }

  const handleSizeInputChange = (index, index2, sum) => {
    return (e) => {
      const { value } = e.target;
      const newRows = [...exchange_transactions];
      newRows[index].exchange_in_transactions[index2].new_purchase_cost_percentage = value / sum *100
      newRows[index].exchange_in_transactions[index2].new_purchase_cost = value;
      setExchange_transactions(newRows);
    }
  }
  const handlePercentageInputChange = (index, index2, sum) => {
    return (e) => {
      const { value } = e.target;
      const newRows = [...exchange_transactions];
      newRows[index].exchange_in_transactions[index2].new_purchase_cost = value * sum / 100;
      newRows[index].exchange_in_transactions[index2].new_purchase_cost_percentage = value;
      setExchange_transactions(newRows);
    }
  }

  const getDataFromAllSplits = (security, splitId, inv1_event_number, searchedData) => {
    const split_transactions = allSplits?.[security]?.[splitId]
    //filter split transaction by inv1_event_number
    const searchedValue = split_transactions?.filter((item) => item?.inv1_event_number === inv1_event_number)?.[0]?.[searchedData]
    return searchedValue
  }

  //------Headers-------

  const getHeadersBatchSalesOver = (param) => {
    const headers = [
      { label: t("importTransactionsV2_batch_sales_over_batch", "ID"), key: "batch"},
      { label: t("importTransactionsV2_batch_sales_over_security", "Arvopaperi"), key: "security"},
      { label: t("importTransactionsV2_batch_sales_over_original_batch_balance", "Balance"), key: "batch_size"},
      { label: t("importTransactionsV2_batch_sales_over_all_sales", "Kaikki myynnit"), key: "all_sales"},
    ];
    return headers
  }

  const getHeadersExchangeTransactions = (param) => {
    const headers = [
        { label: t("importTransactionsV2_exchange_transactions_event_number", "Vaihto ulos Id"), key: "reference_code"},
        { label: t("importTransactionsV2_exchange_transactions_name", "Arvopaperi"), key: "security"},
        { label: t("importTransactionsV2_exchange_transactions_date", "PVM"), key: "date_transaction"},
        { label: t("importTransactionsV2_exchange_transactions_id_in", "Vaihto sisään ID"), key: "id_in"},
        { label: t("importTransactionsV2_exchange_transactions_name_in", "Arvopaperi (sisään)"), key: "security_in"},
        { label: t("importTransactionsV2_exchange_transactions_quantity_in", "Vaihto sisään määrä"), key: "quantity_in"},
        { label: t("importTransactionsV2_exchange_transactions_purchase_cost", "Hankintameno"), key: "purchase_cost"},
        { label: t("importTransactionsV2_exchange_transactions_purchase_cost_new", "Uusi Hankintameno"), key: "new_purchase_cost"},
        { label: t("importTransactionsV2_exchange_transactions_purchase_cost_new_percentage", "Hankintameno %"), key: "new_purchase_cost_percentage"},
    ]
    return headers
  }

  const getHeaderImportedTransactions = (param) => {
    const headers = [
        { label: t("importTransactionsV2_imported_transactions_event_number", "id"), key: "reference_code"},
        { label: t("importTransactionsV2_imported_transactions_type", "Symbol"), key: "ticker_symbol"},
        { label: t("importTransactionsV2_imported_transactions_name", "Pvm"), key: "date_transaction"},
        
    ]
    return headers
  }

  const getHeaderYougerTransactions = (param) => {
      const headers = [

          { label: t("importTransactionsV2_imported_transactions_event_number", "Symbol"), key: "security"},
          { label: t("importTransactionsV2_imported_transactions_name", "Vanhin tuotava kirjaus"), key: "oldest_import"},
          { label: t("importTransactionsV2_imported_transactions_type", "Viimeisin salkussa"), key: "youngest_portfolio"},
          
      ]
      return headers
  }

  const getHeadersNoSplits = (param) => {
    const headers = [
      { label: t("importTransactionsV2_no_splits_event_number", "Tapahtuma num."), key: "inv1_event_number"},
        { label: t("importTransactionsV2_no_splits_name", "Arvopaperi"), key: "security"},
        { label: t("importTransactionsV2_no_splits_date", "PVM"), key: "date"},
        { label: t("importTransactionsV2_no_splits_splitted_quantity", "Splitattu määrä"), key: "Splmaara"},
        { label: t("importTransactionsV2_no_splits_quantity", "Määrä ennen splittiä"), key: "quantity"},
        
    ];
    return headers
  }

  const getHeadersMachingSplits = (param) => {
    const headers = [
      { label: t("importTransactionsV2_maching_splits_event_number", "Tapahtuma num."), key: "inv1_event_number"},
      { label: t("importTransactionsV2_maching_splits_split_id", "Split ID"), key: "split_id"},
      { label: t("importTransactionsV2_maching_splits_split_boolean", "Splittaus"), key: "splitted"},
      { label: t("importTransactionsV2_maching_splits_type", "Tyyppi"), key: "type"},
      { label: t("importTransactionsV2_maching_splits_name", "Arvopaperi"), key: "security"},
      { label: t("importTransactionsV2_maching_splits_date", "PVM"), key: "date"},
      { label: t("importTransactionsV2_maching_splits_quantity", "alkp. määrä"), key: "quantity"},
      { label: t("importTransactionsV2_maching_splits_splitted_quantity", "Splitattu määrä"), key: "Splmaara"},
      { label: t("importTransactionsV2_maching_splits_splid_dividend", "Split luku"), key: "split_dividend"},
      { label: t("importTransactionsV2_maching_splits_date_effective", "Split pvm"), key: "split_date"},
      { label: t("importTransactionsV2_maching_splits_tolerance", "Toleranssi*"), key: "tolerance"},
    ];
    return headers
  }

  
  
  // ------ Get Rows-------

  const getTableRowsBatchSalesOver = useCallback(() => {
    const new_rows = []

    if (batchSalesOver) {
      let new_row = {}

      for (const key in batchSalesOver) {
        const value = batchSalesOver[key];
        new_row.security = key
        value && value.forEach((row, index) => {
          new_row.Split_happened = row?.Split_happened
          new_row.batch = row?.batch
          new_row.batch_size = row?.batch_size
          new_row.next_sale_size = row?.next_sale_size
          new_row.date_effective = row?.date_effective
          new_row.split_id = row?.split_id
          new_row.current_transaction = row?.current_transaction
          new_row.all_sales = row?.all_sales
        })  
        new_rows.push(new_row)
      }
    }
    
    return new_rows.map((item, index) => {
      return {
        ...item,
        Split_happened: item?.Split_happened ? t("yes", "Kyllä") : t("no", "Ei"),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(item?.id)}>
              {t("edit_button","Muokkaa")}
            </Button>
          </div>
        ),
      };
    });
  }, [batchSalesOver])



  
  const getTableRowsNoSplits = useCallback(() => {
    return noSplitFound && noSplitFound.map((row, index) => {
      const transactionTypeValue = getTransactionType(row?.type)?.value
      let transactionTypeCell;
      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }
      return {
        ...row,
        type: transactionTypeCell,
        date: getDate(row?.date),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("edit_button","Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [noSplitFound])


  const getTableRowsExchangeTransactions = useCallback(() => {
    const rows_to_return = []

    exchange_transactions && exchange_transactions?.forEach((row, index) => {
      const transactionTypeValue = getTransactionType(row?.type)?.value
      const exchange_in_transactions = row?.exchange_in_transactions
      const exchange_out_transaction = row?.exchange_out_transaction
      const purchase_cost_total_sum = row?.purchase_cost_total_sum
      let new_pusrchase_cost_total_sum = 0
      let new_purchase_cost_percentage_sum = 0
      let new_pusrchase_cost_correct = false
      let transactionTypeCell;
      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }

      if (exchange_in_transactions && exchange_in_transactions.length > 0) {

       for (const key in exchange_in_transactions) {
            const item = exchange_in_transactions[key];
            const value = Number(item?.new_purchase_cost) || 0
            new_pusrchase_cost_total_sum = new_pusrchase_cost_total_sum + value
            new_purchase_cost_percentage_sum = new_purchase_cost_percentage_sum + (Number(item?.new_purchase_cost_percentage) || 0)
        }

        new_pusrchase_cost_correct = Math.abs( new_pusrchase_cost_total_sum - purchase_cost_total_sum) < 0.0001 ? true : false


        exchange_in_transactions.forEach((item, index2) => {
          rows_to_return.push( {
            // ...item,
            type: transactionTypeCell,
            date: getDate(item?.date_transaction),
            reference_code: exchange_out_transaction?.reference_code,
            security: <>{exchange_out_transaction?.ticker_symbol}</>,
            // id: item?.exchange_in_transaction?.reference_code,
            id_in: item?.reference_code,
            date_transaction: getDate(item?.date_transaction),
            quantity_in: item?.quantity,
            purchase_cost: item?.purchase_cost_total,
            security_in: <>{item?.ticker_symbol}</>,
            new_purchase_cost: (
                <>
                <Input 
                    name="size" 
                    onChange={ handleSizeInputChange(index, index2, purchase_cost_total_sum)} 
                    defaultValue={0}
                    value={exchange_in_transactions[index2]?.new_purchase_cost}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    disabled={false}
                    placeholder={t("importTransactionsV2_purchase_cost", t("hankintameno"))}
                    className={Math.abs( new_pusrchase_cost_total_sum - purchase_cost_total_sum) > 0.0001 ? "bg-danger" : ""}
                />
                </>
            ),
            new_purchase_cost_percentage: (
                <>
                <Input 
                    name="size" 
                    onChange={ handlePercentageInputChange(index, index2, purchase_cost_total_sum)} 
                    defaultValue={0}
                    value={exchange_in_transactions[index2]?.new_purchase_cost_percentage}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    disabled={false}
                    placeholder={t("%", t("%"))}
                    className={Math.abs( new_pusrchase_cost_total_sum - purchase_cost_total_sum) > 0.0001 ? "bg-danger" : ""}
                />
                </>
            ),
          }
            )
        })

        rows_to_return.push({

            quantity_in: {
                content: <span className="font-weight-bold text-right">{t("yht.")}</span>,
                className: "text-right"
              },
            purchase_cost: <span className="font-weight-bold">{purchase_cost_total_sum}</span>,
            new_purchase_cost: <span className="font-weight-bold">{roundScaleDecimals(new_pusrchase_cost_total_sum,2)} / {purchase_cost_total_sum}</span>,
            new_purchase_cost_percentage: <span className="font-weight-bold">{roundScaleDecimals(new_purchase_cost_percentage_sum,2)} / 100</span>,

          }
        )
        //empty row
        rows_to_return.push({

        })

        
      } 

      else {
        rows_to_return.push({
            ...row,
            type: transactionTypeCell,
            date: getDate(row?.date_transaction),
            reference_code: row?.exchange_out_transaction?.reference_code,
            // id: row?.exchange_out_transaction?.reference_code,
            actions: (
              <div className="d-flex justify-content-end">
                <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("edit_button","Muokkaa")}</Button>
              </div>
            ),
          }
        )
      }


    })

    return rows_to_return
  }, [exchange_transactions])

  const getTableRowsMachingSplits = useCallback((trx_splits) => {
    const new_rows = [];
  
    trx_splits && trx_splits.forEach((row) => {
      const mach_splits = row?.maching_splits;
      const rejected_splits = row?.splits_after_trx;

      const no_maching_splits_found = noMachingSplitFound?.filter((item) => item?.inv1_event_number === row?.inv1_event_number)?.[0]?.splits_after_trx

      const transactionTypeValue = getTransactionType(row?.type)?.value
      let transactionTypeCell;
      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className=" ">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "20") {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }

      mach_splits && mach_splits?.forEach((split) => {
        new_rows.push({
          ...row,
          date: getDate(row?.date),
          type: transactionTypeCell,
          tolerance: roundScaleDecimals(row?.tolerance,2),
          splitted: (<span className="text-success">{t('Kyllä')}</span>),
          split_id: split?.id,
          split_dividend: split?.dividend,
          split_date: getDate(split?.date_effective),
          batch_balance: roundScaleDecimals(getDataFromAllSplits(row?.security, split?.id, row?.inv1_event_number,"batch_balance"),8),
          orignal_batch_balance: roundScaleDecimals(getDataFromAllSplits(row?.security, split?.id, row?.inv1_event_number,"original_batch_balance"),8), 
        });
      });

      //filter rejected splits if item id is not in maching splits
      rejected_splits && rejected_splits?.forEach((split) => {
        if (!mach_splits?.find((item) => item?.id === split?.id)) {
          new_rows.push({
            splitted: <span className={!mach_splits ?"text-danger":"text-secondary"}>{!mach_splits?t('Hylätty'): t('Ohitettu')}</span>,
            date: getDate(row?.date),
            inv1_event_number: row?.inv1_event_number,
            batch: row?.batch,
            security: !mach_splits && row?.security,
            tolerance: roundScaleDecimals(row?.tolerance,2),
            type: !mach_splits && transactionTypeCell,
            Splmaara: !mach_splits && row?.Splmaara,
            quantity: !mach_splits && row?.quantity,
            // type: transactionTypeCell,
            // batch: <span className="text-danger">{t('Ohitettu')}</span>,
            // security: <span className="text-danger">{t('splitti')}</span>,
            // date: row?.date,
            // Splmaara: <span className="text-danger">{t('Ohitettu Splitti')}</span>,            
            split_id: split?.id,
            split_dividend: split?.dividend,
            split_date: getDate(split?.date_effective),
            batch_balance: mach_splits ? null:roundScaleDecimals(row?.batch_balance,8),
            orignal_batch_balance: mach_splits ? null:roundScaleDecimals(row?.original_batch_balance,8),
          });
        }
      });
      new_rows.push({
        splitted: <span  style={{color:'rgba(0,0,0,0' }}>{t('Empty row')}</span>,
      });
    });

    return new_rows.map((item, index) => {
      return {
        ...item,
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(item?.id)}>
              {t("edit_button","Muokkaa")}
            </Button>
          </div>
        ),
      };
    });
  }, [machingSplitFound, noMachingSplitFound]);


  const getTableRowsSimple = useCallback((rows) => {
    return rows && rows.map((row, index) => {
        //check if row item is date the convert it to date


        let reference_code = row?.id
        let date_transaction = row?.date_transaction
        let symbol = row?.ticker_symbol

        if (model=="Transaction") {
            reference_code = row?.reference_code
        }
        else if (model=="Option") {
            reference_code = row?.trade_id
            date_transaction = row?.start_date
            symbol = row?.asset_name
        }


        return {
            ...row,
            date_transaction: getDate(row?.date_transaction),
            youngest_portfolio: getDate(row?.youngest_portfolio),
            oldest_import: getDate(row?.oldest_import),
            reference_code: reference_code,
            date_transaction: date_transaction,
            ticker_symbol: symbol,


        }
    })
    }, [already_imported_transactions, already_exists_younger_transactions])

  

  
  // Modal: New/Edit company
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  
  return (
    <>
      <>
        {already_imported_transactions && already_imported_transactions.length > 0 ? (
          <>
          {
            model=="Transaction" ? (
              <Typography variant="h3" className="text-dark mb-2" title={t("Splittiä ei löydetty")}><i className="fas fa-ban text-danger mr-2"></i>{t("importTransactionsV2_cant_import_same_transactions","Kirjaukset on jo tuotu järjestelmään. Et voi tuoda samoja kirjauksia uudestaan.")}</Typography>
            ):(
              <Typography variant="h3" className="text-dark mb-2" title={t("Splittiä ei löydetty")}><i className="fas fa-ban text-danger mr-2"></i>{t("importTransactionsV2_cant_import_same_transactions_options","Alla olevat kirjaukset löytyi jo järjestelmästä, nämä rivit ohitetaan tallennuksessa.")}</Typography>
            )
          }
            <TableAuto
              showRowNumber={false}
              showId={false}
              checkboxes={false}
              headers={getHeaderImportedTransactions()}
              headerColor={"light"}
              rows={getTableRowsSimple(already_imported_transactions)}
              loading={loading}
              pagination={false}
              paginationPrevious={paginationPrevious}
              paginationNext={paginationNext}
              paginationOffset={filters?.offset}
              paginationLimit={filters?.limit}
              paginationCount={null}
              setFilters={setFilters}
            />
          </>
          ):(
            <>
            {/* <Typography variant="h3" className="text-dark mb-2" >{t("")}</Typography> */}
            </>
          )
        }
      </>

      <>
        {already_exists_younger_transactions && already_exists_younger_transactions.length > 0 ? (
          <>  
 
            <Typography variant="h3" className="text-dark mb-2" title={t("Splittiä ei löydetty")}><i className="fas fa-ban text-danger mr-2"></i>{t("importTransactionsV2_cant_import_older_what_is_already","Kirjausten päiväys ei voi olla vanhempi kuin mitä salkussa olevista löytyy.")}</Typography>
            <TableAuto
                showRowNumber={false}
                showId={false}
                checkboxes={false}
                headers={getHeaderYougerTransactions()}
                headerColor={"light"}
                rows={getTableRowsSimple(already_exists_younger_transactions)}
                loading={loading}
                pagination={false}
                paginationPrevious={paginationPrevious}
                paginationNext={paginationNext}
                paginationOffset={filters?.offset}
                paginationLimit={filters?.limit}
                paginationCount={null}
                setFilters={setFilters}
            />
          </>
          ):(
            <>
          {/* <Typography variant="h3" className="text-dark mb-2" >{t("")}</Typography> */}
            </>
          )
        }
      </>

      <>
        {noSplitFound && noSplitFound.length > 0 ? (
          <div className="d-flex justify-content-between mb-2">
            <div className="p-2">
              <Typography variant="h3" className="text-dark mb-2" title={t("Splittiä ei löydetty")}><i className="fas fa-ban text-danger mr-2"></i>{t("importTransactionsV2_no_split_found","Splittiä ei löydetty")}</Typography>
              <Typography  variant="medium" bold className="mb-3 text-dark">{t("importTransactionsV2_no_split_found_title","Erän jälkeen on tapahtunut splitti tai splittejä, mutta niitä ei löydetty Investimesta. Luo ensin tarvittavat splitit ja aloita tuonti uudestaan.")} </Typography>
              <TableAuto
                showRowNumber={true}
                showId={false}
                checkboxes={false}
                headers={getHeadersNoSplits()}
                headerColor={"light"}
                rows={getTableRowsNoSplits()}
                loading={loading}
                pagination={false}
                paginationPrevious={paginationPrevious}
                paginationNext={paginationNext}
                paginationOffset={filters?.offset}
                paginationLimit={filters?.limit}
                paginationCount={null}
                setFilters={setFilters}
              />
            </div>
          </div>
          ):(
            <>
            {/* <Typography variant="h3" className="text-dark mb-2" >{t("")}</Typography> */}
            </>
          )
        }
      </>
        
      {machingSplitFound && machingSplitFound.length > 0 ? (
        <>
        {/* <Typography variant="h3" className="text-dark mb-2 mt-5">{t("importTransactionsV2_founded_splits","Kirjaukseen liittyvät splitit ja todennetut splittaukset")}</Typography> */}
        {/* <Typography  variant="medium" className="mb-3 text-warning">{t("Kirjauksen jälkeiseltä ajalta on etsitty kaikki splitit. Tämän jälkeen on yritetty löytää oikea kombinaatio spliteistä joiden kertoimella alkuperäisestä määrästä saadaan splitattu määrä. Sallittu toleranssi on lähtökohtaisesti 0,01 ja vastaavuuden löytämiseksi kasvatetaan toleranssia aina 0,99 saakka. Mikäli vastaavuutta ei ole vieläkään löytynyt katsotaan splittauksen epäonnistuneen.")} </Typography> */}

        {/* <TableAuto
          showRowNumber={false}
          showId={false}
          checkboxes={false}
          headers={getHeadersMachingSplits()}
          headerColor={"light"}
          rows={getTableRowsMachingSplits(machingSplitFound)}
          loading={loading}
          pagination={false}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        /> */}
        </>
        ):(
          <>
          {/* <div className="d-flex justify-content-start mb-4 mt-3">
            <div className="mr-2" >
              <i className="fas fa-check-circle text-success"></i>
            </div>
            <div >
              <Typography variant="h3" className="text-dark " >{t("importTransactionsV2_no_split_transaction_found","Kirjauksista ei löydetty yhtään splittaantumista.")}</Typography>
            </div>
          </div> */}
          </>
        )
      }
      {noMachingSplitFound && noMachingSplitFound.length > 0 ? (
        <>

        <Typography variant="h3" className="text-dark mb-2 mt-5"><i className="fas fa-ban text-danger mr-2"></i>{t("importTransactionsV2_unsuccess_splits","Kirjaukseen liittyvät splitit ja epäonnistuneet splittaukset")}</Typography>
        {(!machingSplitFound || machingSplitFound.length == 0) &&
          <></>
          // <Typography  variant="medium" className="mb-3 text-warning">{t("Kirjauksen jälkeiseltä ajalta on etsitty kaikki splitit. Tämän jälkeen on yritetty löytää oikea kombinaatio spliteistä joiden kertoimella alkuperäisestä määrästä saadaan splitattu määrä. Sallittu toleranssi on lähtökohtaisesti 0,01 ja vastaavuuden löytämiseksi kasvatetaan toleranssia aina 0,99 saakka. Mikäli vastaavuutta ei ole vieläkään löytynyt katsotaan splittauksen epäonnistuneen.")} </Typography>
        }
        <TableAuto
          showRowNumber={false}
          showId={false}
          checkboxes={false}
          headers={getHeadersMachingSplits()}
          headerColor={"light"}
          rows={getTableRowsMachingSplits(noMachingSplitFound)}
          loading={loading}
          pagination={false}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        </>
        ):(
          <>
          {/* <div>
          <Typography variant="h3" className="text-dark mb-2 mt-5" >{t("Mikäli splittejä löydettiin kirjauksen jälkeiseltä ajalta, yksikään niistä ei epäonnistunut.")}</Typography>
          <Typography variant="h3" className="text-dark mb-2 mt-5" >{t("")}</Typography>
          </div> */}
          </>
        )
      }
      {batchSalesOver && Object.keys(batchSalesOver).length > 0 ? (
        <>
        <Typography variant="h3" className="text-dark mb-4 mt-5"><i className="fas fa-ban text-danger mr-2"></i>{t("importTransactionsV2_sales_over","Myynti ylittää erän koon.")}</Typography>
          {/* <Typography  variant="medium" className="mb-3 text-warning">{t("Mikäli ei ole tapahtunut splittejä ilmoitetaan vain ylitys. Mikäli splittejä suoritettu näytetään splittaus jossa ylitys tapahtuu")} </Typography> */}
        <TableAuto
          showRowNumber={false}
          showId={false}
          checkboxes={false}
          headers={getHeadersBatchSalesOver()}
          headerColor={"light"}
          rows={getTableRowsBatchSalesOver()}
          loading={loading}
          pagination={false}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        </>
        ):(
          <>
          {/* <div className="d-flex justify-content-start mb-5 mt-5">
            <div className="mr-2" >
              <i className="fas fa-check-circle text-success"></i>
            </div>
            <div >
              <Typography variant="h3" className="text-dark " >{t("importTransactionsV2_sales_correct","Myynnit eivät ylittäneet ostoja")}</Typography>
              <Typography variant="h5" className="text-dark mb-0 mt-0" >{t("")}</Typography>
            </div>
          </div> */}
          </>
        )
      }

      {exchange_transactions && exchange_transactions.length > 0 ? (
        <>
        <Typography variant="h3" className="text-dark mb-2 mt-5"><i className="fas fa-check-circle text-secondary mr-2"></i>{t("Vaihdot")}</Typography>
        <Typography  variant="medium" className="mb-3 text-dark">{t("importTransactionsV2_check_exchange_transactions","Tarkista vaihdot ja määritä uusi hankintameno. Mikäli vaihdossa tuli useampi arvopaperi tilalle, tarkista ja kohdista hankintamenot oikeassa suhteessa uusille arvopapreille.")} </Typography>
        <TableAuto
          showRowNumber={false}
          showId={false}
          checkboxes={false}
          headers={getHeadersExchangeTransactions()}
          headerColor={"light"}
          rows={getTableRowsExchangeTransactions(exchange_transactions)}
          loading={loading}
          pagination={false}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        </>
        ):(
          <>
          {/* <div className="d-flex justify-content-start mb-4 mt-3">
            <div className="mr-2" >
              <i className="fas fa-check-circle text-success"></i>
            </div>
            <div >
              <Typography variant="h3" className="text-dark " >{t("importTransactionsV2_no_exchange_transactions","Kirjauksista ei löydetty yhtään vaihtoa")}</Typography>
            </div>
          </div> */}
          </>
        )
      }
      <>
      </>
      
      <Modal
        title={t("companies_list_company_title","Yritys")}
        isOpen={modalEditOpen}
        toggleModal={toggleModalEdit}
      >
        <CompanyEdit
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
      </Modal>
    </>
  );
}

export default ImportChecktTransactionsV2;
import React, { useState, useCallback, useEffect } from "react";
import { useRef } from 'react';
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Markup } from 'interweave';

// Storybook
import { Button, Typography, Spinner, Link,  } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";
import { useNotification } from "stories/components/Notification"

// App
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"

import OptionRightInput from "components/Forms/OptionRightInput/OptionRightInput"
import OptionAssetTypeInput from "components/Forms/OptionAssetTypeInput/OptionAssetTypeInput"
import OptionStateInput from "components/Forms/OptionStateInput/OptionStateInput"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import CommissionSelect from "components/Forms/CommissionSelect/CommissionSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"
import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"
import TransactionsFileHandling from "components/Forms/TransactionsFileHandling/TransactionsFileHandling"

import UploadFile from "components/Forms/UploadFile/UploadFile"

import HelpText from "components/Info/HelpText.js"


import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { httpGetStockData, httpLoadHistoryData } from "services/stocks"
import { httpGetPrivateStockDatas } from "services/private_stocks"
import { 
  httpGetTransaction, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
} from "services/transactions"


import { 
  httpGetPrivateInvestimentFundDatas
} from "services/private_investment_funds";

import {
  httpUploadFiles,
} from "services/files"

import {httpGetInvestmentFundData} from "services/investment_fund";

import { httpGetCurrencyRate } from "services/currencies"
import { set, startOfDay } from "date-fns";
import PortfolioSummary from "../PortfolioSummary/PortfolioSummary";

import AllSecuritySelect2 from "components/Forms/AllSecuritySelect/AllSecuritySelect2"


let today_date = new Date()
today_date.setHours(12, 0, 0, 0);

const defaultValues = {
  id: null,
  cash_flow: true,
  // commission_total: "0",
  currency: "EUR",
  // price: "0",
  // price_total: "0",
  // quantity: "0",
  // date_transaction: moment().format("YYYY-MM-DD"),
  date_transaction: today_date,
  date_settlement: today_date,
  // date_settlement: moment().format("YYYY-MM-DD"),
}

const OtherEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     setFieldError,
     setFieldTouched,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     objectId,
     setValues,
     setErrors,
      setTouched,
   } = props;
   
   const {
      t,
      history,
      currencyDate,
      setCurrencyDate,
      securityTargetRate,
      setSecurityTargetRate,
      portfolioSourceRate,
      setPortfolioSourceRate,
      securitySourceRateDate,
      setSecuritySourceRateDate,
      stocks,
      stockMarket,
      setStockMarket,
      setInvestmentType,
      investmentType,
      fundType,
      setFundType,
      totalValue,
      setTotalValue,
      totalValueEur,
      setTotalValueEur,
      stocksLoading,
      setCurrentStock,
      setFilters,
      portfolio,
      transaction_count,
      account,
      portfoliosSummary,
      setFiles,
      files,
      handleFileUpload,
      trxFiles,
      setTrxFiles,

   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const [stockDatePrice, setStockDatePrice] = useState();
  const [stockDatePriceLoading, setStockDatePriceLoading] = useState(false);
  const [firstTimeQuantity, setFirstTimeQuantity] = useState(false);
  
  const [stockTickerSymbol, setStockTickerSymbol] = useState(null);

  const [calculatePriceClicked, setCalculatePriceClicked] = useState(false);
  const [calculatePriceTotalClicked, setCalculatePriceTotalClicked] = useState(false);
  const [securityCurrencyChanged, setSecurityCurrencyChanged] = useState(false);
  const [dateTransactionChanged, setDateTransactionChanged] = useState(false);
  const [dateTransactionChangedFirstTime, setDateTransactionChangedFirstTime] = useState(true);

  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const [show_hided_basicdata, setShowHidedBasicData] = useState(false);

  // const helpTexts = useStoreState((state) => state.helptexts?.helptexts);
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        // console.log(res)
        setTouched(res);
        // console.log(res)
        if (stockMarket === "PRIVATE") {

          setErrors({
            ...res,
            stock_private_obj: res?.stock_private_obj?.id ? res?.stock_private_obj?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet")
          })
        }
        else {
          setErrors({
            ...res,
            stock: res?.stock?.id ? res?.stock?.id : t("Kenttä on pakollinen"),
            general: t("Please check the form errors", "Tarkista lomakkeen virheet")
          })
        }
      }
    })
  }

  // console.log(setFieldError)

  const findStockObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }

    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])


  //---------------------------UPDATE date_settlement if date_transaction are changed----------------------------------------------------
  useEffect(() => {
    if (!objectId){
      setFieldValue("date_settlement", values?.date_transaction)
    }
  }, [values?.date_transaction])


  //----------------------------UPDATE BASICDATA when stock market change----------------------------------------------------
  useEffect(() => {
    if (investmentType) {
      // console.log("PRIVATE basic_data")
      // getPrivateBasicDataObjects()

      let param ={
        stock_market:stockMarket,
        related_model_type: investmentType,
        show_hidden:show_hided_basicdata
      
      }

      if (investmentType == "30" ){
        param ={...param, type: fundType}
      }
      getBasicDataObjects(param)
    }
    else {
    getBasicDataObjects({stock_market:stockMarket, show_hidden:show_hided_basicdata})
    }
    // if (values)
    // setFieldValue('stock', null)
  }, [stockMarket, investmentType, fundType, show_hided_basicdata])
  


  //----------------------------UPDATE totalValue STATE----------------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission_total = parseFloat(values?.commission_total ? values?.commission_total : 0)

    if (commission_total > 0) {
      setTotalValue(round(price_total-commission_total, 6))
    }
    else{
      setTotalValue(round(price_total+commission_total, 6))
      
    }
    
  }, [values?.price_total, values?.commission_total])
  
  /* Optional: if you want to calc price reversed
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const quantity = parseInt(values?.quantity)
    let calculation = 0
    if (price_total && price_total > 0 && quantity && quantity > 0) {
      calculation = price_total / quantity
    }
    if (calculation && calculation != parseFloat(values?.price)) {
      setFieldValue("price", calculation)
    }
  }, [values?.price_total, values?.quantity])
  */
  

  //----------------------------UPDATE TOTAL PRICE Automatically ----------------------------------------------------


  const handleCalculatePriceTotal = () => {
    setCalculatePriceClicked(false)
    setCalculatePriceTotalClicked(true)
    const quantity = parseFloat(values?.quantity)
    const price = parseFloat(values?.price)
    let calculation = 0
    if (price && quantity && price > 0 && quantity > 0) {
      calculation = price*quantity;
    }
    if (calculation && calculation !== parseFloat(values?.price_total)) {
      setFieldValue("price_total", parseFloat(roundScaleDecimals(calculation, 6)))
    }
    setTimeout(() => {
      setCalculatePriceTotalClicked(false);
    }, 1000);
  }

  useEffect(() => {
    //handleCalculatePriceTotal()
  }, [values?.price, values?.quantity])


  useEffect(() => {
    let currency = portfolio?.currency_label


    // if (stockMarket == "XHEL"){
    //   currency = "EUR"
    // }
    // else if (stockMarket == "XSTO"){
    //   currency = "SEK"
    // }
    // else if (stockMarket == "XICE"){
    //   currency = "ISK"
    // }
    // else if (stockMarket == "XCSE"){
    //   currency = "DKK"
    // }

    // const stock = findStockObject(values?.stock?.id)
    // if (stock?.currency_label){
    //   currency = stock?.currency_label
    // }
    // else if (stock?.currency){
    //   currency = stock?.currency
    // }
    // else if (stock?.trading_currency){
    //   currency = stock?.trading_currency
    // }

    setFieldValue("currency", currency )
  }, [portfolio])
  // }, [stockMarket, values?.stock, portfolio])



  //----------------------------Calculate PRICE based on value_total and quantity ----------------------------------------------------


  const handleCalculatePrice = () => {
    setCalculatePriceClicked(true)
    setCalculatePriceTotalClicked(false)
    const quantity = parseFloat(values?.quantity)
    const price_total = parseFloat(values?.price_total)
    let calculation = 0
    setTimeout(() => {
      setCalculatePriceClicked(false);
    }, 1000);
    if (price_total && quantity ) {
      calculation = price_total /quantity;
    }
    else {return}
    setFieldValue("price", parseFloat(roundScaleDecimals(calculation, 6)))
  }




  //----------------------------UPDATE STOCK PRICE  {values.stock.id}----------------------------------------------------

  const findObject = (id) => {
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }

  useEffect(() => {
    let stockObject = findObject( stockMarket === "PRIVATE"? values?.stock_private_obj :values?.stock?.id)

    let ticker_symbol = values?.ticker_symbol
    let stock_market = values?.stock_market
    let nasdaq_id =values?.nasdaq_id; // "ACG1V";
    let basic_data_id = values?.basic_data_id;
    if (stockObject) {
      nasdaq_id = stockObject?.nasdaq_id
      stock_market = stockObject?.stock_market
      basic_data_id = stockObject?.id


      if (stockObject?.ticker_symbol){
        ticker_symbol = stockObject?.ticker_symbol
        setStockTickerSymbol(stockObject?.ticker_symbol)
      }
      else {
        setStockTickerSymbol(stockObject?.symbol)
      }
      // setFieldValue("stock", stockObject)
    }
    else {
      setStockTickerSymbol(null)
    }
    if ((values?.stock && values?.date_transaction && firstTimeQuantity)|| (values?.stock_private_obj && values?.date_transaction)) {
      const stock_date = undefined; // moment("2021-12-28").toISOString().split('T')[0] +" 00:00"; // "2021-12-27";
      let params = {
        offset:0, 
        limit:1, 
        // stock_date: "2021-12-26", // this exact filter not working
        nasdaq_id: nasdaq_id,
        stock_market: stock_market,
      }
      
      // Stock date

      if (stockMarket === "PRIVATE") {
        params["stock_date__gte"] = moment.utc(values?.date_transaction).startOf('day').toDate()
        params["stock_date__lte"] = moment(values?.date_transaction).add("days",1).toISOString().split('T')[0] +" 23:59"; 
        
        params["private_stock"] = values?.stock_private_obj?.id
        httpGetPrivateStockDatas(params).then(res => {
          if (res?.data?.count > 0) {
            setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price, 2).toFixed(2)))
          }
          else {
            setStockDatePrice(null)
          }
        })
      }
      else {
        params["stock_date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
        params["stock_date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 

        if (investmentType == "10" && ticker_symbol){
          params["ticker_symbol"] = ticker_symbol;
          setStockDatePriceLoading(true)
          params['ticker_symbol'] = null
          params['get_history'] = true
          params['basic_data_id'] = basic_data_id
          httpLoadHistoryData({basic_data_id: basic_data_id}).then(res => {
            if (res?.data) {
                
              console.log(res.data)
                
              }
              else {
                console.log("no data")
                
              }
          })
          httpGetStockData(params).then(res => {
            if (res?.data?.count > 0) {
              
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.ending_price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "20"){
          params["private_stock"] = values?.stock?.id;

          setStockDatePriceLoading(true)
          httpGetPrivateStockDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "30"){
          params["private_investment_fund"] = values?.stock?.id;
          params["date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
          params["date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
          setStockDatePriceLoading(true)
          httpGetPrivateInvestimentFundDatas(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
        if (investmentType == "40"){
          params["investment_fund"] = values?.stock?.id;
          params["date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 00:00:00Z"; 
          params["date__lte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
          setStockDatePriceLoading(true)
          httpGetInvestmentFundData(params).then(res => {
            if (res?.data?.count > 0) {
              setStockDatePrice(parseFloat(round(res?.data?.results[0]?.price,2).toFixed(2)))
              setStockDatePriceLoading(false)
              
            }
            else {
              setStockDatePrice(null)
              setStockDatePriceLoading(false)
              
            }
          })
        }
      }
    }
  }, [values?.stock, values?.stock_private_obj, values?.date_transaction, firstTimeQuantity])

  //Get stock date price only when stock date change or stock change but only ones/frist time when quantity change
  useEffect(() => {
    if (values?.quantity && firstTimeQuantity == false) {
      setFirstTimeQuantity(true)
    }
  }, [values?.quantity])

  useEffect(() => {
    if (values?.stock || values.stock_private_obj) {
      let stockObject = findObject(values?.stock?.id)
      if (stockObject) {
        if (stockMarket === "PRIVATE") {
          setFieldValue("stock_private_obj", {...values.stock_private_obj, label: stockObject?.name})
        }
        else {
        setFieldValue("stock", {...values.stock, label: stockObject?.name})
        
        }
      }
    }
  }, [stocks])

  

  //------------------------UPDATE COMISSION TOTAL-----------------------------------------------
  useEffect(() => {
    const price_total = parseFloat(values?.price_total)
    const commission = parseInt(values?.commission)
    if (price_total && price_total > 0 && commission && commission > 0) {
      if (commission === 2) {
        setFieldValue("commission_total", 0)
      }
      else if (commission === 3) {
        const commission_total = 8.0
        setFieldValue("commission_total", round(commission_total,2))
      }
      else if (commission === 4) {
        const commission_total = round(8.0 + (0.025*price_total), 2)
        setFieldValue("commission_total", round(commission_total,2))
      }
      
    }
    else {
      // setFieldValue("commission_total", 0)
    }
  }, [values?.price_total, values?.commission])
  


  // ------------------UPDATE CURRENCY DATE-----------------------------------
  useEffect(() => {
    if (values?.currency && values?.date_transaction) {
      
      if (values?.currency === "EUR") {
        setPortfolioSourceRate(1.0)
      }
      else {
        let params = {
          offset:0, 
          limit:1, 
          // ordering: '-Date'

        }
        params["Date"] = moment(values?.date_transaction).format("YYYY-MM-DD")
        params['currency'] = values?.currency
        
        httpGetCurrencyRate(params).then(res => {
          if (res?.data?.currency_rate) {
            setPortfolioSourceRate(res?.data?.currency_rate)
          }
          else {
            setPortfolioSourceRate(1.0)

          }
        }, error => {
          setPortfolioSourceRate(null)
          

        })

      }
    }
    else {
      setPortfolioSourceRate(null)

    }
  }, [values?.currency, values?.date_transaction])

  useEffect(() => {
    if (securityTargetRate && portfolioSourceRate){

      setCurrencyDate(roundScaleDecimals(securityTargetRate/portfolioSourceRate, 3))
    }
    else{
      setCurrencyDate(null)
    }

  }, [securityTargetRate, portfolioSourceRate])

      // const stock = findStockObject(values?.stock?.id)
    // if (stock?.currency_label){
    //   currency = stock?.currency_label
    // }
    // else if (stock?.currency){
    //   currency = stock?.currency
    // }
    // else if (stock?.trading_currency){
    //   currency = stock?.trading_currency
    // }
  


  // ------------------UPDATE TOTAL VALUE EUR-----------------------------------
  useEffect(() => {
    if (currencyDate && totalValue ) {
      setTotalValueEur(round(totalValue*currencyDate, 2))
    }
    else {
      setTotalValueEur(totalValue)
    }
      
      
  }, [currencyDate, totalValue])
  

  // -------------------------SET PRICE automatically if in field have no value already--------------------------------------------
  useEffect(() => {
    // if (!values.price) {
    //   handleUseStockDatePrice()
    // }
    // handleUseStockDatePrice()
  }, [stockDatePrice])
            


  //----------------------------Show user that security currency changed ----------------------------------------------------

  useEffect(() => {
    if (getStockCurrency() !== values?.currency && !securityCurrencyChanged && values?.stock) {
      // setSecurityCurrencyChanged(true)
      // setTimeout(() => {
      //   setSecurityCurrencyChanged(false);
      // }, 1000);
    }
  }, [values?.stock])

    //----------------------------Show user that date settlement changed ----------------------------------------------------

    useEffect(() => {

      if (dateTransactionChanged && values?.date_transaction ) {
        setTimeout(() => {
          setDateTransactionChanged(false);
        }, 1000);
      }
      else if (dateTransactionChangedFirstTime) {
        setDateTransactionChangedFirstTime(false)
      }
    }, [dateTransactionChanged])


  //---------------------------- Update subscription BEGIN---------------------------------

  const openUpdateUp = useCallback(() => {
    return (<>{(account?.license?.limit_transactions <= transaction_count && objectId == null) && <UpdateUp redirectToFrontpage={false} forceOpen={account?.license?.limit_transactions <= transaction_count}/>}</>)
  }, [account?.license, transaction_count])

  // Update subscription END


  // ------------------fUCTIONS TO USE-----------------------------------

  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    /*
    httpConfirmPurchaseOrderShipping(values?.id).then(res => {
      handleSubmit()
    })
    */
  }

  useEffect(() => {
      
    if (values?.stock && values?.date_transaction) {
      const security_currency = getStockCurrency()
      let params = {
        offset:0, 
        limit:1, 
        // ordering: '-Date'

      }
      params["Date"] = moment(values?.date_transaction).format("YYYY-MM-DD")
      params['currency'] = security_currency
      // if (security_currency === "EUR") {
      //   setSecurityTargetRate(1.0)
      // }
      // else{
        httpGetCurrencyRate(params).then(res => {
          if (res?.data?.currency_rate) {
            setSecurityTargetRate(res?.data?.currency_rate)
            setSecuritySourceRateDate(res?.data?.currency_date)

          }
          else {
            setSecurityTargetRate(1.0)
            setSecuritySourceRateDate(null)
          }
        }, error => {
          setSecurityTargetRate(null)
          setSecuritySourceRateDate(null)
        })
      }
    // }
    else {
      setSecurityTargetRate(null)
      setSecuritySourceRateDate(null)
    }
  }, [values?.stock, stocksLoading, values?.date_transaction])



  const getStockCurrency = useCallback(() => {
    let stock = values?.stock
    if (objectId){
      stock = findStockObject(values?.stock?.id)
    }
    let currency = null
    if (stock?.currency_label){
      currency = stock?.currency_label
    }
    else if (stock?.currency){
      currency = stock?.currency
    }
    else if (stock?.trading_currency){
      currency = stock?.trading_currency
    }
    return currency
  }, [values?.stock, stocks, stocksLoading])

  const handleStockMarketChange = ({target}) => {
    setStockMarket(target.value)
    setFieldValue("stock", null)
    setFieldValue("stock_private_obj", null)
    setStockTickerSymbol(null)
    setStockDatePrice(null)
    // if (investmentType === "10") {
    //   //filters are from transactionslist, so when this modal are closed, transactionslist view stock_market is same as there.
    //   setFilters(s => ({
    //     ...s,
    //     stock_market: target.value,
    //   }))
    // }
    // else {
    //   setFilters(s => ({
    //     ...s,
    //     stock_market: null,
    //   }))
    // }


    if (target.value === "PRIVATE") {
      setErrors({
        ...errors,
        stock_private_obj: t("Kenttä on pakollinen"),
      })
    }
    else {
      console.log(errors)
      setErrors({
        ...errors,
        stock: t("Kenttä on pakollinen"),
      })
    }

  }

  const handleInvestmentTypeChange = ({target}) => {
    if (target.value === "10") {
      setStockMarket("XHEL")
      // setFilters(s => ({
      //   ...s,
      //   related_model_type: "10",
      // }))
      setInvestmentType(target.value)   
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
    }
    else {
      setStockMarket(null)
      setInvestmentType(target.value)
      // setFilters(s => ({
      //   ...s,
      //   // related_model_type: target.value,
      //   // stock_market: null,
      // }))
      setFieldValue("stock", null)
      setFieldValue("stock_private_obj", null)
      setStockTickerSymbol(null)
      setStockDatePrice(null)
    }
  } 

  const handleFundChange = ({target}) => {
      setFundType(target.value)  
      setFieldValue("stock", null) 
  } 


  const getDefaultValues = useCallback(() => {
    // if (location?.state?.stockId && !values?.private_stock && !values?.stock==="stock_market_changed") {
    //   return {value: location.state?.stockId, label: location.state?.stockName}
    // }
    if (values?.stock_market === "PRIVATE") {
    return {value: values?.private_stock, label: values?.private_stock_name }
    }
    return {value: values?.stock, label: values?.stock_name }
  }, [values])

  
  const handleUseStockDatePrice = () => {
    if (stockDatePrice && !objectId) {
      setFieldValue("price", Number(stockDatePrice.toFixed(2)))
    }
  }


  
  const handleDelete = () => {

    if (stockMarket === "PRIVATE") {
      httpDeletePrivateStockTransaction(values?.id).then(res => {
        props.notify({ title:t("Kirjaus"), message:t("Poistettu")})

        props.handleResolve()
      }, error => {
        
        props.notify({ title:t("Kirjaus"), message:t("Poisto epäonnistui")})
      })
    }
    else {
      httpDeleteTransaction(values?.id).then(res => {
        props.notify({ title:t("Kirjaus"), message:t("Poistettu")})
        if (investmentType == "10"){
          setFilters(s => ({
              ...s,
              // stock_market: stockMarket,
              // related_model_type: investmentType,
              stock_market: null,
              related_model_type: null,
            }))
      }
      else {
          setFilters(s => ({

              ...s,
              // stock_market: null,
              // related_model_type: investmentType,
              stock_market: null,
              related_model_type: null,
            }))
      }
        props.handleResolve()
      },  error => {
        console.log("httpCreateStockTransaction error", error);
        props.notify({ title:t("Kirjaus"), message:t("Poisto epäonnistui")})

        if (error?.status === 400) {
          setErrors(error?.data)
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
        
      })
    }
  }
  


  const getStockSelect = useCallback(() => {
    if (stockMarket === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("other_edit_asset_stock", "Osake (yksityinen)")}
          setFieldValue={setFieldValue}
          setValueToSelect={true}
          ratesView={false}
          setFieldValueReturnId={false}
          disabled={objectId? true: false}
        />
      )
    }
    else {
      return (
        <StockSelect
          {...getFormikInputProps("stock")}
          label={t("other_edit_asset_stock", "Arvopaperi")}
          setFieldValue={setFieldValue}
          setValues={setValues}
          setFieldValueReturnId={false}
          ratesView={false}
          setValueToSelect={true} 
          disabled={objectId? true: false}
          id = "stock"
          async={true}
        />
      )
    }
  }, [investmentType, stockMarket, values?.stock, values.stock_private_obj, errors?.stock, errors?.stock_private_obj, errors])


  const handleEmailClick = () => {
      window.location.href = 'mailto:support@investime.fi';
  };


  // if (account?.license?.limit_transactions <= transaction_count && objectId == null) {
  //   return(
  //     <SubscriptionOptionsInfo
  //       handleResolve={handleCancel}
  //       handleReject={handleCancel}
  //       custom_info_text={t("subscription_transactions_info_text","Kirjausten enimmäismäärä tilauksellanne on täyttynyt.")}
  //     />
  //   )
  //   return(
  //     <div className="d-flex justify-content-between">
  //     <div className="p-3">
   
  //       <Typography bold variant="h3" className="text-dark mt-3 mb-5">{t("Kirjausten enimmäismäärä tilauksellanne on täyttynyt.")}</Typography>
  
  //     <Typography variant="medium" className="text-dark mt-3 mb-1">{t("Muuttaaksesi tilausta ota yhteyttä tukipalveluumme.")}</Typography>
  //     <Row>
  //       <Col>
  //         <Button className="mt-5" name="purchaseEdit_cancel" onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
  //       </Col>
  //       <Col className="text-rigth">
  //         <Button variant="secondary" className="mb-3">
  //             <Link href="https://www.investime.fi/ota-yhteytta/" target="_blank" className="text-dark">{t("subscription_options_info_change_subcription","Vaihda tilausta")}</Link>
  //         </Button>
  //       </Col>
  //     </Row>

    
  //     </div>
  //   </div>
  //   )

  // }

  // if (portfoliosSummary >= account?.license?.limit_purchase_cost && objectId == null) {
  //   return(
  //     <SubscriptionOptionsInfo
  //       handleResolve={handleCancel}
  //       handleReject={handleCancel}
  //       custom_info_text={t("subscription_purchase_cost_info_text","Kirjausten hankintojen arvo ylittää tilauksenne salliman rajan.")}

  //     />
  //   )
  //   return(
  //     <div className="d-flex justify-content-between">
  //     <div className="p-3">
   
  //       <Typography bold variant="h3" className="text-dark mt-3 mb-5">{t("Hankintoihin käytetty rahallinen arvo ")}({roundScaleDecimals(portfoliosSummary, 2)}€) {t( "ylittää tilauksenne salliman rajan")} (Max. {account?.license?.limit_purchase_cost}€)</Typography>
  
  //     <Typography variant="medium" className="text-dark mt-3 mb-1">{t("Muuttaaksesi tilausta ota yhteyttä tukipalveluumme.")}</Typography>
  //     <Button variant="secondary" className="mb-3">
  //         <Link href="https://www.investime.fi/ota-yhteytta/" target="_blank" className="text-dark">{t("subscription_options_info_change_subcription","Vaihda tilausta")}</Link>
  //     </Button>
  //     <Button className="mt-5" name="purchaseEdit_cancel" onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
  //     </div>
  //   </div>
  //   )
  // }

  // const nextInputRef = useRef(null);

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && nextInputRef.current) {
  //     nextInputRef.current.focus();
  //   }
  // };
  const allSecurity2OnChange = (value) => {
    console.log("allSecurity2OnChange", value)
    setInvestmentType(value?.related_model_type)   
    setStockMarket(value?.stock_market)
    setFundType(value?.type)
    setFieldValue("stock", {value: value?.value, label: value?.name, stockCurrency: value?.currency_label, stockMarket: value?.stock_market, id: value?.value, currency_label:value?.currency_label, ticker_symbol: value?.ticker_symbol})
  } 
  
  return (
    <div className="px-3 pt-2">
      {
        // {JSON.stringify(values)}
        // {"---------------------"}
        // {JSON.stringify(errors)}
        // {JSON.stringify(investmentType)}
        // {JSON.stringify(stockMarket)}
      }
      
      {openUpdateUp()}
      <Row className="mt-2 mb-2 border-bottom">
        <Col xs={12} md={6} className="ml-auto pb-3 pl-3 d-flex align-items-center">
          {objectId ? (
            <Typography variant="h2"  >{t("other_edit_title", "Muokkaa kirjausta")}</Typography>
          ) : (
            <div className="d-inline-flex">
              <Typography variant="h2" className="">{t("other_add_title", "Lisää kulu tai tuotto")}:  </Typography>
              <Typography variant="h2"  className="font-weight-light ml-1"> {portfolio?.name}</Typography>
            </div>
          )}
        </Col>
        <Col xs={12} md={6} className="ml-auto pb-3">
        {/*  <PortfolioSelectStore icon="fas fa-suitcase" iconColor="text-secondary" disabled={true}/> */}
        </Col>
      </Row>
      <Row  className="pb-3 pt-2 ">
        <Col xs={12} md={6} className="border-right px-4 pt-0">
          {/* <Row className="">
            <Col>
              <div name="click_related_model_type">
                <InvestmentTypeSelect name="related_model_type1" onChange={handleInvestmentTypeChange} value={investmentType} icon="" showAll={false}  disabled={objectId? true: false}/>
              </div>
            </Col>
          </Row>
          {
            investmentType === 30 || investmentType === "30" ? (
            <Row className="mt-3">
              <Col>
                <InvestmentTypeSelectSub name="fund_type" onChange={handleFundChange} value={fundType} showPrivate={false} showAll={false} icon=""  disabled={objectId? true: false}/>
              </Col>
            </Row>
            ) : null
          }

          {
            investmentType === 10 || investmentType === "10" ? (
            <Row className="mt-3">
              <Col>
               
                <StockMarketSelect name="stock_market" onChange={handleStockMarketChange} value={stockMarket} showPrivate={false} showAll={false} icon=""  disabled={objectId? true: false}/>
              </Col>
            </Row>
            ) : null
          }
          <Row>
            <Col className="my-3">
            { getStockSelect() }

            { stockTickerSymbol ? (
              <div className="d-flex">
                <Typography variant="small" className="mr-auto pl-1">{t("Kaupankäyntitunnus")}: {stockTickerSymbol}</Typography>
                <Typography variant="small" style={{fontWeight: securityCurrencyChanged ? "normal" : "normal"}} className={securityCurrencyChanged && objectId == null? "text-secondary pr-1" : "pr-1"}> {t('Valuutta')}: <span >{getStockCurrency()}</span></Typography>

              </div>
              
            ) : null }
            </Col>
          </Row> */}

          <Row>
            <Col className="mb-3">
              {!objectId && <AllSecuritySelect2  label={t("security", "Arvopaperi")} portfolio={portfolio.id} onChange={allSecurity2OnChange} delisted={show_hided_basicdata} disabled={objectId?true:false}  value={values?.stock}/>}
              {objectId && <AllSecuritySelect2  label={t("security", "Arvopaperi")} portfolio={portfolio.id} onChange={allSecurity2OnChange} delisted={show_hided_basicdata} disabled={objectId?true:false} value={values?.stock}/>}

            { (stockTickerSymbol && objectId) ? (
              <div className="d-flex">
                <Typography variant="small" className="mr-auto pl-1">{t("Kaupankäyntitunnus")}: {stockTickerSymbol}</Typography>
                <Typography variant="small" style={{fontWeight: securityCurrencyChanged ? "normal" : "normal"}} className={securityCurrencyChanged && objectId == null? "text-secondary pr-1" : "pr-1"}> {t('Valuutta')}: <span >{getStockCurrency()}</span></Typography>

              </div>
              
            ) : 
            <div className="d-flex">
              <Typography variant="small" className="mr-auto pl-1">{t("Kaupankäyntitunnus")}: {values?.stock?.ticker_symbol}{values?.stock?.stockMarket ? ":" + values?.stock?.stockMarket : ""}</Typography>
              <Typography variant="small" style={{fontWeight: securityCurrencyChanged ? "normal" : "normal"}} className={securityCurrencyChanged && objectId == null? "text-secondary pr-1" : "pr-1"}> {t('Valuutta')}: <span >{getStockCurrency()}</span></Typography>

            </div>
            }
            </Col>
          </Row>



          {/* <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-1 text-right">
              {stockMarket === "PRIVATE" ? (
                <Typography className="text-secondary" variant="small">
                {values.stock_private_obj === undefined || 
                  values.stock_private_obj === null || 
                  stockDatePrice ? null : t("Kurssin päätöshintaa hankintapäivälle ei ole saatavilla ")}
                </Typography>

                ):(
                <Typography className="text-secondary" variant="small">
                {values.stock === undefined || 
                  values.stock === null || 
                  stockDatePrice ? null : t("Kurssin päätöshintaa hankintapäivälle ei ole saatavilla ")}
                </Typography>

              )}  
              </Col>
          </Row> */}
          <Row>
            <Col className="my-1">
            <div className="ml-0 mb-3">
              <Checkbox 
                id={"show_hided_basicdata_other_edit"}
                name="show_hided_basicdata"
                checked={show_hided_basicdata}
                onChange={(event) =>  setShowHidedBasicData(!show_hided_basicdata)}
                value="show_hided_basicdata"
                className="zIndexZero"
                required
                label={<>{t("show_hided_basicdata", 'Näytä poistuneet')}</>}
              />
            </div>
            </Col>
          </Row>
          
          <Row>
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("other_date", "Tapahtumapäivä")} *</label>
            </Col>
            <Col className="mb-1">
              <DatePicker
                className="form-control"
                selected={values?.date_transaction}
                name="date_transaction"
                onChange={(date) => {
                  setFieldValue("date_transaction", date) 
                  setDateTransactionChanged(true)
                }}
                showYearDropdown
                // dateFormat="d.M.yyyy"
                value={values?.date_transaction}
                // showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                dateFormat="d.M.yyyy"
                // placeholderText="15.1.2000 12:00"
                placeholderText="15.1.2000"
                timeCaption="Time"
                // disabled={objectId? true: false}
                error={touched.date_transaction && Boolean(errors.date_transaction)}
                errorText={errors?.date_transaction}
                // onKeyDown={handleKeyDown}
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t(moment(values?.date_transaction).format("dddd"))}/>}

                // errorText={touched.date_transaction && Boolean(errors.date_transaction)}
              />
              </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("Hankinta-aika")} * </label>
              <div> </div>
              <HelpText contentKey="transaction_edit_date_transaction_time" parentElementId="date_transaction_time" showButton={true}  className="mb-0"/>
            </Col>
            <Col className="mb-0">
                <DatePicker
                  // id="date_transaction_time"
                  className="form-control "
                  selected={values?.date_transaction}
                  name="date_transaction"
                  onChange={(date) => setFieldValue("date_transaction", date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}   
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="12:00"
                  customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={t("klo")}/>}
                />
            </Col>
          </Row> */}

          
          {/* <Row className="mt-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("settlement_date", "Selvityspäivä")} * </label>
              <div> </div>
              <HelpText contentKey="transaction_edit_date_settlement" parentElementId="date_settlement_helptext" showButton={true}  className="mb-0"/>
            </Col>
            <Col className="mb-1">
              <DatePicker
                selected={values?.date_settlement}
                name="date_settlement"
                onChange={(date) => setFieldValue("date_settlement", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.date_settlement}
                customInput={<Input addonTypeClass="append" iconColor={"bg-light"} inputText={values?.date_settlement ? (moment(values?.date_settlement).format("dddd")):""}/>}
                disabled={objectId? true: false}  
                className={`form-control b-0 calculated ${dateTransactionChanged ? "border-yellow" : ""}`}
              />
            </Col>
          </Row> */}

          
          <Row className="mt-3">
            <Col sm={12} lg={4} className="d-flex align-items-center mb-3">
              <label className="mb-0 pr-1 header">{t("broker", "Välittäjä")}</label>
            </Col>
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("broker_name")}
                placeholder={t("broker_placeholder", "Esim. Nordea")}
                // icon="fas fa-user"
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("reference_code", "Viite")}</label>
            </Col>
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("reference_code")}
                // placeholder={t("reference_code", "Viite")}
                placeholder=""
                
              />
            </Col>
          </Row>
          {
            account?.show_cahs_flow_checkbox_in_transaction && 
            <Row className="mt-4">
                <Col className="my-1">
                <div className="ml-0 mb-3">
                  <Checkbox 
                    id={"cash_flow"}
                    name="cahs_flow"
                    checked={values?.cash_flow}
                    onChange={(event) =>  setFieldValue("cash_flow",!values?.cash_flow)}
                    value={values?.cash_flow}
                    className="zIndexZero"
                    required
                    label={<>{t("transaction_edit_show_cash_flow", 'Huomioidaan kassavirrassa')}</>}
                    />
                </div>
                </Col>
            </Row>
          }
          {/* <Row className="row justify-content-start mt-5">
            <Col lg={12} md={12} className="mb-1 text-left">
                <Typography className="text-red" variant="medium">
                   {t("HUOM! Muista tehdä salkun sisällä samaa osaketta koskevat kirjaukset aina kronologisessa järjestyksessä.")}
                </Typography>
                {objectId &&  
                  <Typography className="text-red" variant="medium">
                    {t("Mikäli tarve poistaa virheellinen kirjaus salkusta, tulee myös virheellistä kirjausta uudemmat samaa osaketta koskevat kirjaukset poistaa. Tämän jälkeen kaikki poistetut kirjaukset tulee luoda uudelleen.")}
                  </Typography>
                }
              </Col>
          </Row> */}

        </Col>
        <Col className="px-4 pt-0">
          <Row>
            <Col className="mb-0">
               <label className="mb-1 pr-1 header-bold">{t("other_information", "Kulu / tuotto")}</label>
            </Col>
          </Row>
          {/* <Row >
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-0 pr-1 header">{t("other_edit_currency", "Valuutta")}</label>
            </Col>
            <Col className="mb-1">
              <CurrencySelect {...getFormikInputProps("currency")} label="" disabled={true}/>
              
              
            </Col>
          </Row> */}
            {/* <Row className="row justify-content-end mb-3">
          {securityTargetRate && portfolioSourceRate && 
              <Col lg={8} md={12} >
                <Typography variant="small">{t(`Valuuttakurssi`)}: {currencyDate ? currencyDate : t("Ei saatavilla")} {securitySourceRateDate && <span className="text-gray">  ({securitySourceRateDate})</span>}</Typography>
              </Col>
          }
            </Row>
           */}

          {/* <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("other_edit_commission", "Palkkio")}</label>
            </Col>
            <Col className="mb-0">
              <CommissionSelect
                {...getFormikInputProps("commission")}
                allOption={false}
                label=""
                disabled={objectId? true: false}
              />
            </Col>
          </Row> */}

          
          {/* <Row className="mb-3">
          
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_quantity", "Kappalemäärä")} *</label>
            </Col>
            <Col sm={12} lg={4} >
  
            
              <Input
                {...getFormikInputProps("quantity")}
                label=""
                type="number"
                step={investmentType == 10 || investmentType == 20?"1":"0.01" }
                min={0}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={t("kpl")}
             
                onWheel={(e) => e.target.blur()} // Prevents scrolling on input
                placeholder={t("")}
                className={`calculated ${calculatePriceTotalClicked || calculatePriceClicked ? "border-yellow" : ""}`}
                disabled={objectId? true: false}
              />
  
            </Col>

          </Row> */}
          
          {/* <Row className="mb-1">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_price", "Kappalehinta")} *</label>
            </Col>
            <Col sm={12} lg={4} >
              <Input
                {...getFormikInputProps("price")}
                label=""
                type="number"
                step="0.01"
                min={0}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={values?.currency}
                onWheel={(e) => e.target.blur()}
                placeholder={t("")}
                className={`calculated ${calculatePriceTotalClicked ? "border-yellow" : ""}`}
                disabled={objectId? true: false}
              />

            </Col>

                <Col className="text-left mt-sm-2  mt-md-0">
                  <Button variant="default" className="bg-lighter" size="sm"  onClick={() => handleCalculatePrice()}  disabled={objectId? true: false} >
                    <Typography variant="medium" className="text-dark">{ t("transaction_edit_calculate_price","Laske kpl hinta") }</Typography>
                    
                  </Button>
                </Col>

          </Row> */}

          <Row className="my-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("other_edit_price_total", "Hinta")} *</label>
            </Col>
            <Col sm={12} lg={8} className="mb-0">

              <Input
                {...getFormikInputProps("price_total")}
                label=""
                type="number"
                step="0.01"
                min={0}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={values?.currency}
                // style={{ backgroundColor: "green", color: "white" }}
                onWheel={(e) => e.target.blur()}
                placeholder={t("")}
                className={`calculated ${values?.price_total && values?.price_total >= 0 ? "text-success" : values?.price_total < 0 ? "text-danger": "text-dark"}`}
                // disabled={objectId? true: false}
              />
            </Col>

          </Row>

          
          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("ohter_commission_total", "Tapahtuman kulu")}</label>
            </Col>
            <Col sm={12} lg={8} >
              <Input
                {...getFormikInputProps("commission_total")}
                label=""
                type="number"
                step="0.01"
                className={"text-danger"}
                min={0}
                addonTypeClass="append"
                iconColor={"bg-lighter"}
                inputText={values?.currency}
                // style={{ backgroundColor: "red", color: "white" }}
                onWheel={(e) => e.target.blur()}
                placeholder={t("")}
                // disabled={objectId? true: false}
              />
            </Col>
          </Row>
          <Row className="row justify-content-end">
            <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-1 pr-1 header">{t("transaction_edit_value_total", "Hinta yhteensä")}</label>
            </Col>
            <Col lg={8} md={12} className="d-flex align-items-center">
             <Typography variant="small" className={totalValue > 0?"text-dark":null}>{t("")} {totalValue > 0}{totalValue ? thousandFormat(totalValue) : 0.0} ({values?.currency})</Typography>
            </Col>
          </Row>
          
          { values.currency != getStockCurrency() && values?.stock != null ? (
          <Row className="row justify-content-end">
            <Col lg={8} md={12} className="">
              <div className="d-flex ">
              <Typography variant="small"  className={totalValueEur > 0?"text-dark":null}> {totalValueEur > 0}{totalValueEur ? thousandFormat(totalValueEur) : 0.0} ({getStockCurrency() ? getStockCurrency(): <span className="text-gray">{t("arvopaperin valuutta")}</span> })</Typography>
              <Typography variant="small" className="ml-2"><span className="text-gray">{t("transaction_edit_rate", `Kurssi`)}: {currencyDate ? currencyDate : t("Ei saatavilla")} {securitySourceRateDate && <span className="text-gray">  ({securitySourceRateDate})</span>}</span></Typography>
              </div>
            </Col>
          </Row>
          ) : null}
          
          <Row className="mb-1 mt-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header">{t("transaction_edit_comments", "Kommentit")}</label>
            </Col>
            <Col >
              <Input
                {...getFormikInputProps("notes")}
                label=""
                // placeholder={t("transaction_edit_comments", "Kommentit")}
                type="textarea"
                rows={3}
                maxLength={70}
              />
            </Col>
          </Row>


          <Row className="row justify-content-end">
            <Col lg={8} md={12} className="mb-3 text-right">
              <Typography variant="small"  >{values?.notes && values?.notes.length || 0}/70</Typography>
            </Col>
          </Row>
          {account && account?.license?.name !== "Lite" && (
            <>
                    <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex ">
              <label className="mb-0 mt-3 pr-1 header">{t("attachment_file", "Liite")}</label>
            </Col>
            <Col className="mb-0">
              <UploadFile apiUrl="https://your-server.com/upload" setFiles={setFiles} files={files} padding={15} />
            </Col>
            
          </Row>
            <Row className="mb-3">
              <Col sm={12} lg={4} className="d-flex ">
              </Col>
              <Col className="mb-0">
                
                <TransactionsFileHandling setFiles={setTrxFiles} files={trxFiles} transaction={values} setFieldValue={setFieldValue} values={values}/>
                {/* {JSON.stringify(values?.connect_files)} */}
              </Col>
              
            </Row>
            </>
          )}
          <Typography  variant="small" className="mt-2 text-gray">
          {t("purchase_edit_required","* Tähdellä merkityt ovat pakollisia")}
        </Typography>
        </Col>

      </Row>
      
      <Row>
        <Col>{/*JSON.stringify(values)*/}</Col>
      </Row>
      
      <Row className="bg-lighter pt-3  rounded-bottom">
        <Col className="mb-3">
          <Button name="purchaseEdit_cancel" onClick={handleCancel} variant="primary">{t("Sulje")}</Button>
        </Col>
        { values?.id > 0 ? (
        <Col className="mb-3 text-center">
          <Button name="transactionEdit_delete" onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        ) : null}
        <Col className="mb-3 text-right">
          { !preSetValues?.state_delivery && (
            <Button name="purchaseEdit_submit" variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              { t("Kirjaa ja tallenna") }
            </Button>
          )}
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </div>
  );
}

const PurchaseEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {  
      const {t, stockMarket, investmentType} = props;

      const required = t("Kenttä on pakollinen");
      if (stockMarket === "PRIVATE") {
        return Yup.object().shape({
          // checht that stock are object with id
          stock_private_obj: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
          quantity: Yup.number().required(required).nullable(),
          price : Yup.string().required(required).nullable(),
        });
      }
      else {

        return Yup.object().shape({
          stock: Yup.object().shape({
            id: Yup.number().nullable().required(required),
          }).nullable().required(required),
        //   quantity: investmentType <=20?Yup.number().integer().min(0, t('Arvon tulee olla vähintään 0')).required(t('Arvo on pakollinen')): Yup.number().min(0.000000000000, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          // quantity: Yup.number().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
        //   price: Yup.string().min(0.000000000000, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
          // price: Yup.string().test(
          //   "not-0",
          //   t("Value cannot be '0'"),
          //   (value) => value !== "0"
          // )
          date_transaction: Yup.date().required(required).nullable(),
          // value_total: Yup.number().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable(),
        //   price_total: Yup.number().min(0.0000000000001, t('Arvon tulee olla enemmän kuin 0')).required(required).nullable()
          price_total: Yup.number().required(required).nullable()
        });
      }
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, currencyDate, portfolio, stockMarket, investmentType, totalValue, setFilters, objectId, handleFileUpload, files} = props;

      let data = {}

      if (stockMarket === "PRIVATE"){
        data = {
          ...values,
          commission_total:objectId? values?.commission_total: -values?.commission_total || 0,
          currency_exchange_rate: currencyDate,
          currency_label: values?.currency,
          portfolio: portfolio?.id,
          price: null,
          price_total: values?.price_total,
          stock: values?.stock_private_obj?.id,
          type: 2, // Buy
          date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
          date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
          stock_market_label: stockMarket,
          // state: values?.state?.key,
          value_total: totalValue,
        };
      }         
      else {
        let value_total = totalValue
        let commission_total = values?.commission_total
        console.log("values?.commission_total", values?.commission_total, values?.price_total)
        if (values?.commission_total && values?.commission_total < 0) {
          value_total = Number(values?.price_total) + Number(values?.commission_total)
          commission_total = Number(values?.commission_total)

        }
        if (values?.commission_total && values?.commission_total > 0){
          value_total = Number(values?.price_total) - Number(values?.commission_total)
          commission_total = Number(-values?.commission_total)
        }
        console.log("value_total", value_total)

        data = {
          ...values,
          quantity:1,
          commission_total:commission_total ? commission_total : 0,
          currency_exchange_rate: currencyDate,
          currency_label: values?.currency,
          portfolio: portfolio?.id,
          price: 0,
          price_total:objectId? values?.price_total : values?.price_total && parseFloat(values?.price_total.toString().replace(",",".")),
          stock: values?.stock?.id,
          type: 50, // Buy
          date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
          date_transaction: values?.date_transaction ? moment(values?.date_transaction).format() : undefined,
          stock_market: stockMarket,
          related_model_id: values?.stock?.id,
          related_model_type: investmentType,
          // value_total: objectId? values?.price_total : values?.price_total && parseFloat(values?.price_total.toString().replace(",",".")),
          value_total:  round(value_total,6),

          batch_balance: 0,
          original_batch_balance: 0,
          reference_code: values?.reference_code,
        };
      }
      
      if (values?.id) {
        if (stockMarket === "PRIVATE") {
          setSubmitting(true)
          httpUpdatePrivateStockTransaction (values?.id, data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty onnistuneesti")})

            props.handleResolve()
          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})

            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
              // props.notify({ title:t("Kirjaus"), message:error?.data})
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }
        else {
          setSubmitting(true)

          //only backend can update batch_balance


          delete data.original_batch_balance
          delete data.batch_balance
          delete data.stock_market
          // delete data.price
          delete data.stock_market
          delete data.fifo_used
          delete data.wac_date_lte
          delete data.wac_date_gte
          delete data.wac_ids
          delete data.currency_exchange_rate
          delete data.currency_label
          delete data.date_settlement
          
          if (investmentType != "10"){
            data.ticker_symbol = null
            data.stock_market = null
          }

          httpUpdateTransaction(values?.id,data).then(res => {
            if(res?.data?.id){
              handleFileUpload([res?.data?.id], files)
            }
            props.notify({ title:t("Kirjaus"), message:t("Päivitetty onnistuneesti")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            props.handleResolve()

          }, error => {
            props.notify({ title:t("Kirjaus"), message:t("Päivitetys epäonnistui")})
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }
      }
      else {
        if (stockMarket === "PRIVATE") {
          setSubmitting(true)
          httpCreatePrivateStockTransaction(data).then(res => {
            props.notify({ title:t("Kirjaus"), message:t("Lisätty onnistuneesti")})

            props.handleResolve()
          }, error => {
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              setErrors(error?.data)
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
  
        }
        else {

          if (investmentType != "10"){
            data.ticker_symbol = null
            data.stock_market = null
          }

          // const oldDate = new Date(data.date_transaction);
          // const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), 12, 0, 0);
          // const formattedDate = newDate.toISOString().split('T')[0] +" 12:00:00Z";;
          // data.date_transaction = formattedDate;
          setSubmitting(true)
          httpCreateTransaction(data).then(res => {
            if(res?.data?.id){
              handleFileUpload([res?.data?.id], files)
            }

            props.notify({ title:t("Kirjaus"), message:t("Lisätty onnistuneesti")})
            if (investmentType == "10"){
                setFilters(s => ({
                    ...s,
                    // stock_market: stockMarket,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            else {
                setFilters(s => ({

                    ...s,
                    // stock_market: null,
                    // related_model_type: investmentType,
                    stock_market: null,
                    related_model_type: null,
                  }))
            }
            console.log("httpCreateStockTransaction res")
            props.handleResolve()
            console.log("after resolve")
          }, error => {
            console.log("httpCreateStockTransaction error", error);
            if (error?.status === 400) {
              // setErrors(error?.data)
              console.log("error?.data", error?.data)
              if (error?.data?.message) {
                props.notify({ title:t("Kirjaus"), message:error?.data?.message, type:'danger', autoDismiss:9})
              }
              else if (error?.data?.general) {
                props.notify({ title:t("Kirjaus"), message:error?.data?.general, type:'danger' , autoDismiss:9})
              }
              else {
                setErrors(error?.data)
              }
            }
            else {
              setErrors({
                general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
            
          }).finally(() => {
            setSubmitting(false)
          })
        }

      }

    },
    displayName: "BasicForm"
  
})(OtherEdit)
    
    
const OtherEditView = ({history, objectId,defaultStockMarket, defaultInvestmentType="10",portfoliosSummary, transaction_count, ticker_symbol, setFilters, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(defaultValues);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [totalValue, setTotalValue] = useState(0);
  const [totalValueEur, setTotalValueEur] = useState(0);
  const [stockMarket, setStockMarket] = useState(defaultStockMarket? defaultStockMarket : "XHEL");
  const [investmentType, setInvestmentType] = useState(defaultInvestmentType? defaultInvestmentType : "10");
  const [fundType, setFundType] = useState("private_stock");
  const [currencyDate, setCurrencyDate] = useState(1.0);
  const [securityTargetRate, setSecurityTargetRate] = useState(1.0);
  const [portfolioSourceRate, setPortfolioSourceRate] = useState(1.0);
  const [securitySourceRateDate, setSecuritySourceRateDate] = useState(null);
  const [currentStock, setCurrentStock] = useState(null);
  
  // Store
  const account = useStoreState((state) => state.account?.account);

  const options = useStoreState((state) => state.options?.options);
  const addOption = useStoreActions((actions) => actions.options.addOption);
  const stocks = useStoreState((state) => state.stocks?.objects);
  const stocksLoading = useStoreState((state) => state.stocks?.loading);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const getPrivateBasicDataObjects = useStoreActions((actions) => actions.privateStocks.getObjects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const [files, setFiles] = useState([]);
  const [trxFiles, setTrxFiles] = useState([]);
  
  useEffect(() => {
    if (portfolio) {
      setPreSetValues(s => ({
        ...s,
        // currency: portfolio?.currency_label
        currency: "EUR"
      }))
    }
  }, [portfolio])

  const findObject = useCallback ((id) => {
    // const filteredObjects = stocks && stocks.filter(o => o.id === id)
    // if (filteredObjects && filteredObjects.length > 0) {
    //   return filteredObjects[0]
    // }
    // return null
    let filteredObjects = null
    if (stockMarket === "PRIVATE") {
      filteredObjects = privateStocks && privateStocks.filter(o => o.id === id)
    }
    else {
      filteredObjects = stocks && stocks.filter(o => o.id === id)
    }

    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }, [stocks, stocksLoading])


  const handleFileUpload = (transactions) => {
    if (!files?.length) return
    const formData = new FormData();
    files.forEach(file => formData.append('file', file))
    // formData.append('files', files);
    formData.append('account', account?.id);
    formData.append('transactions', transactions)
  
    formData.append('portfolio', portfolio.id);
    // setLoadingSendExcel(true)
    httpUploadFiles(formData).then(res => {
      if (res?.data){
  
        console.log(res?.data)
      }
      setFiles([])
  
      // setLoadingSendExcel(false)
  
    }, error => {
      // setErrorText("Tapahtumien tuonti epäonnistui")
      if(error?.data?.message) {
        // setErrorMessage(t(error?.data?.message))
      }
      if(error?.data?.general) {
        //replace : with . to avoid rendering issues
        const general = error?.data?.general?.toString()
        const formattedError = general.replace(/:/g, '.')
        // setErrorMessage(t(formattedError))
      }
  
    }).finally(() => {
      // setLoadingSendExcel(false)
  
      // setLoadingImport(false)
    })
  
  
  };

  
  const getObject = (id) => {
    setLoading(true)

    if (stockMarket === "PRIVATE") {
      httpGetPrivateStockTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market_label)

  
        getPrivateBasicDataObjects({stock_market:res?.data?.stock_market_label})
        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          stock_private_obj:{id: res?.data?.stock, value:res?.data?.stock, label: findObject(res?.data?.stock)?.name},
  
          price: round(res?.data?.price, 2) * -1,
          value_total : round(res?.data?.value_total, 2) ,
          price_total : round(res?.data?.price_total, 2) ,
          commission_total : round(res?.data?.commission_total, 2) ,
        });
      
      }).finally(response => {
        setLoading(false);
      })
    }
    else{
      httpGetTransaction(id).then(res => {
        setStockMarket(res?.data?.stock_market)

        setTrxFiles(res?.data?.files)

  
        getBasicDataObjects({stock_market:res?.data?.stock_market, related_model_type: investmentType})
        setPreSetValues({
          ...defaultValues,
          ...res.data,
          date_transaction: res?.data?.date_transaction ? moment(res?.data?.date_transaction).toDate() : new Date(),
          date_settlement: res?.data?.date_settlement ? moment(res?.data?.date_settlement).toDate() : new Date(),
          stock:{id: res?.data?.related_model_id, value:res?.data?.related_model_id, label: res?.data?.related_model?.name, ticker_symbol: res?.data?.ticker_symbol, stock_market: res?.data?.related_model?.stock_market},
          // stock:{id: res?.data?.related_model_id, value:res?.data?.related_model_id, label: findObject(res?.data?.related_model_id)?.name},
  
          price: roundScaleDecimals(res?.data?.price, 6) * -1,
          value_total : roundScaleDecimals(res?.data?.value_total, 6) ,
          price_total : roundScaleDecimals(res?.data?.price_total, 6) ,
          commission_total : roundScaleDecimals(res?.data?.commission_total, 6) ,
          ticker_symbol : ticker_symbol
        });
      
      }).finally(response => {
        setLoading(false);
      })
    }


    
  }
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  if (loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center">
        <Spinner className="mt-3" />
      </Container>
    )
  }

  return (
    <>
    <PurchaseEditFormik 
      t={t}
      history={history}
      preSetValues={preSetValues}
      notify={notify}
      objectId={objectId}
      addOption={addOption}
      currencyDate={currencyDate}
      securityTargetRate={securityTargetRate}
      setSecurityTargetRate={setSecurityTargetRate}
      portfolioSourceRate={portfolioSourceRate}
      setPortfolioSourceRate={setPortfolioSourceRate}
      securitySourceRateDate={securitySourceRateDate}
      setSecuritySourceRateDate={setSecuritySourceRateDate}

      setCurrencyDate={setCurrencyDate}
      portfolio={portfolio}
      stocks={stocks}
      stockMarket={stockMarket}
      setStockMarket={setStockMarket}
      setInvestmentType={setInvestmentType}
      setFundType={setFundType}
      fundType={fundType}
      investmentType={investmentType}
      setFilters={setFilters}
      totalValue={totalValue}
      setTotalValue={setTotalValue}
      totalValueEur={totalValueEur}
      setTotalValueEur={setTotalValueEur}
      stocksLoading={stocksLoading}
      currentStock={currentStock}
      findeObject={findObject}
      transaction_count={transaction_count}
      account={account}
      portfoliosSummary={portfoliosSummary}
      setFiles={setFiles}
      files={files}
      trxFiles={trxFiles}
      setTrxFiles={setTrxFiles}
      handleFileUpload={handleFileUpload}

      {...rest} 
    />
    </>
    )
  

}
  

export default OtherEditView;

import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { tr } from "date-fns/locale";
import { TAGS } from "interweave";
import { Typography } from "stories/components"

import {
  FormGroup,
} from "reactstrap";

// const TransactionTypeSelect = ({ label,isMulti,placeholder,setSelected_mapping, onChange, value,showAll=true, name="type", ...rest }) => {
const TransactionTypeSelect = ({ label,isMulti,showAll,placeholder,setSelected_mapping, onChange,options, value, name="option_mapping_select", required, ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()

  const default_options = [
    { id: "empty", name: t("option_mapping_select", "-"), mapping: null },
  ]
  const new_options = default_options.concat(options)
  
  const getInputOptions = useCallback(() => {
    // const transactionTypes = enums?.transactions?.transaction?.type
    //filter and save only key 2,3,20,41
    return new_options && new_options.map(obj => {
      return (
        // <option key={obj.id} value={obj.id}> {obj.name}</option>
        {value:obj.id, mapping: obj.mapping, label:  obj.name}
      )
    })
  }, [options])


  const [files, setFiles] = useState([]);


  const handleSelectChange = (selectedOption) => {
      // setSelected_mapping(selectedOption)
      setSelected_mapping(selectedOption)
      // if (setFileSelectValue) {
      //     console.log("selectedOption", selectedOption)
      // setFileSelectValue(selectedOption); // Set the selected value in the parent component

      // //append the selected value to the array
      // setFileSelectValue([...fileSelectValue, selectedOption]);
      // }
  };



  const getInputOptionsMulti = useCallback(() => {
      //if show all is true add all option
    const transactionTypes = enums?.transactions?.transaction?.type.filter(obj => obj.key === 2 || obj.key === 3 || obj.key === 20 || obj.key === 41 || obj.key === 30 || obj.key === 32  || obj.key === 50 )

      let inputoptions = []
      if (showAll) {
          inputoptions = [{key: " ", value: t("Kaikki")}]
      }

      if (transactionTypes){

        inputoptions = [...inputoptions, ...transactionTypes]
      }

      return transactionTypes && inputoptions && inputoptions.map(obj => {
          return (
          {value:obj.key, label: t(`transactionTypeValue_${obj.key}`, obj.value)}
          )
      })
  }, [enums])
  
  return (
    <>
        <>
          <Typography bold>{label}</Typography>
          <FormGroup
        // className={`${focused} ? "focused" : ""} my-0` } style={{style}} 
    >
        {label && (
            <Typography bold className={`labelInline ? "d-inline" : ""`}>{label} {required ? "*" : ""}</Typography>
        )}
        <Select 
        label={label || label === "" ? label : t("option_mapping_select", "Valitse")}
        name={name} 
        options={getInputOptions()} 
        onChange={handleSelectChange}
        placeholder={placeholder?placeholder:t("option_mapping_select", "Valitse")}
        value={value}
        isMulti={isMulti}
        
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%'  // Make the select component take the full width of the container
          })
        }}
        // styles={{
          //     menuPortal: base => ({
            //       ...base,
            //       zIndex: 9999 // Set the desired z-index for the dropdown menu
            //     })
            //   }}
            {...rest} 
            />

    </FormGroup>

        </>
    </>
  )
  
  
}

export default TransactionTypeSelect;


import React, { use, useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import ImportColumnSelect from "components/Forms/ImportColumnSelect/ImportColumnSelect"
import ImportColumnSelectGeneral from "components/Forms/ImportColumnSelect/ImportColumnSelectGeneral"
// import {  httpUploadTransactionsExcelImport } from "services/transactions"
import {  httpUploadOptionsExcelImport, httpGetOptionColumnMapping } from "services/options"
import { Row, Col, Card,CardBody, Container } from "stories/layout";
import { Input, DatePicker, Checkbox } from "stories/forms";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PageHeader, PageContainer } from "components/Page"
import { useAppContext }from "contexts/AppContext"
import { Typography, Button, Link, IconButton, Modal, Popover, PopoverInfo, StepProgressBar, Spinner } from "stories/components";


import ImportNoBasicDataTable from 'views/app/ImportNoBasicDataTable/ImportNoBasicDataTable'
import ImportFoundedBasicDataV2 from 'views/app/ImportFoundedBasicData/ImportFoundedBasicDataV2'
import ImportCheckTransactionsV2 from 'views/app/ImportCheckTransactions/ImportCheckTransactionsV2'
import OptionMappingSelect from 'components/Forms/OptionMappingSelect/OptionMappingSelect'
import HelpText from "components/Info/HelpText.js"
import { set } from 'date-fns';



const ImportExcelOptions = ({handleResolve}, props) => {

    const { t } = useTranslation();
    const { enums } = useAppContext()

    let transactionTypes1 = enums?.transactions?.transaction?.type.filter(obj => obj.key === 2 || obj.key === 3 || obj.key === 20 || obj.key === 41 || obj.key === 30 )

    //add transactionTypes one item
    transactionTypes1 = [ ...transactionTypes1, {key: 200, value: t('VAIHTO JÄTTÖ'), optional: true},]

    const [file, setFile] = useState(null);
    const [fileSummary, setFileSummary] = useState(null);
    const [columns, setColumns] = useState([]);
    const [mapping, setMapping] = useState({});
    const [mappings, setMappings] = useState([]);
    const [transactionTypemappings, setTransactionTypemappings] = useState([]);
    const [errorText, setErrorText] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorMessageFields, setErrorMessageFields] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [headerRowIndex, setHeaderRowIndex] = useState(0);
    const [NordnetImport, setNordnetImport] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [nextStepBtn, setNextStepBtn] = useState(null);
    const [nextStepHandle, setNextStepHandle] = useState(1);
    const [basicDataRows, setBasicDataRows] = useState(null);
    const [foundedBasicDataRows, setFoundedBasicDataRows] = useState(null);
    const [checkTransactions, setCheckTransactions] = useState(null);
    const [exchange_transactions, setExchange_transactions] = useState()
    const [validBasicDatas, setValidBasicDatas] = useState(true)

    const [loadingSendExcel, setLoadingSendExcel] = useState(false);

    const [transactionTypes, setTransactionTypes] = useState(null)

    const [save_new_mapping, setSave_new_mapping] = useState(false)
    const [update_mapping, setUpdate_mapping] = useState(false)
    const [mapping_name, setMapping_name] = useState(null)
    const [selected_mapping, setSelected_mapping] = useState(null)
    
    const portfolio = useStoreState((state) => state.portfolio?.portfolio);


    const [mapping_models , setMapping_models] = useState(null)

    useEffect(() => {
        httpGetOptionColumnMapping().then(res => {
            setMapping_models(res?.data)
        })
    }, [])


    //--- Investime default options ---
    // const initialOptions = [
    //   { key: 'reference_code', value: t('importOptions_reference_code','ID'), optional: false },
    //   { key: 'date_transaction', value: t('importOptions_date_transaction','Tapahtuma päivä'), optional: false },
    //   { key: 'ticker_symbol', value: t('importOptions_ticker_symbol','Arvopaperin tunnus'), optional: false },
    //   { key: 'type', value: t('importOptions_type','Tapahtumatyyppi'), optional: false },
    //   { key: 'quantity', value: t('importOptions_quantity','Määrä'), optional: false },
    //   { key: 'price', value: t('importOptions_price','A-hinta (kurssi)'), optional: false },
    //   { key: 'price_total', value: t('importOptions_price_total','Kokonaishinta (määrä*A-hinta)'), optional: true },
    //   { key: 'commission_total', value: t('importOptions_commission_total','Kokonaiskulut'), optional: true },
    //   { key: 'value_total', value: t('importOptions_value_total','Yhteensä (kokonaishinta ja kulut)'), optional: true },
    //   { key:'stock_market', value: t('importOptions_stock_market','Pörssi'), optional: true },
    //   { key: 'date_settlement', value: t('importOptions_date_settlement','Selvityspäivä (maksupäivä)'), optional: true },
    //   { key: 'notes', value: t('importOptions_notes','Tapahtumateksti'), optional: true },
    //   { key: 'name', value: t('importOptions_name','Arvopaperin nimi'), optional: true },
    //   { key: 'isin', value: t('importOptions_isin','ISIN'), optional: true },
    //   { key: 'system_portfolio', value: t('importOptions_system_portfolio','Salkku'), optional: true },
    //   { key: 'exchange_rate', value: t('importOptions_exchange_rate','Vaihtokurssi'), optional: true },
    // ];

    const initialOptions = [
        { key: 'derivative_type', value: t('importOptions_derivative_type','Derivaatti tyyppi'), optional: false},  // EXCEL Product/ Option (if Option is forward thet derivative_type is forward 3 else option 2)
        { key: 'trade_id', value: t('importOptions_trade_id','Trade id'), optional: false}, // EXCEL Trade number
        { key: 'asset_name', value: t('importOptions_asset_name','Arvopaperi nimi'), optional: false}, // related_model_name EXEL Underliyng
        { key: 'writer_name', value: t('importOptions_writer_name','Kirjoittaja'), optional: false}, // EXCEL Company, but if it is buy option, then owner_name???
        { key: 'role', value: t('importOptions_role','Rooli'), optional: false}, //EXCEL Bought/Sold (sold 3 bought 2)
        { key: 'option_right', value: t('importOptions_option_right','Oikeus'), optional: false}, // EXCEL Option (put 3 or call 2) but if it is forward then 2
        { key: 'quantity', value: t('importOptions_quantity','Kokonaismäärä'), optional: false}, // EXCEL Notional
        { key: 'start_date', value: t('importOptions_start_date','Aloituspäivä'), optional: false}, // EXCEL Trade date
        { key: 'end_date', value: t('importOptions_end_date','Lopetuspäivä'), optional: false}, // EXCEL Expiry date
        { key: 'currency_1', value: t('importOptions_currency_1','Valuutta 1'), optional: false}, // EXCEL Currency
        { key: 'strike_price', value: t('importOptions_strike_price','Strike hinta'), optional: false}, // EXCEL Strike
        { key: 'fx_rate', value: t('importOptions_fx_rate','Vaihtokurssi'), optional: true }, // EXCEL FX rate
        { key: 'portfolio', value: t('importOptions_portfolio','Salkku'), optional: true }, 
        { key: 'asset_type', value: t('importOptions_asset_type','Arvopaperi tyyppi'), optional: true }, //related_model_type not same as in transactions
        { key: 'asset_id', value: t('importOptions_asset_id','Arvopaperi id'), optional: true }, // related_model_id
        { key: 'owner_name', value: t('importOptions_owner_name','Omistaja'), optional: true }, // eg Nordea, but not in file
        { key: 'lot_size', value: t('importOptions_lot_size','Erän koko'), optional: true }, // not in file
        { key: 'lot_amount', value: t('importOptions_lot_amount','Erän määrä'), optional: true }, // not in file
        { key: 'premium', value: t('importOptions_premium','Preemio/ Osake'), optional: true }, // --
        { key: 'premium_total', value: t('importOptions_premium_total','Preemio yhteensä'), optional: true }, // --
        { key: 'other_expenses', value: t('importOptions_other_expenses','Muut kulut'), optional: true }, // --
        { key: 'termination_fee', value: t('importOptions_termination_fee','Lopetus maksu'), optional: true }, // --
        { key: 'option_state', value: t('importOptions_option_state','Option tila'), optional: true }, // -- default open 
        { key: 'option_state_date', value: t('importOptions_option_state_date','Option tila päivä'), optional: true }, //-- null
        { key: 'exercise_price', value: t('importOptions_exercise_price','Harjoitus hinta'), optional: true }, // --
        { key: 'notes', value: t('importOptions_notes','Muistiinpanot'), optional: true }, // --
        { key: 'spot_price_manual', value: t('importOptions_spot_price_manual','Spot hinta manuaalinen'), optional: true }, 
        { key: 'currency_2', value: t('importOptions_currency_2','Valuutta 2'), optional: true },
    ];

    // const initialOptions = [
    //     { key: 'trade_id', value: t('importOptions_trade_id','Trade id'), optional: false },
    //     { key: 'derivative_type', value: t('importOptions_derivative_type','Derivative type'), optional: false },
    //     { key: 'asset_type', value: t('importOptions_asset_type','Asset type'), optional: false },
    //     { key: 'asset', value: t('importOptions_asset','Asset'), optional: false },
    //     { key: 'right', value: t('importOptions_right','Right'), optional: false },
    //     { key: 'owner', value: t('importOptions_owner','Owner'), optional: false },
    //     { key: 'writer', value: t('importOptions_writer','Writer'), optional: false },
    //     { key: 'lot_size', value: t('importOptions_lot_size','Lot size'), optional: false },
    //     { key: 'lot_amount', value: t('importOptions_lot_amount','Lots amount'), optional: false },
    //     { key: 'quantity', value: t('importOptions_quantity','Total quantity'), optional: false },
    //     { key: 'start_date', value: t('importOptions_start_date','Start date'), optional: false },
    //     { key: 'end_date', value: t('importOptions_end_date','End date'), optional: false },
    //     { key: 'premium', value: t('importOptions_premium','Premium/ Share'), optional: false },
    //     { key: 'premium_total', value: t('importOptions_premium_total','Premium total'), optional: false },
    //     { key: 'other_expenses', value: t('importOptions_other_expenses','Other exp.'), optional: false },
    //     { key: 'strike_price', value: t('importOptions_strike_price','Strike price'), optional: false },
    //     { key: 'spot_price', value: t('importOptions_spot_price','Spot price'), optional: false },
    //     { key: 'current_outcome', value: t('importOptions_current_outcome','Current outcome'), optional: false },
    //     { key: 'state', value: t('importOptions_state','State'), optional: false },
    //     { key: 'option_state_date', value: t('importOptions_option_state_date','State date'), optional: false },
    //     { key: 'exercise_price', value: t('importOptions_exercise_price','Exercise price'), optional: false },
    //     { key: 'termination_fee', value: t('importOptions_termination_fee','Termination fee'), optional: false },
    //     { key: 'exercise_outcome', value: t('importOptions_exercise_outcome','Exercise outcome'), optional: false },
    //     { key: 'notes', value: t('importOptions_notes','Notes'), optional: false },
    // ];
    
    const fileInputRef = React.createRef();
    const [availableOptions, setAvailableOptions] = useState(null);
    const [transactionAvailableOptions, setTransactionAvailableOptions] = useState(null);
    const [transactionInitialOptions, setTransactionInitialOptions] = useState(null);
    const [transactionTypesExcel, setTransactionTypesExcel] = useState(null);
    
    //---loop transactions types and create like initialOptions but for transaction types---
    useEffect(() => {
        let newInitialOptions = []
        transactionTypes1 && transactionTypes1.forEach((type) => {
            newInitialOptions.push({key:type.key, value:t(`transactionTypeValue_${type.key}`, type.value), optional:true})
        })
        setTransactionTypes(newInitialOptions)
        setTransactionTypemappings(newInitialOptions.map((type,index) => { return { key:index, key2:type.key, value: "" } }));
        // setTransactionAvailableOptions(newInitialOptions)
        // setTransactionInitialOptions(newInitialOptions)
    }, [])


    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    //---File upload---
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];

      //if no selectedFile return and set errorText
      if (!selectedFile){
        setErrorText(t("importTransactionsV2_choose_file","Valitse tiedosto (.xlsx)"))
        return
      }
      setErrorText(null)

      //Check that file is xlsx
      if (selectedFile?.name?.split('.').pop() !== "xlsx" && selectedFile?.name?.split('.').pop() !== "xls"){
        setErrorText(t("importTransactionsV2_file_format","Tiedoston tulee olla xlsx tai xls muodossa"))
        return
      }


      setFile(selectedFile);
      setMappings({}); // Reset the mapping when a new file is uploaded
  
      // Parse the Excel file and extract column names
      const reader = new FileReader();
      reader.onload = (e) => {
        
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
  
        // Assuming the first sheet of the Excel file contains the data
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let headerRow = null;
        for (let i = 0; i < jsonData.length; i++) {
          const row = jsonData[i];
          if (row.some((cell) => cell !== null && cell !== "")) {
            headerRow = row;
            break;
          }
        }

        
      
        if (headerRow !== null) {
          // Extract column names from the header row
          const header = headerRow;
      
          // Add Excel column letters to the header
          const headerWithLetters = header.map((column, index) => {
             const columnLetter = getExcelColumnLetter(index );
            return `${columnLetter} - ${column}`;
          });
          console.log("---", headerWithLetters, header)
        //   setColumns(headerWithLetters);
          setColumns(header.map((column, index) => { return { key: column, value: index, label:column} }));
          setAvailableOptions(header.map((column, index) => { return {  key: column, value: index, label:column} }));
          setMappings(initialOptions.map((column, index) => { return { key: index,key2: column?.key, value: "" } }));
        }
      };
      // Function to calculate Excel column letter
      function getExcelColumnLetter(columnNumber) {
          let columnName = "";
          while (columnNumber >= 0) {
          let remainder = columnNumber % 26;
          columnName = String.fromCharCode(65 + remainder) + columnName;
          columnNumber = Math.floor(columnNumber / 26) - 1;
          }
          return columnName;
      }
  
      reader.readAsArrayBuffer(selectedFile);
    };


    //---Read transaction types from Excel file---
    const readTypes = (index) => {
        const reader = new FileReader();
                  
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          
          // Assuming the first sheet of the Excel file contains the data
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          let cellValues = []
            
          let columnIndexToRead = index; // Change this to the index of the column you want to read
        
          // Start from index 1 to exclude the header row
          for (let i = 1; i < jsonData.length; i++) {
            const row = jsonData[i];
        
            // Check if the columnIndexToRead is within the row's length
            if (columnIndexToRead < row.length) {
              const cellValue = row[columnIndexToRead];
              cellValues.push(cellValue);
            }
          }
          const uniqueColumnValues = [...new Set(cellValues)];

          //filter undefined values out of uniqueColumnValues
          const filteredArray = uniqueColumnValues.filter(value => value !== undefined);

          //Convert all filteredArray values to string
          filteredArray.forEach((value, index) => {
            filteredArray[index] = String(value)
          })




          setTransactionTypesExcel(filteredArray)

          if(NordnetImport){

            setTransactionTypemappings(filteredArray.map((column) => { 
              if (column === "OSTO"){
                return { key: String(column), value: 2, optional: true }
              }
              if (column === "MYYNTI"){
                return { key: String(column), value: 3, optional: true }
              }
              if (column === "SPLIT"){
                return { key: String(column), value: 20, optional: true }
              }
              if (column === "VAIHTO AP-OTTO"){
                return { key: String(column), value: 41, optional: true }
              }
              if (column === "OSINKO"){
                return { key: String(column), value: 30, optional: true }
              }
              if (column === "VAIHTO AP-JÄTTÖ"){
                return { key: String(column), value: 200, optional: true }
              }

              return { key: String(column), value: "" } 
            }));

          }
          else {
        //   setTransactionTypemappings(filteredArray.map((column) => { return { key: String(column), value: "" } }));

             setTransactionAvailableOptions(filteredArray.map((column, index) => { return {  key: column, value: index, label:column} }))
             setTransactionInitialOptions(filteredArray.map((column, index) => { return {  key: column, value: index, label:column} }))
          }
        };
        
        // Make sure to invoke the readAsArrayBuffer method on the FileReader with your Excel file variable
        reader.readAsArrayBuffer(file);
    }
  

    const handleOptionChange = (event, index, value) => {

        const selectedValue = event.target.value;
        //filter out selected value from available options
        if (selectedValue || true) {
            // if selected value is in available options, remove it from available options
            const removeAvailableOptions = availableOptions.filter(option => option.value != selectedValue)
            // setAvailableOptions(removeAvailableOptions)
            //add earlier selected value back to available options (initialOptions where option.key == value)
            const addAvailableOptions = [ ...columns.filter(option => option.value == value?.value && value?.value != ""), ...removeAvailableOptions] 
            //filter initial options based on addAvailableOptions, use map
            const newAvailableOptions = columns.filter(option => addAvailableOptions.map(option => option.value).includes(option.value))
            setAvailableOptions(newAvailableOptions)
            
            let updatedMappings = [...mappings];
            // updatedMappings[index] = {key: updatedMappings[index].key,key2:updatedMappings[index].key2, value: selectedValue};
            updatedMappings[index].value = selectedValue;
            setMappings(updatedMappings);

            setErrorText(null)

            // Get transaction types from Excel file when the "type" column is selected
            console.log("updatedMappings", updatedMappings[index])
            if (updatedMappings[index].key2 === "type") {
              readTypes(selectedValue)
              console.log("selectedValue", selectedValue)   
            }

        }
    };

    const handleOptionChangeTransactionType = (event, index, value) => {
        const selectedValue = event.target.value;
        if (selectedValue || true) {
            console.log("selectedValue", selectedValue)
            console.log("value", value)
            // if selected value is in available options, remove it from available options
            const removeAvailableOptions = transactionAvailableOptions.filter(option => option.value != selectedValue)
            console.log("removeAvailableOptions", removeAvailableOptions)
            // setAvailableOptions(removeAvailableOptions)
            //add earlier selected value back to available options (initialOptions where option.key == value)
            const addAvailableOptions = [ ...transactionInitialOptions.filter(option => option.value == value?.value && value?.value), ...removeAvailableOptions]
            console.log("addAvailableOptions", addAvailableOptions)
            //filter initial options based on addAvailableOptions, use map
            const newAvailableOptions = transactionInitialOptions.filter(option => addAvailableOptions.map(option => option.value).includes(option.value))
            console.log("newAvailableOptions", newAvailableOptions)

            // setTransactionAvailableOptions(newAvailableOptions)

            let updatedMappings = [...transactionTypemappings];
            updatedMappings[index].value = selectedValue
            setTransactionTypemappings(updatedMappings);

            setErrorText(null)
        }
    };





    const getSelectedOptionsValue = (key) => {
        return initialOptions.filter(option => option.key === key?.value)?.[0]?.value
    }
    const getSelectedOptionsValueTransactionType = (key) => {
        return transactionInitialOptions.filter(option => option.key == key?.value)?.[0]?.value
    }

  
    const handleFileUpload = (transaction_save, chect_transactions) => {

      //Validate data before sending

      //All required columns must be selected
    //   if (availableOptions.length > 0) {
    //       if (availableOptions.filter(option => option.optional === false).length > 0) {
    //           setErrorText("Kaikkia pakollisia Investimen sarakkeita * ei ole valinnalla yhdistetty")
    //           return
    //       }

    //   }
      //loop all initialOptions and check that all required columns are selected
        if (mappings && mappings.length > 0) {
            if (mappings.filter(option => option.key2 && option.value === "" || option.value === " ").filter(option => initialOptions.filter(initialOption => initialOption.key === option.key2 && initialOption.optional === false).length > 0).length > 0) {
                setErrorText(t("options_required_headers","Kaikkia pakollisia Investimen sarakkeita * ei ole valittu"))
                return
            }
        }


      //At least one transaction type must be selected
      if (!transaction_save && transactionAvailableOptions && transactionAvailableOptions.length > 0) {
        if (transactionAvailableOptions.filter(option => option.optional === false).length > 0) {
            setErrorText("Kaikkia pakollisia Investimen tapahtuman tyyppejä ei ole valinnalla yhdistetty")
            return
        }
        //if availableOptions list are same as initialOptions list, then all options are selected
        // if (transactionAvailableOptions.length === transactionInitialOptions.length) {
        //   setErrorText("Yhtään Investimen tapahtuman tyyppiä ei ole valittu")
        //   return
        // }
        if (transactionTypemappings && transactionTypemappings.length > 0) {
            //loop all and check that even ones value is not "" or  " " that it
            if (transactionTypemappings.filter(option => option.value === "" || option.value === " ").length === transactionTypemappings.length) {
                setErrorText("Yhtään Investimen tapahtuman tyyppiä ei ole valittu")
                return
            }

        }
      }

      // //Check that transaction not already exists
      // if (transaction_save && checkTransactions?.already_imported_transactions && checkTransactions?.already_imported_transactions.length > 0) {
      //   setErrorText(t('importTransactionsV2_cant_import_same_transactions','Jo olemassa olevia tapahtumia ei voi tuoda uudestaan'))
      //   return
      // }

      //Check that there is no younger transaction in portfolio
      if (transaction_save && checkTransactions?.already_exists_younger_transactions && checkTransactions?.already_exists_younger_transactions.length > 0) {
        setErrorText(t('importTransactionsV2_cant_import_older_what_is_in_portfolio','Et voi tuoda vanhempia tapahtumia kuin mitä salkussa jo on olemassa ko arvopaperille')) 
        return
      }
      
      let data_valid = true
      if (transaction_save){
        exchange_transactions && exchange_transactions?.forEach((row, index) => {
          const exchange_in_transactions = row?.exchange_in_transactions
          const purchase_cost_total_sum = row?.purchase_cost_total_sum



                if (exchange_in_transactions && exchange_in_transactions.length > 0) {
                    let new_pusrchase_cost_total_sum = 0
                    let new_purchase_cost_percentage_sum = 0
                    for (const key in exchange_in_transactions) {
                      const item = exchange_in_transactions[key];
                      const value = Number(item?.new_purchase_cost) || 0
                      new_pusrchase_cost_total_sum = new_pusrchase_cost_total_sum + value
                      new_purchase_cost_percentage_sum = new_purchase_cost_percentage_sum + (Number(item?.new_purchase_cost_percentage) || 0)
                    }
                    if ( Math.abs(new_pusrchase_cost_total_sum - purchase_cost_total_sum) > 0.001){
                      setErrorMessage(t("importTransactionsV2_purchase_cost_total_sum_not_equal", "Uudelle tai uusille arvopapereille asetetun hankintamenon yhteismäärän tulee olla sama kuin vanhan arvopaperin hankintameno."))
                      // setErrorMessage(`${purchase_cost_total_sum}  -  ${new_pusrchase_cost_total_sum}`)
                      data_valid = false
                      return
                    }
                }
        })
      }

      if (data_valid == false){
        return
      }

      setErrorText(null)
      setErrorMessage(null)

      const validated_mappings = columns.map((mapping) => {
        return {
            key: mapping.value,
            // value: mappings.filter(option => option.value == mapping.value)?.[0]?.key2 || "",
            value: mappings.filter(option => {
                if (option.value === "" || option.value === " ") {
                    return mapping.value === "";  // Check if mapping.value should be empty string
                } else {
                    return Number(option.value) === mapping.value;  // Compare numeric values
                }
            })?.[0]?.key2 || "",
        }
        })

    //   const validated_type_mappings = transactionInitialOptions.map((mapping) => {    
    //     return {
    //         key: mapping.key,
    //         value:  transactionTypemappings.filter(option => option.value == mapping.value)?.[0]?.key2 || "",
    //     }
    //     })


      

      // Perform the file upload to your Django backend here
      // Send the file and the mapping to the API endpoint
      // Example: Using the Fetch API
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileSummary', fileSummary);
    //   formData.append('column_mapping', JSON.stringify(mappings));
      formData.append('column_mapping', JSON.stringify(validated_mappings));
    //   formData.append('types', JSON.stringify(validated_type_mappings));
      formData.append('header_row_index', headerRowIndex);
      formData.append('transaction_save', transaction_save);
      formData.append('check_transactions', chect_transactions);
      formData.append('basicDataRows', JSON.stringify(basicDataRows));
      formData.append('exchange_transactions', JSON.stringify(exchange_transactions));
      formData.append('update_mapping' , update_mapping)
      formData.append('save_new_mapping' , save_new_mapping)
      formData.append('mapping_name' , mapping_name)
      formData.append('selected_mapping_id' , selected_mapping?.value)

      formData.append('portfolio', portfolio.id);
      setLoadingSendExcel(true)
      httpUploadOptionsExcelImport(formData).then(res => {
        let b_data = []
        if (res?.data?.data.length > 0){
          b_data = res?.data?.data.map((item)=>{
            return {
              ...item,
              size: 0,
              currency_label: "EUR",
              security_type: "private_stock",
            }
          })
        }

        setBasicDataRows(b_data)
        setFoundedBasicDataRows(res?.data?.find_basic_data)
        setCheckTransactions(res?.data?.data)
        setNextStepHandle((prevStep) => prevStep + 1)
        setLoadingSendExcel(false)

        // setExcelFile(null)
        // fileInputRef.current.value = "";
        // notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
        // getTransactions()
      }, error => {
        setBasicDataRows([])
        setCheckTransactions(null)
        setFoundedBasicDataRows([])
        setErrorText("Tapahtumien tuonti epäonnistui")
        if(error?.data?.message) {
          setErrorMessage(t(error?.data?.message))
        }
        if (error?.data?.unvalid_transactions && error?.data?.unvalid_transactions.length > 0){ 
          setErrorMessageFields(error?.data?.unvalid_transactions[0])
        }
        if(error?.data?.general) {
          //replace : with . to avoid rendering issues
          const general = error?.data?.general?.toString()
          const formattedError = general.replace(/:/g, '.')
          setErrorMessage(t(formattedError))
        }
        setNextStepHandle((prevStep) => prevStep + 1)

      }).finally(() => {
        setLoadingSendExcel(false)

        // setLoadingImport(false)
      })

    
    };


    const handleFileUploadWithBasicData= () => {

        const validated_mappings = columns.map((mapping) => {
            return {
                key: mapping.value,
                // value: mappings.filter(option => option.value == mapping.value)?.[0]?.key2 || "",
                value: mappings.filter(option => {
                    if (option.value === "" || option.value === " ") {
                        return mapping.value === "";  // Check if mapping.value should be empty string
                    } else {
                        return Number(option.value) === mapping.value;  // Compare numeric values
                    }
                })?.[0]?.key2 || "",
            }
            })
    
          // const validated_type_mappings = transactionInitialOptions.map((mapping) => {    
          //   return {
          //       key: mapping.key,
          //       value:  transactionTypemappings.filter(option => option.value == mapping.value)?.[0]?.key2 || "",
          //   }
          //   })

      if (basicDataRows.length > 0) {
        let pluralBasicDataSymbol = {}
        let name_given = true

 

        for (const security of basicDataRows) {
          if (pluralBasicDataSymbol[security?.ticker_symbol]) {
            pluralBasicDataSymbol[security?.ticker_symbol]++;
          } else {
            pluralBasicDataSymbol[security?.ticker_symbol] = 1;
          }
          if (security?.name == "" || security?.name == null) {
            name_given = false
          }

        }
        let emptyRows = basicDataRows.filter(row => 'security_type' in row === false || 'size' in row === false ||'currency_label' in row === false || row?.security_type === null || row?.security_type == "" || row?.currency_label === null ||row?.currency_label == "" || row?.size === null || row?.size ==="" )

        let not_valid = false
        emptyRows && emptyRows.forEach(row => {
          if (pluralBasicDataSymbol[row?.ticker_symbol] == 1){
            setErrorText(t('importTransactionsV2_check_basic_datas','Tarkista ja täytä kaikki arvopaperin tiedot. Mikäli useampi arvopaperi samalla symbolilla vähintään yhdestä on luotava arvopaperi.'))
            not_valid = true
            
          }
          if (pluralBasicDataSymbol[row?.ticker_symbol] > 1){
            pluralBasicDataSymbol[row?.ticker_symbol]--;
          }
        });
        if (not_valid){
          return
        }
        if (name_given == false){
          setErrorText(t('importTransactionsV2_give_name','Anna kaikille arvopapereille nimi'))
          return
        }
      }
      setErrorText(null)
      setErrorMessage(null)
   

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileSummary', fileSummary);


      formData.append('column_mapping', JSON.stringify(validated_mappings));
      // formData.append('types', JSON.stringify(validated_type_mappings));
      formData.append('header_row_index', headerRowIndex);
      formData.append('portfolio', portfolio.id);
      formData.append('basicDataRows', JSON.stringify(basicDataRows));
      formData.append('check_transactions', true);

      formData.append('basicDataImport', true)
      setLoadingSendExcel(true)
      httpUploadOptionsExcelImport(formData).then(res => {

        setBasicDataRows(res?.data?.data)
        setFoundedBasicDataRows(res?.data?.find_basic_data)
        setCheckTransactions(res?.data?.data)

        setNextStepHandle((prevStep) => prevStep + 1)
        setLoadingSendExcel(false)

      // setExcelFile(null)
      // fileInputRef.current.value = "";
      // notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
      // getTransactions()
    }, error => {
      setBasicDataRows([])
      setCheckTransactions(null)
      setFoundedBasicDataRows([])
      setErrorText("Tapahtumien tuonti epäonnistui")
      if(error?.data?.message) {
        setErrorMessage(t(error?.data?.message))
      }
      if(error?.data?.general) {
        //replace : with . to avoid rendering issues
        const general = error?.data?.general?.toString()
        const formattedError = general.replace(/:/g, '.')
        setErrorMessage(t(formattedError))
      }
      setNextStepHandle((prevStep) => prevStep + 1)
      
    }).finally(() => {
      setLoadingSendExcel(false)
      // setLoadingImport(false)
    })

  };

  const handleCheckboxChange = (isChecked) => {
      setNordnetImport(!NordnetImport)
      if(!NordnetImport){
        // setMappings([ 
        //     { key: 0, value: 'reference_code', optional: true },
        //     { key: 1, value: 'date_record', optional: true },
        //     { key: 2, value: 'date_transaction', optional: false },
        //     { key: 3, value: 'date_settlement', optional: true },
        //     { key: 4, value: 'system_portfolio', optional: true },
        //     { key: 5, value: 'type', optional: false },
        //     { key: 6, value: 'ticker_symbol', optional: true },
        //     { key: 7, value: 'instrument_type', optional: true },
        //     { key: 8, value: 'isin', optional: true },
        //     { key: 9, value: 'quantity', optional: false },
        //     { key: 10, value: 'price', optional: false },
        //     { key: 11, value: 'interest', optional: true },
        //     { key: 12, value: 'commission_total', optional: false },
        //     { key: 13, value: 'commission_total_currency', optional: true },
        //     { key: 14, value: 'value_total', optional: true },
        //     { key: 15, value: 'value_total_currency', optional: true },
        //     { key: 16, value: 'purchase_cost_total', optional: true },
        //     { key: 17, value: 'purchase_cost_total_currency', optional: true },
        //     { key: 18, value: 'profit', optional: true },
        //     { key: 19, value: 'profit_currency', optional: true },
        //     { key: 20, value: 'balance', optional: true },
        //     { key: 21, value: 'money_balance', optional: true },
        //     { key: 22, value: 'exchange_rate', optional: true },
        //     { key: 23, value: 'notes', optional: true },
        //     { key: 24, value: 'date_voiding', optional: true },
        //     { key: 25, value: 'laskelma', optional: true },
        //     { key: 26, value: 'vahvistusnumero', optional: true },
        //     { key: 27, value: 'commission_basic_amount', optional: true },
        //     { key: 28, value: 'commission_basic_amount_currency', optional: true },
        //     { key: 29, value: 'stock_market', optional: true },

        // ]);


        // { key: 'derivative_type', value: t('importOptions_derivative_type','Derivaatti tyyppi'), optional: true },  // EXCEL Product/ Option (if Option is forward thet derivative_type is forward 3 else option 2)
        // { key: 'trade_id', value: t('importOptions_trade_id','Trade id'), optional: true }, // EXCEL Trade number
        // { key: 'asset_name', value: t('importOptions_asset_name','Arvopaperi nimi'), optional: true }, // related_model_name EXEL Underliyng
        // { key: 'writer_name', value: t('importOptions_writer_name','Kirjoittaja'), optional: true }, // EXCEL Company, but if it is buy option, then owner_name???
        // { key: 'role', value: t('importOptions_role','Rooli'), optional: true }, //EXCEL Bought/Sold (sold 3 bought 2)
        // { key: 'option_right', value: t('importOptions_option_right','Oikeus'), optional: true }, // EXCEL Option (put 3 or call 2) but if it is forward then 2
        // { key: 'quantity', value: t('importOptions_quantity','Kokonaismäärä'), optional: true }, // EXCEL Notional
        // { key: 'start_date', value: t('importOptions_start_date','Aloituspäivä'), optional: true }, // EXCEL Trade date
        // { key: 'end_date', value: t('importOptions_end_date','Lopetuspäivä'), optional: true }, // EXCEL Expiry date
        // { key: 'currency_1', value: t('importOptions_currency_1','Valuutta 1'), optional: true }, // EXCEL Currency
        // { key: 'strike_price', value: t('importOptions_strike_price','Strike hinta'), optional: true }, // EXCEL Strike
        // { key: 'fx_rate', value: t('importOptions_fx_rate','Vaihtokurssi'), optional: true }, // EXCEL FX rate
        // { key: 'asset_type', value: t('importOptions_asset_type','Arvopaperi tyyppi'), optional: true }, //related_model_type not same as in transactions
        // { key: 'asset_id', value: t('importOptions_asset_id','Arvopaperi id'), optional: true }, // related_model_id
        // { key: 'owner_name', value: t('importOptions_owner_name','Omistaja'), optional: true }, // eg Nordea, but not in file
        // { key: 'lot_size', value: t('importOptions_lot_size','Erän koko'), optional: true }, // not in file
        // { key: 'lot_amount', value: t('importOptions_lot_amount','Erän määrä'), optional: true }, // not in file
        // { key: 'premium', value: t('importOptions_premium','Preemio/ Osake'), optional: true }, // --
        // { key: 'premium_total', value: t('importOptions_premium_total','Preemio yhteensä'), optional: true }, // --
        // { key: 'other_expenses', value: t('importOptions_other_expenses','Muut kulut'), optional: true }, // --
        // { key: 'termination_fee', value: t('importOptions_termination_fee','Lopetus maksu'), optional: true }, // --
        // { key: 'option_state', value: t('importOptions_option_state','Option tila'), optional: true }, // -- default open 
        // { key: 'option_state_date', value: t('importOptions_option_state_date','Option tila päivä'), optional: true }, //-- null
        // { key: 'exercise_price', value: t('importOptions_exercise_price','Harjoitus hinta'), optional: true }, // --
        // { key: 'notes', value: t('importOptions_notes','Muistiinpanot'), optional: true }, // --
        // { key: 'spot_price_manual', value: t('importOptions_spot_price_manual','Spot hinta manuaalinen'), optional: true }, 
        // { key: 'currency_2', value: t('importOptions_currency_2','Valuutta 2'), optional: true },

   

        setMappings([
            { key: 1, key2: 'derivative_type', value: 0},
            { key: 2, key2: 'trade_id', value: 1},
            { key: 3, key2: 'asset_name', value: 27},
            { key: 4, key2: 'writer_name', value: 31},
            { key: 5, key2: 'role', value: 8},
            { key: 6, key2: 'option_right', value: 16},
            { key: 7, key2: 'quantity', value: 11},
            { key: 8, key2: 'start_date', value: 2},
            { key: 9, key2: 'end_date', value: 19},
            { key: 10, key2: 'currency_1', value: 10},
            { key: 11, key2: 'strike_price', value: 17},
            { key: 12, key2: 'fx_rate', value: 32},

        ])

        setTransactionTypemappings([
            { key: "OSTO", value: 2, optional: true },
            { key: "MYYNTI", value: 3, optional: true },
            { key: "SPLIT", value: 20, optional: true },
            { key: "VAIHTO AP-OTTO", value: 41, optional: true },
            { key: "OSINKO", value: 30, optional: true },
            { key: "VAIHTO AP-JÄTTÖ", value: 200, optional: true },
        ]);
        
        setAvailableOptions([])
        setErrorText(null)
        readTypes(5)
      }
      else{
        setMappings(columns.map((column) => { return { key: column, value: "" } }));
        setAvailableOptions(columns)
      }
  };

  useEffect(() => {
    if (selected_mapping && selected_mapping?.mapping !== null ){
      // setMappings(initialOptions.map((column, index) => { return { key: index,key2: column?.key, value: selected_mapping.filter(item => item.value == column?.key).key  } }));
      setMappings(
        initialOptions.map((column, index) => {
          const matchedItem = selected_mapping.mapping.find(item => item.value === column?.key);
          return {
            key: index,
            key2: column?.key,
            value: matchedItem ? matchedItem.key : null, // Safely handle cases where no match is found
          };
        })
      );
      setMapping_name(selected_mapping?.name)
    }
    else{
      setMappings(columns.map((column) => { return { key: column, value: "" } }));
    }
  }, [selected_mapping])

  const getFileName = useCallback((file) => {
    if (file) {
      return file.name;
    }
    return t("(xlsx, xls)");
  }, [file]);


  const getExcelColumnLetter = (columnNumber) => {
      let columnName = "";
      while (columnNumber >= 0) {
          let remainder = columnNumber % 26;
          columnName = String.fromCharCode(65 + remainder) + columnName;
          columnNumber = Math.floor(columnNumber / 26) - 1;
      }
      return columnName;
  }

  const numberToColumnLetter = (number) =>  {
      let columnLetter = '';
      while (number >= 0) {
        columnLetter = String.fromCharCode((number % 26) + 65) + columnLetter;
        number = Math.floor(number / 26) - 1;
      }
      return columnLetter;
    }



  const handleSelect = (index, selectedValue) => {
      // Update the mapping for the selected column at the given index
      const updatedMappings = [...mappings];
      updatedMappings[index] = selectedValue;
      setMappings(updatedMappings);
    };

  const handleSelectTransactionType = (index, selectedValue) => {
      // Update the mapping for the selected column at the given index
      const updatedMappings = [...transactionTypemappings];
      updatedMappings[index] = selectedValue;
      setTransactionTypemappings(updatedMappings);
      };
    
  const getErrorText = useCallback(() => {
      if (errorText) {
          return (
            <>{errorText}</>
          );
      }
      return null;
  }, [errorText, validBasicDatas, file]);


  const moveToSecondStep = () => {
    if(!file){
      setErrorText(t("importTransactionsV2_choose_file","Valitse tiedosto (.xlsx)"))
      return
    }
    setNextStepHandle((prevStep) => prevStep + 1)
  }

          
  const getContext = useCallback(() => {
      if (nextStepHandle === 1) {
          return "import_transactions_step1"
      }
      if (nextStepHandle === 2) {
          return "import_transactions_step2"
      }
      if (nextStepHandle === 3) {
          return "import_transactions_step3"
      }
      if (nextStepHandle === 4) {
          return "import_transactions_step4"
      }
      if (nextStepHandle === 5) {
          return "import_transactions_step5"
      }
      return null;
  }, [nextStepHandle]);


  const getColumns= useCallback(() => {
      return(
        <Container fluid>
          <div className="row justify-content-start">
            {/* <div className="ml-3 mb-3 mt-4">
              <Checkbox
                id="unique_checkbox_id"
                name='NordnetImport'
                checked={NordnetImport}
                label={t("import_from_nordea", "Tuonti Nordea")}
                onChange={(e) => {handleCheckboxChange(e.target.checked)}}
              />
            </div> */}
            <div className="ml-3 mb-0 mt-5" style={{minWidth: "200px"}}> 
              <OptionMappingSelect options={mapping_models} setSelected_mapping={setSelected_mapping} placeholder={t("option_mapping_select_placeholder", "Valitse malli")}/>
            </div>
          </div>



          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_investime_column", "Investime sarake")}</label>
            </Col>
            {/* <Col  >
            {JSON.stringify(availableOptions)}
            </Col> */}
            <Col sm={12} lg={8} >
              <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_excel_column", "Tuotavan excelin sareke")}</label>
            </Col>
          </Row>
          {/* {JSON.stringify(mappings)} */}

          {initialOptions && initialOptions.map((column, index) => (
            <div key={index}>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <Typography variant="h4" style={{transition: 'padding-left 0.5s' }} className={`mb-1 pr-1 header ${mappings[index]?.value !== "" && mappings[index]?.value !== " " ? ' pl-3 transitioning text-warning' : 'text-dark'}`}>{column?.value}{!column?.optional && " *"}</Typography>

                  {column?.key == "value_total" && <div className="mb-1"><HelpText contentKey="import_value_total" parentElementId="import_value_total" showButton={true}  /></div>}
                  {column?.key == "commission_total" && <div className="mb-1"><HelpText contentKey="import_commission_total" parentElementId="import_commission_total" showButton={true}  /></div>}
                </Col>
                       
                <Col sm={12} lg={8} >
                    
                  <ImportColumnSelectGeneral
                    initialOptions={columns}
                    selectedOptions={mappings}
                    availableOptions={availableOptions}
                    onChange={(e) => handleOptionChange( e, index, mappings[index])}
                    // value={getSelectedOptionsValue(mappings[index])}
                    value={columns[mappings[index]?.value]?.label}
                    label =" "
                    placeholder="Valitse"
                    name={column.key+index}
                    onSelect={(selectedValue) =>handleSelect(index, selectedValue, )}
                    className={mappings[index]?.value !== "" && mappings[index]?.value !== " " ? 'text-warning' : 'text-dark'}
                  />
                </Col>
              </Row>

            </div>

            
          ))}
            <Row>
                <Col className="my-1 mt-4">
                <Typography variant="h3" className="text-dark"> 

                  {t("importTransactionsV2_column_mapping_header", "Luo yhdistämisestä malli tai päivitä olemassa oleva")}
                </Typography>
                </Col>

            </Row>
            <Row className="mt-4">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                <div className="ml-0 mb-2">
                  <Checkbox 
                    id={"new_mapping_checkbox_id"}
                    name="save_new_mapping"
                    checked={save_new_mapping}
                    onChange={(e) => {
                      setSave_new_mapping(e.target.checked)
                      setUpdate_mapping(false)
                      setMapping_name("")
                    }}
                    value={save_new_mapping}
                    className="zIndexZero"
                    required
                    label={<>{t("option_create_new_mapping", 'Tallenna uusi malli')}</>}
                    />
                </div>
                </Col >
                {save_new_mapping &&
                  <Col  sm={12} lg={8} >
                    <Input
                        type="text"
                        name="new_mapping_name"
                        onChange={(e) => {setMapping_name(e.target.value)}}
                        
                        // placeholder={t("reference_code", "Viite")}
                        placeholder={t("importTransactionsV2_new_mapping_name", "Uuden mallin nimi")}
                        
                        />
                  </Col>
                }
            </Row>
            {selected_mapping &&
              <Row className="mt-2">
                  <Col sm={12} lg={4} className="d-flex align-items-center">
                  <div className="ml-0 mb-3">
                    <Checkbox 
                      id={"update_mapping_checkbox_id"}
                      name="update_mapping"
                      checked={update_mapping}
                      onChange={(e) => {
                        setUpdate_mapping(e.target.checked) 
                        setSave_new_mapping(false)
                        setMapping_name(null)
                      }}
                      value={update_mapping}
                      className="zIndexZero"
                      required
                      label={<>{t("option_update_mapping", 'Päivitä malli')}</>}
                      />
                  </div>
                  </Col >
                  {update_mapping &&
                    <Col  sm={12} lg={8} >
                      <Input
                          type="text"
                          name="update_mapping_name"
                          onChange={(e) => {setMapping_name(e.target.value)}}
                          value={mapping_name != null ? mapping_name : selected_mapping?.label}
                          
                          
                          
                          // placeholder={t("reference_code", "Viite")}
                          placeholder={t("importTransactionsV2_update_mapping_name", "Nimi")}
                          
                          />
                    </Col>
                  }
              </Row>
           }
        </Container>
      )
    }, [columns, mappings, availableOptions, initialOptions, NordnetImport]);


  const getColumnsTransactionType= useCallback(() => {
      return(
        <Container fluid>
            {/* {JSON.stringify(transactionTypemappings)}
            {JSON.stringify(transactionTypesExcel)}
            {JSON.stringify(transactionAvailableOptions)}
            {JSON.stringify(transactionInitialOptions)} */}
          {
            transactionTypesExcel && transactionTypesExcel.length > 0 && (
              <Row className="my-3 mt-5">
                  <Col sm={12} lg={4} className="d-flex align-items-center">
                    <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_investime_types", "Investimen tapahtumien tyypit")}</label>
                  </Col>
                    <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_excel_types", " Tuotavan excelin tapahtumien tyypit")}</label>
                  <Col sm={12} lg={8} >
                  </Col>
                </Row>
            )
          }

          {transactionTypesExcel && transactionTypesExcel.length > 0 && transactionTypes && transactionTypes.map((column, index) => (
            <div key={index}>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <Typography variant="h4" style={{transition: 'padding-left 0.5s' }} className={`mb-1 pr-1 header ${transactionTypemappings[index]?.value !== "" && transactionTypemappings[index]?.value !== " " ? ' pl-3 transitioning text-warning' : 'text-dark'}`}>{column?.value}</Typography>
                </Col>
                <Col sm={12} lg={8} >
                  <ImportColumnSelectGeneral
                      initialOptions={transactionInitialOptions}
                      selectedOptions={transactionTypemappings}
                      availableOptions={transactionAvailableOptions}
                      onChange={(e) => handleOptionChangeTransactionType( e, index, transactionTypemappings[index])}
                    //   value={transactionTypemappings[index]?.value ?transactionInitialOptions[transactionTypemappings[index]?.value]?.label: ""}
                      label =" "
                      placeholder="Valitse"
                      name={column+index}
                      onSelect={(selectedValue) => handleSelectTransactionType(index, selectedValue, )}
                      className={transactionTypemappings[index]?.value !== "" && transactionTypemappings[index]?.value !== " " ? 'text-warning' : 'text-dark'}
                />
                </Col>
              </Row> 
            </div>
          ))}
        </Container>
      )
    }
    , [transactionAvailableOptions, transactionInitialOptions, transactionTypemappings, transactionTypesExcel]);

  
    return (
      <div>
        <Row className="justify-content-center mb-2">
          <Col xl="4" lg="7" md="8" xs="10">
            <StepProgressBar steps={5} showNextButton={false} takeNextBtnState={setNextStepBtn} givenActiveStep={nextStepHandle} />
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xl="12">
            <div className="bg-default px-3 py-1" id="import-helptext" rounded>
              <div className="mt-3">
                <HelpText contentKey={getContext()} parentElementId="import-helptext" showButton={false} popoverOpen={false} className="mb-0 text-center mt-2"/>
              </div>
            </div>
          </Col>
        </Row>


        {nextStepHandle === 1 && (
          <>
            <div>
              <Typography variant="h4" className="my-1 mt-4 pr-1 text-dark">{t("importTransactionsV2_import_excel", "Tiedosto")}</Typography>
              <div className="mb-5">

                <label className="mb-0" htmlFor="fileInput"><Button onClick={handleButtonClick}>{t("Choose file")}</Button></label>
                <input
                  ref={fileInputRef}
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <div className="custom-file-upload " style={{ display: 'inline-block', marginLeft: '8px' }}>
                  {getFileName(file)}
                </div>
              </div>

            </div>
            <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={() =>  moveToSecondStep()}>
              {t("Jatka")}
            </Button>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{getErrorText()}</Typography>}
          </>
        )}
        
        {columns.length > 0 && nextStepHandle === 2 && (
          <div className="mt-4">
            {/* {JSON.stringify(columns)} */}

             <PageHeader className="text-dark mb-5 ml-3" title={t("importTransactionsV2_connect_columns","Yhdistä sarakkeet")}></PageHeader>

            {getColumns()}
            {/* {getColumnsTransactionType()} */}

            <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={() => handleFileUpload(false,false)}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Tuo tiedot")}
            </Button>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
          </div>
        )}
        {nextStepHandle >= 3 && basicDataRows.length > 0 ? (
          <>
            <ImportNoBasicDataTable basicDataRows={basicDataRows} setBasicDataRows={setBasicDataRows} setValidBasicDatas={setValidBasicDatas} model="Option"/>
            <ImportFoundedBasicDataV2 basicDataRows={foundedBasicDataRows} setBasicDataRows={setFoundedBasicDataRows}/>

            <Button  className=" mt-2 mr-auto ml-auto" color="secondary"  onClick={handleFileUploadWithBasicData}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("importTransactionsV2_create_basicdatas","Luo arvopaperit ja jatka")}
            </Button>
            {errorText && !validBasicDatas && <Typography variant="h5" className="my-1 pr-1 text-danger">{getErrorText()}</Typography>}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
        ): nextStepHandle ==3 && (
          <>
            <div>
              {/* <label className="mb-1 pr-1 header-bold"><i className="fas fa-check-circle text-success mr-2"></i>{t("importTransactionsV2_all_bd_found", "Kaikille arvopapereille löytyi vastine")}</label> */}
            </div>
            <ImportFoundedBasicDataV2 basicDataRows={foundedBasicDataRows} setBasicDataRows={setFoundedBasicDataRows}/>
            <Row className="justify-content-center">
              <Col>
                <Button  className="mt-2 m-auto" color="secondary" onClick={() => handleFileUpload(false,true)}>
                 {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Jatka")}
                </Button>
              </Col>
            </Row>
            {/* {errorText && !validBasicDatas && <Typography variant="h5" className="my-1 pr-1 text-danger">{getErrorText()}</Typography>} */}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
         
          </>
        )}
        {nextStepHandle === 4 && (
          <>
            <div>
              {/* <label className="mb-5 pr-1 header-bold">{t("importTransactionsV2_transactions_validation", "Kirjausten oikeellisuus")}</label> */}
            </div>
              {!errorText && <ImportCheckTransactionsV2 model="Option" checkTransactions={checkTransactions} setCheckTransactions={setCheckTransactions} exchange_transactions={exchange_transactions} setExchange_transactions={setExchange_transactions} />}
            <Row className="justify-content-center mt-3">
              <Col>
                <Button  className="mt-2 m-auto" color="secondary" onClick={() => handleFileUpload(true,true )}>
                {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Tallenna ja luo kirjaukset")}
                </Button>
              </Col>
            </Row>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
            {errorMessageFields ?
                  Object.entries(errorMessageFields).map(([key, values]) => (
                      <div key={key} className="d-flex ">
                          <Typography variant="h5" className="my-1 pr-1 text-danger">
                              {key}:
                          </Typography>
                          {values.map((message, index) => (
                              <Typography key={index} variant="h5"  className="my-1 pr-1 text-danger">
                                  {message}
                              </Typography>
                          ))}
                      </div>
                  ))
              : 
              <>
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
              
              </>
            }
          </>
           )}
        {nextStepHandle === 5 && (
          <>
            <div>
              {!errorMessage && !errorText ?(
              
                <div className="d-flex justify-content-start mb-4 mt-3">
                <div className="mr-2" >
                  <i className="fas fa-check-circle text-success"></i>
                </div>
                <div >
                  <Typography variant="h3" className="text-dark " >  <label className="mb-1 pr-1 header-bold text-dark">{t("importTransactionsV2_end_success", "Kirjausten tuonti onnistui")}</label></Typography>
                </div>
              </div>
              ):(
                <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_end_fail", "Kirjausten tuonnissa jotain ongelmia")}</label>
              )}
            </div>
            <Row className="justify-content-center mt-5">
              <Col>
                <Button  className="mt-5 m-auto" color="secondary" onClick={() => handleResolve()}>
                  {t("Sulje")}
                </Button>
              </Col>
            </Row>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
          )
        }
      </div>
    );
  };
  
  export default ImportExcelOptions;


import React, { useState, useEffect } from 'react';

import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic, Popover } from "stories/components";
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"
import { httpGetSecurityPerformance} from "services/portfolios"



const BestWorstPerformance = ({ data, portfolio, portfolio_list, rows, date, sectors, related_model_id, currency, related_model_type, bestPerfomanceTimePeriod="month"}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const [bestPerformers1, setBestPerformers] = useState([]);
  const [worstPerformers1, setWorstPerformers] = useState([]);


  useEffect(() => {
    let securities = [];
    if (rows !== null) {
      for (let i = 0; i < rows.length; i++) {
        securities.push({related_model_id: rows[i].related_model_id, related_model_type: rows[i].related_model_type});
      }
    }

    const params = {
      portfolio: portfolio? portfolio: portfolio_list,
      related_model_id: related_model_id,
      sectors: sectors,
      investmentType: related_model_type && related_model_type?.filter(item => item.id !== "all").map(item => item.id),
      date: date,
      currency: currency,

    };
    // if (securities.length > 0) {
    //   params["securities"] = securities;
    // }
    httpGetSecurityPerformance(params).then((response) => {
      setChartData(response?.data);

    }
    );
  }, [portfolio, rows]);

  useEffect(() => {
    if (chartData.length > 0) {
      if (bestPerfomanceTimePeriod === "ytd") {
        const new_sorted_list = [...chartData].sort((a, b) => b?.first_day_of_year_change_percent- a?.first_day_of_year_change_percent);
        //add used_change_percent to data
        const new_sorted_list1 = new_sorted_list.map((item) => {
          item.market_value_change_percent_show = roundScaleDecimals(item?.first_day_of_year_change_percent,2);
          return item;
        }
        );
        setBestPerformers(new_sorted_list1.slice(0, 5));
        setWorstPerformers(new_sorted_list1.slice(-5).reverse());
      } else if (bestPerfomanceTimePeriod === "1y") {
        const new_sorted_list = [...chartData].sort((a, b) => b?.one_year_ago_change_percent- a?.one_year_ago_change_percent);
        const new_sorted_list1 = new_sorted_list.map((item) => {
          item.market_value_change_percent_show = roundScaleDecimals(item?.one_year_ago_change_percent,2);
          return item;
        }
        );
        setBestPerformers(new_sorted_list1.slice(0, 5));
        setWorstPerformers(new_sorted_list1.slice(-5).reverse());
      } else if (bestPerfomanceTimePeriod === "all") {
        const new_sorted_list = [...chartData].sort((a, b) => b?.market_value_change_percent- a?.market_value_change_percent);
        const new_sorted_list1 = new_sorted_list.map((item) => {
          item.market_value_change_percent_show = roundScaleDecimals(item?.market_value_change_percent,2);
          return item;
        }
        );
        setBestPerformers(new_sorted_list1.slice(0, 5));
        setWorstPerformers(new_sorted_list1.slice(-5).reverse());
      } else if (bestPerfomanceTimePeriod ==="month") {
        const new_sorted_list = [...chartData].sort((a, b) => b?.one_month_ago_change_percent- a?.one_month_ago_change_percent);
        const new_sorted_list1 = new_sorted_list.map((item) => {
          item.market_value_change_percent_show = roundScaleDecimals(item?.one_month_ago_change_percent,2);
          return item;
        }
        );

        setBestPerformers(new_sorted_list1.slice(0, 5));
        setWorstPerformers(new_sorted_list1.slice(-5).reverse());
      }
    }
  }, [chartData, bestPerfomanceTimePeriod]);

        


  // Sort securities by market_value (assumes data includes a 'market_value' field as percentage)




  return (
    <div style={{ width: "100%" }}>
      {/* {JSON.stringify(bestPerfomanceTimePeriod)} */}
      {/* {JSON.stringify(bestPerformers1)} */}
      {/* {JSON.stringify(chartData)} */}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
        {/* <tr className="bg-light">
            <td colSpan="2"  style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "start" }}>{t("performance_best", "Nousijat")}</td>
          </tr> */}
          <tr className="bg-light">
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "left" }}><Typography variant="small" >{t("performance_winners","Nousijat")} </Typography></th>
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}><Typography variant="small" >{t("performance_performance", "Muutos")} </Typography></th>
          </tr>
        </thead>
        <tbody>


          {/* {bestPerformers.map((item, index) => ( */}
          {bestPerformers1.map((item, index) => (
            <tr key={`worst-${index}`}>
            <td className="pl-2" style={{ borderBottom: "1px solid #ddd"}}>
              <Typography variant="small" >
                {/* {item?.related_model?.name} */}
                {item?.name}
              </Typography>
            </td>
            <td  className={`${item?.market_value_change_percent_show> 0 ? "text-success": "text-danger"} pl-2`} style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}>
            <Typography variant="small" className={`${item?.market_value_change_percent_show> 0 ? "text-success": "text-danger"}`}>

              {item?.market_value_change_percent_show}%
              </Typography>
            </td>
          </tr>
          ))}

        </tbody>
      </table>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
        {/* <tr className="bg-light mt-5">
            <td colSpan="2"  className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "start" }}>{t("performance_worst", "Laskijat")}</td>
          </tr> */}
          <tr className="bg-light">
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "left" }}><Typography variant="small" >{t("performance_losers","Laskijat")} </Typography></th>
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}><Typography variant="small" >{t("performance_performance", "Muutos")} </Typography></th>
          </tr>
        </thead>
        <tbody>
        

          {worstPerformers1.map((item, index) => (
            <tr key={`worst-${index}`}>
              <td className="pl-2" style={{ borderBottom: "1px solid #ddd"}}>
                <Typography variant="small" >
                  {item?.name}
                </Typography>
              </td>
              <td  className={`${item?.market_value_change_percent_show> 0 ? "text-success": "text-danger"} pl-2`} style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}>
              <Typography variant="small" className={`${item?.market_value_change_percent_show> 0 ? "text-success": "text-danger"}`}>

                {item?.market_value_change_percent_show}%
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

BestWorstPerformance.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      market_value: PropTypes.number.isRequired, // Performance as a percentage
    })
  ).isRequired,
};

export default BestWorstPerformance;


// import React from "react";
// import PropTypes from "prop-types";

// const BestWorstPerformance = ({ data }) => {
//   // Sort securities by market_value (assumes data includes a 'market_value' field as percentage)
//   const sortedData = [...data].sort((a, b) => b.market_value - a.market_value);

//   // Get top 5 and bottom 5 performers
//   const bestPerformers = sortedData.slice(0, 5);
//   const worstPerformers = sortedData.slice(-5).reverse();

//   return (
//     <div style={{ display: "flex", gap: "2rem" }}>
//       {/* Best Performers */}
//       <div style={{ flex: 1 }}>
//         <h3>Top 5 Best Performers</h3>
//         <table style={{ width: "100%", borderCollapse: "collapse" }}>
//           <thead>
//             <tr>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Security</th>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>Performance (%)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {bestPerformers.map((item, index) => (
//               <tr key={index}>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>{item?.related_model?.name}</td>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>
//                   {item?.market_value.toFixed(2)}%
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Worst Performers */}
//       <div style={{ flex: 1 }}>
//         <h3>Top 5 Worst Performers</h3>
//         <table style={{ width: "100%", borderCollapse: "collapse" }}>
//           <thead>
//             <tr>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Security</th>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>Performance (%)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {worstPerformers.map((item, index) => (
//               <tr key={index}>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>{item?.related_model?.name}</td>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>
//                   {item?.market_value.toFixed(2)}%
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// BestWorstPerformance.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       market_value: PropTypes.number.isRequired, // Performance as a percentage
//     })
//   ).isRequired,
// };

// export default BestWorstPerformance;

import { React, useEffect, useState, useCallback } from 'react';

//import easypeasy
import { useStoreState } from 'easy-peasy';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import moment from "moment";



import { Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic, Popover } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { httpUpdateUser } from "services/users";
import { useNotification } from "stories/components/Notification"
import { useStoreActions} from 'easy-peasy';
import { Table, THead, Pagination } from "stories/tables";

import { thousandFormat } from "services/helpers";
import { roundScaleDecimals } from "services/helpers";
import { TableAuto } from "stories/tables"
import FileDownloadButton from "components/Buttons/FileDownloadButton/FileDownloadButton"

import UploadFile from "components/Forms/UploadFile/UploadFile"
import FilesSelect from "components/Forms/FilesSelect/FilesSelect"


// use autContext
import { useAuthContext } from "contexts/AuthContext";
import { useAppContext } from "contexts/AppContext";
import { differenceInSecondsWithOptions } from 'date-fns/fp';
import { httpGetMyUser } from 'services/users';
import { httpGetNewFeatures } from 'services/new_features';

import {
    httpUploadFiles,
    httpUpdateFile,
    httpDeleteFile,
    httpGetFiles,
  } from "services/files"
import { Input } from 'reactstrap';


// import "./NewFeatures.css"


const NewFeatures = ({ location }) => {

    const { t } = useTranslation();
    const { myUser, getMyUser, loading, accountUserType } = useAuthContext();
    const { getPortfolioName} = useAppContext()

    const myUserLoading = loading
    const newFeatures = useStoreState((state) => state.newFeatures?.newFeatures);
    const newFeaturesLoading = useStoreState((state) => state.newFeatures?.loading);
    const myAccount = useStoreState((state) => state.account?.account);
    const [fileSelectValue, setFileSelectValue] = useState(null);
    const [count, setCount] = useState()
    const [resData, setResData] = useState(null)



    const [objects, setObjects] = useState()
    const [files, setFiles] = useState([]);



    const { notify } = useNotification(); 
    
    const [filters, setFilters] = useState({
      limit: 30,
      offset:0,
      ordering: "-created",
    })

    const getFiles = (id) => {
        const params ={
           ...filters,
            limit: filters.limit,
            offset: filters.offset,
            id: id ||filters.id,
            // ordering: filters.ordering,

        }
        httpGetFiles(params).then((response) => {
            console.log(response);
            setObjects(response.data?.results);
            setCount(response?.data?.count)

        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        setFilters(f => ({
            ...f,
            id: fileSelectValue?.value
        }))
    }, [fileSelectValue])

    useEffect(() => {
        getFiles(resData?.id)
    }, [resData])

    
        

    useEffect(() => {
        getFiles()
      }, [filters])

    const getCrumbLinks = () => {
        const crumbLinks = [
          {name: t("Etusivu"), url: "/dashboard/"},
          {name: t("routes_dashboard_documents", "Tiedostot"), url: "/dashboard/new_features"},
        ]
    
        //if location state include crumbLinks return them
        if (location?.state && location?.state?.crumbLinks) {
          //made new const and deapcopy location.state.crumbLinks into it
          const crumbLinks = [...location.state.crumbLinks]
    
    
    
          crumbLinks.push({name:  t("routes_dashboard_documents", "Tiedostot"), url: "/dashboard/new_features"})
          return crumbLinks
        }
        //else return default links
        return crumbLinks
      }
    
   
      const headers = [
        { label: t("documents_id", "Id"), key: "id", sortable: true, sortingKey: "id"},
        { label: t("documents_name", "Nimi"), key: "filename", sortable: true, sortingKey: "file"},
        { label: t("documents_portfolio", "Salkku"), key: "portfolio", sortable: true, sortingKey: "portfolio"},
        { label: t("documents_downloaded", "Ladattu"), key: "created", sortable: true, sortingKey: "created"},
        { label: t("documents_actions", ""), key: "actions"},

        
      ];
      
      const paginationPrevious = () => {
        let offset = filters?.offset - filters?.limit;
        if (offset < 0) {
          offset = 0;
        }
        setFilters(f => ({
          ...f,
          offset: offset,
        }))
      }
      
      const paginationNext = () => {
        let offset = filters?.offset + filters?.limit;
        if (offset > filters?.count) {
          return
        }
        setFilters(f => ({
          ...f,
          offset: offset,
        }))
      }

      const removeFile = (id) => {
        const confirmed = window.confirm(t("document_delete_confirm_text","Oletko varma että haluat poistaa tiedoston pysyvästi?"));

        if (!confirmed) {
            return; // Do nothing if user cancels
        }
        httpDeleteFile(id).then((response) => {
            console.log(response);
            // setFiles(files.filter(file => file.id !== id));
            getFiles();
        }).catch((error) => {
            console.log(error);
        });
    }
      
      const getTableRows = useCallback(() => {
        return objects && objects.map((row, index) => {
          return {
            ...row,
            // transactions: row?.transactions && row?.transactions.length > 0 && <i className="fas fa-paperclip text-primary"></i>,
            created: moment(row?.created).format("DD.MM.YYYY"),
            portfolio: row?.portfolio ? getPortfolioName(row?.portfolio): row?.portfolio,
            actions:
                <div className="d-flex justify-content-end">
                {row?.transactions_data && row?.transactions_data.length > 0 &&
                <>
                    <i id={`connectedt-${row?.id}`} className="fas fa-link text-primary mr-auto my-auto"></i>
                    <Popover
                    placement="left"
                    target={`connectedt-${row?.id}`}
                    > 
                    <>
                    <Typography className="text-dark mb-1" variant="medium">
                    {t("documents_connected",'Linkitetty')}
                    </Typography>  
                    {row?.transactions_data.map((trx, index) => {
                        return (
                            <Typography className="text-dark mb-1" variant="small">
                             {t("documents_transaction_id","Tapahtuma id")}: {trx.id},  {t("documents_portfolio","Salkku")}: {trx.portfolio_name},  {t("documents_symbol","Tunnus")}: {trx.ticker_symbol},  {t("documents_date_transaction","Tap. päivä")}: {moment(trx.date_transaction).format("DD.MM.YYYY")}
                            </Typography>  
                            )
                        }
                    )}
                    
                    </>

                    </Popover>
                </>
                }
                    
                <Button id={`remove-${row?.id}`} className="text-white font-weight-light rounded-circle custom-xs-button-input  b-0"  disabled={accountUserType==="read_only"}  variant="lighter" onClick={() => removeFile(row.id)} >
                <i className="fas fa-trash-alt text-primary bg-white fa-xl"  />
                </Button>
                <Popover
                placement="right"
                target={`remove-${row?.id}`}
                >       
                <Typography className="text-dark mb-1" variant="medium">
                {t("documents_delete_file",'Poista tiedosto')}
                </Typography>  
                </Popover>
                <FileDownloadButton  showPreview={true} fileId={row?.id} fileName={row?.filename} />
                </div>
            
            
          }
        })
      }, [objects])



    // if (myAccount.license.name =="Lite"){
    //     return (
    //         <PageContainer>
    //             <Typography variant="h1" className="text-center">{t("Sinulla ei ole oikeuksia tähän sisältöön")}</Typography>
    //         </PageContainer>
    //     )
    // }
    const get_view = useCallback(() => {
        if (myAccount?.license?.name =="Lite"){
            return (
                <PageContainer>
                    <Typography variant="h1" className="text-center">{t("Not awailable")}</Typography>
                </PageContainer>
            )
        }
      return (      <>
          <PageHeader title={t("routes_dashboard_documents", "Tiedostot")} />
          <BreadcrumbsDynamic links={getCrumbLinks()} />
          
          <PageContainer className="pt-3">
            {
              accountUserType !== "read_only" &&
              <div className ="mb-3">
                <UploadFile  files={files} setFiles={setFiles} uploadDirectly={true} updatePage={getFiles} padding={30} setResData={setResData}/>
              </div>
            }
          <Row className="mb-2">
              <Col xs="12" md="8" lg="6" xl="4" xxl="1">
              
              <FilesSelect  
                  placeholder={t("files_select_label", "Valitse...")}  
                  name="files" 
                  isMulti={false} 
                  setFileSelectValue={setFileSelectValue} 
                  fileSelectValue={fileSelectValue} 
                  showAll={true}
                  reload={objects}
              />
              </Col>
          </Row>
          <TableAuto
            showRowNumber={false}
            showId={false}
            checkboxes={false}
            headers={headers}
            headerColor={"light"}
            rows={getTableRows()}
            loading={loading}
            pagination={true}
            paginationPrevious={paginationPrevious}
            paginationNext={paginationNext}
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
            paginationCount={count}
            setFilters={setFilters}
            filters={filters}
  
            />
            
            </PageContainer>
        </>
      )   
    }, [myAccount, objects, filters, fileSelectValue, files, resData, count, newFeatures, newFeaturesLoading, myUser, myUserLoading])



    return (
        <>
        {get_view()}
        </>

    );
};

export default NewFeatures;



import {useEffect, React, useState, useCallback} from "react";
import { useTranslation } from 'react-i18next';

// Stories
import { PageHeader, PageContainer } from "components/Page"

import { Col, Container, Row, Card, CardBody, CardHeader } from "stories/layout"

import {Link, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic, Spinner} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetAllPortfoliosSummary} from "services/transactions"
import { round, thousandFormat } from "services/helpers"


import PortfoliosSummaryChart from "views/app/PortfoliosSummaryChart/PortfoliosSummaryChart"
import PortfoliosSummaryTableLineChart from "views/app/PortfoliosSummaryChart/PortfoliosSummaryTableLineChart" 
import PortfolioCarousel from "views/app/PortfolioCarousel/PortfolioCarousel"




// App
import graph01 from "assets/images/graph_01.jpg"
import graph02 from "assets/images/graph_02.jpg"
import { Typography } from "stories/components";

import UnmadeSplitTransactionsList from 'views/app/UnmadeSplitTransactionsList/UnmadeSplitTransactionsList'
import { set } from "date-fns";



const FrontPage = (props) => {



  const { t } = useTranslation();
  const { myUser, accountUserType } = useAuthContext();
  const account = useStoreState((state) => state.account?.account);

  const language = useStoreState((state) => state.language?.language);
  const unmadeSplitTransactions = useStoreState((state) => state.unmadeSplitTransactions?.unmadeSplitTransactions);
  const unmadeSplitTransactionsLoading = useStoreState((state) => state.unmadeSplitTransactions?.loading);
  const getUnmadeSplitTransactions = useStoreActions((actions) => actions.unmadeSplitTransactions.getUnmadeSplitTransactions);
  const unmadeDividendTransactions = useStoreState((state) => state.unmadeDividendTransactions?.unmadeDividendTransactions);
  const unmadeDividendTransactionsLoading = useStoreState((state) => state.unmadeDividendTransactions?.loading);
  const getUnmadeDividendTransactions = useStoreActions((actions) => actions.unmadeDividendTransactions.getUnmadeDividendTransactions);
  const frontpageInfo = useStoreState((state) => state.frontpageInfo?.frontpageInfo);
  const frontpageInfoLoading = useStoreState((state) => state.frontpageInfo?.loading);

  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfoliosSummary = useStoreState((state) => state.portfoliosSummary?.portfoliosSummary);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const getPortfoliosSummary = useStoreActions((actions) => actions.portfoliosSummary.getPortfoliosSummary);
  
  const [portfolioSummary, setProfitSummary] = useState()
  const [frontpageInfoLanguage, setFrontpageInfoLanguage] = useState()
  const [chartDataLoading, setChartDataLoading] = useState()

  const [portfolioName, setPortfolioName] = useState("")
  const [sumProfit, setSumProfit] = useState("-")
  const [allPortfoliosWAC, setAllPortfoliosWAC] = useState(false)
  const [chartData, setChartData] = useState({})

  
  function getFirstDayXMonthsAgo(months) {
    const date = new Date();
    
    // Vähennä 6 kuukautta nykyisestä kuukaudesta
    date.setMonth(date.getMonth() - months);
  
    // Aseta päivä kuukauden ensimmäiseksi päiväksi
    date.setDate(1);
  
    return date.toISOString().split('T')[0];
  }

  useEffect(() => {
    // getUnmadeSplitTransactions()
    // getUnmadeDividendTransactions()
    getPortfoliosSummary()
    }, [])

  useEffect(() => {
    if (portfolios && portfolios.length > 0){
      //check is all portfolios sell_profit_calculation_method is FIFO
      const isFIFO = portfolios.every(portfolio => portfolio.sell_profit_calculation_method === "WAC")
      setAllPortfoliosWAC(isFIFO)
    }
  }, [portfolios])

  useEffect(() => {
    if (frontpageInfo?.frontpage && myUser && myUser?.language && frontpageInfo?.frontpage?.length > 0){
      
      setFrontpageInfoLanguage(frontpageInfo?.frontpage.find(item => item.title == myUser?.language))
    }
  }, [frontpageInfo, frontpageInfoLoading , myUser])



  //get crumb links from location state or return default links
  const getCrumbLinks = () => {
    const crumbLinks = [
      {
        name: t("Etusivu"),
          url: "/dashboard",  
        },
    ]
    //if location state include crumbLinks return them
    if (props.location?.state && props.location?.state?.crumbLinks) {
      props.location.state.crumbLinks.push({name: t("Etusivu"), url: "/dashboard"})
      return props.location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }

  const title = t("web_fronpage_welcome","Tervetuloa palveluun") + ", " + (myUser?.first_name? myUser?.first_name : myUser?.username);

  const getPortfoliosData = () => {
    const params = {
      // portfolio: filters?.portfolio,
      // date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      // sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      // stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      // selected_portfolios: null,
      ordering:  "-date_transaction",
    }
    setChartDataLoading(true)
    httpGetAllPortfoliosSummary(params).then(response => {
        // setProfitSummary(response?.data)

        if (response?.data){
          setProfitSummary(response?.data?.portfolios_market_value)
          let options=  []
          const sellOptions = response?.data?.sell || []
          const buyOptions = response?.data?.buy || []
          const optionsSet = new Set()
          // setSecurityOptions(options)
        }
        // console.log(response?.data)
        // setLoading(false)
      }, error => {
        setChartDataLoading(false)
        console.log(error)
      })
      .finally(() => {
        setChartDataLoading(false)
      }
      )

  }

  useEffect(() => {
    // getPortfoliosData()
  }, [portfoliosLoading])

  const getChart = useCallback(() => {
    if(portfoliosSummary.length == 0) return (
      <Container fluid className="d-flex justify-content-center align-items-center">
      <Spinner className="mt-3" />
    </Container>
    )

    const portfolios = Object.keys(portfoliosSummary).map((portfolioId) => ({
      id: portfolioId,
      src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',

      ...portfoliosSummary[portfolioId]
    }));

    return(<div ><PortfolioCarousel portfolios={portfolios} setPortfolioName={setPortfolioName} /></div>)
    return <PortfoliosSummaryChart data={portfoliosSummary}/>
   }, [portfoliosSummary])


   const getTotal = useCallback(() => {
    if(portfoliosSummary.length == 0) return null
    const sumOfPortfolios = Object.values(portfoliosSummary).reduce((acc, portfolio) => {
      for (const [key, value] of Object.entries(portfolio)) {
        if (!acc.hasOwnProperty(key)) {
          acc[key] = 0;
        }
        acc[key] += value;
      }
      return acc;
    }, {});

    return (sumOfPortfolios)
    }, [portfoliosSummary])


    const get_portfolio_names = () => {
      let portfolios_filtered = portfolios.filter(o =>
        unmadeSplitTransactions.map(t => t.batch.portfolio).includes(o.id)
      );
    
      return portfolios_filtered.map(o => o?.name); // Just return the portfolio names
    };

    const portfolioNames = () => {
      const portfolioNames = get_portfolio_names(); // Get portfolio names
    
      return (
        <div  className="d-flex justify-content start align-items-center" >
          {portfolioNames.map((name, index) => (
            <>
            <i className="fas fa-circle fa-xs text-light" key={index} />
            <Typography variant="h4" className="ml-2 mr-4 text-light" key={index}>{name}</Typography>
            </>
          ))}
        </div>
      );
    };
 
   const getSum = useCallback(() => {
    if(portfoliosSummary.length == 0) return (
      <Container fluid className="d-flex justify-content-center align-items-center">
        <Spinner className="mt-3" />
      </Container>
    )



    const sumOfPortfolios = Object.values(portfoliosSummary).reduce((acc, portfolio) => {
      for (const [key, value] of Object.entries(portfolio)) {
        if (!acc.hasOwnProperty(key)) {
          acc[key] = 0;
        }
        acc[key] += value;
      }
      return acc;
    }, {});
    let summary =  JSON.parse(JSON.stringify(sumOfPortfolios));
    summary = {"summary": summary}
    // summary.summary.Hankintahinta *= -1
    // setSumProfit(summary.summary.Markkina_arvo)
    delete summary.summary.Realisoitumattomat_tuotot
    delete summary.summary.Realisoituneet_tuotot
    const profit_percentage = (summary.summary.Markkina_arvo - summary.summary.Hankintahinta) / summary.summary.Hankintahinta * 100 || 0.0
    let profit_classes = "text-bold text-success"
    let icon_profit_classes = "fas fa-arrow-up"
    if(summary.summary.Markkina_arvo < summary.summary.Hankintahinta){
      profit_classes = "text-bold text-danger"
      icon_profit_classes = "fas fa-arrow-down"
    }



    return (
      <Row>
        <Col xs="7">
          <div className="mx-2">
            <PortfoliosSummaryChart data={summary} width="100" height="80"/>
          </div>
        </Col>

          
        <Col xs="5">
        <Typography variant="h2" className="text-bolder mt-3">{t("frontpage_profit","Tuotto")}</Typography>
        <Typography variant="h2" bolder className="text-bold my-1">{sumOfPortfolios && sumOfPortfolios.Realisoitumattomat_tuotot ? thousandFormat(sumOfPortfolios.Realisoitumattomat_tuotot.toFixed(2)) : ""}€</Typography>
        <Typography variant="h1" className={profit_classes}>{profit_percentage ? profit_percentage.toFixed(2) : ""}%  <i className={icon_profit_classes}/></Typography>
        <Typography variant="h4" className="text-bold mt-3">{t("frontpage_realized","Realisoituneet")}: {sumOfPortfolios && sumOfPortfolios.Realisoituneet_tuotot ? thousandFormat(sumOfPortfolios.Realisoituneet_tuotot.toFixed(2)) : ""}€ </Typography>
        </Col>
      </Row>
    )
   }, [portfoliosSummary])
  
  return (
    <>
   
   


    <Row>
    {(account?.show_frontpage_portfolio_summary ) &&
      <Col xs="12" md="6">
      <Card className={`shadow-sm-strong  h-100  `} >
          <CardHeader className={`d-flex justify-content-between align-items-center text-bol `} >
          {/* <h3 calssName="mt-5"> {t("all_portfolios_summary", "Salkkujen tuotto")}</h3> */}
          {/* <h3 calssName="mt-5"> */}
            
              <i className="fas fa-briefcase text-primary  fa-2x mr-3" />
              <Typography>
              <span variant="h4" className="text-bold mr-1 ">
              {t("front_page_selected_portfolio","Valittu salkku")}: 
              </span>

              {portfolio?.name}
                </Typography> 
            {/* </h3> */}
            <span variant="h4" className="text-bold ml-auto">
              {t("all_portfolios_summary_profit", "")} 
              <Typography>
              <span variant="h4" className={`text-bold mr-1`}> 
                {t("front_page_portfolio_value","Arvo")} 
              </span>
              {chartData?.latest_market_value ? thousandFormat(chartData?.latest_market_value.toFixed(2)) : ""} {portfolio?.currency_label}
              </Typography>
            </span>
          </CardHeader>
          <CardBody>
  
          <Row className="mb-0">
            <Col>
            {/* <PortfoliosSummaryTableLineChart portfolio={portfolio.id} date__gte={getFirstDayXMonthsAgo(5)} week_day={1}  setChartData={setChartData}/> */}
            {portfolio && <PortfoliosSummaryTableLineChart portfolio={portfolio?.id} date__gte={getFirstDayXMonthsAgo(5)} setChartData={setChartData} currency={portfolio?.currency_label}/>}
            {/* <PortfoliosSummaryTableLineChart portfolio={portfolio?.id} date__gte={getFirstDayXMonthsAgo(5)} setChartData={setChartData} currency={portfolio?.currency_label}/> */}
            </Col>
          </Row>
          <Row className="mb-1  border-top">
            {/* <Col className="border-rigth">
                <div className="mt-2 ml-1">
                  <Typography variant="h4" className="text-bold">{t("frontpage_one_day","1vrk")}   <span style={{ fontSize: '0.8em', marginLeft: '0.2em' }}>({portfolio?.currency_label})</span></Typography>
              {
                (chartData?.latest_market_value || chartData?.latest_market_value==0)  && 
                  <Typography className={(chartData?.yesterday_change ||chartData?.yesterday_change ==0 )  && chartData?.yesterday_change >= 0 ? 'text-success' : 'text-danger' }> 
                    {(chartData?.yesterday_change ||chartData?.yesterday_change ==0 )  && chartData?.yesterday_change >= 0 ? "+" : ""}
                    {(chartData?.yesterday_change ||chartData?.yesterday_change ==0 )  ?thousandFormat(chartData?.yesterday_change.toFixed(2)):null}
                    </Typography>
              }
                </div>
            </Col> */}
            <Col className="">
                <div className="mt-2 ml-1">
                  <Typography variant="h4" className="text-bold">{t("frontpage_one_month","1kk")} <span style={{ fontSize: '0.8em', marginLeft: '0.2em' }}>({portfolio?.currency_label})</span></Typography>
            {
               (chartData?.latest_market_value || chartData?.latest_market_value==0)   && 
                  <Typography className={(chartData?.monthly_change ||chartData?.monthly_change ==0)  && chartData?.monthly_change >= 0 ? 'text-success' : 'text-danger' }> 
                    {(chartData?.monthly_change ||chartData?.monthly_change ==0) && chartData?.monthly_change >= 0 ? "+" : ""}
                    {(chartData?.monthly_change ||chartData?.monthly_change ==0) ?thousandFormat(chartData?.monthly_change.toFixed(2)):null}
                    </Typography>
              }
                </div>
            </Col>
            <Col  className="border-left">
                <div className="mt-2 ml-1">
                  <Typography variant="h4" className="text-bold">{t("frontpage_one_year","1v.")} <span style={{ fontSize: '0.8em', marginLeft: '0.2em' }}>({portfolio?.currency_label})</span></Typography>
            {
                (chartData?.latest_market_value || chartData?.latest_market_value==0)   && 
                  <Typography className={(chartData?.one_year_change ||chartData?.one_year_change ==0) && chartData?.one_year_change >= 0 ? 'text-success' : 'text-danger' }> 
                    {(chartData?.one_year_change ||chartData?.one_year_change ==0) && chartData?.one_year_change >= 0 ? "+" : ""}
                    {(chartData?.one_year_change ||chartData?.one_year_change ==0) ?thousandFormat(chartData?.one_year_change.toFixed(2)):null}
                    </Typography>
              }
                </div>
            </Col>
            <Col  className="border-left">
                <div className="mt-2 ml-1">
                  <Typography variant="h4" className="text-bold">{t("frontpage_ytd","YTD")} <span style={{ fontSize: '0.8em', marginLeft: '0.2em' }}>({portfolio?.currency_label})</span></Typography>
            {
                (chartData?.latest_market_value || chartData?.latest_market_value==0)   && 
                  <Typography className={(chartData?.yearly_change ||chartData?.yearly_change ==0) && chartData?.yearly_change >= 0 ? 'text-success' : 'text-danger' }> 
                    {(chartData?.yearly_change ||chartData?.yearly_change ==0) && chartData?.yearly_change >= 0 ? "+" : ""}
                    {(chartData?.yearly_change ||chartData?.yearly_change ==0) ?thousandFormat(chartData?.yearly_change.toFixed(2)):null}
                    </Typography>
              }
                </div>
            </Col>
          </Row>
  
  
          </CardBody>
  
          </Card>
        </Col>
      }
      <Col xs="12" md="6" className="mt-sm-3 mt-md-0">

        <Card className="shadow-sm-strong  h-100">
        <CardHeader className="text-bold">
        {title}
        </CardHeader>
        <CardBody>

        <Row className="mb-5">
        <Col>
          {frontpageInfoLanguage ? (
            <Markup content={frontpageInfoLanguage?.description} />
          ):(
            <Container fluid className="d-flex justify-content-center align-items-center">
              <Spinner className="mt-3" />
            </Container>
          )
        }
        </Col>
        </Row>

        {/*Lähetä meille palautetta*/}
        <h3 calssName="mt-5">{t("frontpage_feedback","Kerro palautteesi")}</h3>
        <Link href="https://www.investime.fi/tukiportaali/" target="_blank"><Button variant="secondary">{t('web_frontpage_send_us_feedback','Lähetä meille palautetta')}</Button></Link>
        </CardBody>

      </Card>
      </Col>
        {(!account?.show_frontpage_portfolio_summary && !allPortfoliosWAC && account?.show_front_page_portfolio_summary_bar_chart) &&
      <Col xs="12" md="6" className="mt-sm-3 mt-md-0">
        <Card className={`shadow-sm-strong  h-100  `} style={{ backgroundColor: getTotal()?.Realisoitumattomat_tuotot < 0? ' rgb(223, 33, 33, 0.08)': 'rgba(69, 201, 21, 0.08)' }}>
          <CardHeader className={`d-flex justify-content-between align-items-center text-bol `} style={{ backgroundColor: getTotal()?.Realisoitumattomat_tuotot < 0? ' rgb(223, 33, 33, 0.08)': 'rgba(69, 201, 21, 0.08)' }}>
          <h3 calssName="mt-5"> {t("all_portfolios_summary", "Salkkujen tuotto")}</h3>
          
            <span variant="h4" className="text-bold ml-auto">
              {t("all_portfolios_summary_profit", "")} 
              <span variant="h4" className={`text-bold ${getTotal()?.Realisoitumattomat_tuotot < 0? 'text-danger': 'text-success'} `}> 
                {getTotal()?.Realisoitumattomat_tuotot ? thousandFormat(getTotal()?.Realisoitumattomat_tuotot.toFixed(2)) : ""} €
              </span>
              
            </span>
          </CardHeader>
          <CardBody>
  
          <Row className="mb-5">
            <Col>
             {getSum()}
            </Col>
          </Row>
  
  
          </CardBody>
  
          </Card>
          </Col>
      }





        {(!allPortfoliosWAC && account?.show_front_page_portfolio_summary_bar_chart) &&
      <Col xs="12" md="6" className="mt-4">

      <Card className="shadow-sm-strong  h-100">
        <CardHeader className="text-bold d-flex justify-content-between align-items-center">
          {portfolioName}
          <span variant="h4" className="text-bold ml-auto">EUR</span>
        </CardHeader>
        <CardBody >

        <Row className="mb-5 ">
          <Col>
           {getChart()}
          </Col>
        </Row>


        </CardBody>

        </Card>
        </Col>
      }
      


      {accountUserType!=="read_only" && (
      <Col xs="12" md="6" className="mt-4">


              <Card className="shadow-sm-strong h-100">
              <CardHeader className="text-bold">
              {t("web_frontpage_process_splits","Käsittele saapuneet splitit tai osingot")}
              </CardHeader>
              <CardBody className="p-3">
              {(false) ?             
                <Container fluid className="d-flex justify-content-center align-items-center">
                  <Spinner className="mt-3" />
                </Container> :
              <>
              {  unmadeSplitTransactions?.[0]?.batch  ? (
                <>
                <Link to="/dashboard/transactions" >
                  <div className="d-flex justify-content start align-items-center  bg-danger p-3 rounded ">
                    <div className="d-flex justify-content start align-items-center  bg-danger p-3 rounded ">
                      <i className="fas fa-exclamation-triangle text-white fa-2x mr-3" />
                      <div className="">
                          <div>
                            
                          <Typography  variant="h3" className="text-white">
                            {t("unmade_split_transactions_list_description",`Split-kirjauksia (${unmadeSplitTransactions?.length}) tekemättä. Tiedot eivät välttämättä näy oikein!`) }
                          </Typography>
                          </div>

                          {account.split_handling_only_active_portfolio == true && (
                        <>
                        {portfolioNames() }
                        </>

                      )
                      }
                        
                      </div>   
                    </div>
                   
                  </div>
                </Link>
                </>
              ):(
                <div className="d-flex justify-content-start align-items-center bg-success p-3 rounded">
                  {unmadeSplitTransactionsLoading ? (
                    <div className="mx-auto mb-1 ">
                      <Spinner className="mt-3 text-white" />
                    </div>
                  ):(
                    <>
                      <i className="fas fa-thumbs-up text-white fa-2x mr-3" />
                      <div className="d-flex  align-items-center bg-success ">
                        <Typography  variant="h3" className="text-white">
                          {t("web_frontpage_splits_ok","Split-kirjaukset tehty!") }
                        </Typography>
                      </div>  
                    </>
                  )}
                </div>
              )
             }
             {
              account.hide_dividends_handling ? null :
              <>
                { unmadeDividendTransactions?.[0]?.batch ? (
                  <>
                  <Link to="/dashboard/transactions" >
                    <div className="d-flex justify-content start align-items-center mt-4 bg-warning p-3 rounded ">
                    <i className="fas fa-coins text-white fa-2x mr-3" />
                    <div className="d-flex  align-items-center bg-warning ">
                        <Typography  variant="h3" className="text-white">
                          {t("unmade_dividend_transactions_list_description",`Osinko-kirjauksia (${unmadeDividendTransactions?.length}) tekemättä.`) }
                        </Typography>
                        </div>   
                    </div>
                  </Link>
                  </>
                ):(
                  <div className="d-flex justify-content-start align-items-center bg-success mt-4 p-3 rounded">
                    {unmadeDividendTransactionsLoading? (
                      <div className="mx-auto mb-1 ">
                        <Spinner className="mt-3 text-white" />
                      </div>
                    ):(
                      <>
                        <i className="fas fa-thumbs-up text-white fa-2x mr-3" />
                        <div className="d-flex  align-items-center bg-success ">
                            <Typography  variant="h3" className="text-white">
                              {t("web_frontpage_dividends_ok","Osinko-kirjaukset tehty!") }
                            </Typography>
                        </div>   
                      </>
                    )}
                  </div>
                )
              }
              
              </>
             }
     
   
              </>
             }
  
             </CardBody>
  
             </Card>
  
  
        </Col>
      )}
        {/* <Col xs="12" md="6" className="mt-4">
          <Card className="shadow-sm-strong  h-100">
            <CardHeader className="text-bold d-flex justify-content-between align-items-center">
              {portfolioName}
              <span variant="h4" className="text-bold ml-auto">EUR</span>
            </CardHeader>
            <CardBody >

            <Row className="mb-5 ">
              <Col>
              {getChart()}
              </Col>
            </Row>


            </CardBody>

            </Card>
        </Col> */}
    </Row>

{/*
    <Row>
        <Col className="mb-3">
          <img src={graph01} />
        </Col>
        <Col className="mb-3">
          <img src={graph02} />kmvkgbbb
        </Col>
  </Row>*/}
    </>
  );
}

export default FrontPage;

import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import UserInvitate from "views/dashboard/UserInvitate/UserInvitate"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

import { httpGetAccountUsers, } from "services/account_users"
import { httpInviteUser} from "services/users"
// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import { httpGetCompanies } from "services/companies";

import InvestmentTypeSelectSub from "components/Forms/InvestmentTypeSelectSub/InvestmentTypeSelectSub"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { set } from "date-fns";


const ImportNoBasicDataTable = ({match, history, basicDataRows, setBasicDataRows, setValidBasicDatas, model="Transaction"}) => {
  
  const { t } = useTranslation();

  // const loading = useStoreState((state) => state.portfolio?.loading);
  const portfoliosCount = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()


  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })


  const getHeaders = (param) => {
  const headers = [
    { label: t("import_no_basic_data_ticker_symbol", "Symbol"), key: "ticker_symbol"},
    { label: t("import_no_basic_data_name", "Arvopaperin nimi"), key: "name"},
      { label: t("import_no_basic_data_security_type", "Tyyppi"), key: "security_type"},
      { label: t("import_no_basic_data_security_currency", "Valuutta "), key: "currency_label"},
      { label: t("import_no_basic_data_security_size", "Arvopaperin koko "), key: "size"},
  ];
  return headers
  }

  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }

  const handleInvestmentTypeChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].security_type = value;
        setBasicDataRows(newRows);
    }
    }

  const handleSizeInputChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].size = value;
        setBasicDataRows(newRows);
    }
  }
  const handleNameInputChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].name = value;
        setBasicDataRows(newRows);
    }
  }
  const handleSymbolInputChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].ticker_symbol = value;
        setBasicDataRows(newRows);
    }
  }
  const handleCurrencySelectChange = (index) => {
    return (e) => {
        const { value } = e.target;
        const newRows = [...basicDataRows];
        newRows[index].currency_label = value;
        setBasicDataRows(newRows);
    }
  }

  const getTableRows = useCallback(() => {

    let pluralBasicDataSymbol = {}
    let pluralBasicDataName = {}

    let validBasicDatas = true

    for (const security of basicDataRows) {
      if (pluralBasicDataSymbol[security?.ticker_symbol]) {
        pluralBasicDataSymbol[security?.ticker_symbol]++;
      } else {
        pluralBasicDataSymbol[security?.ticker_symbol] = 1;
      }

      if (security?.name == "" || security?.name == null) {
        validBasicDatas = false
      }

      if (pluralBasicDataName[security?.name]) {
        pluralBasicDataName[security?.name]++;
      } else {
        pluralBasicDataName[security?.name] = 1;
      }
    }
    if (setValidBasicDatas){
      setValidBasicDatas(validBasicDatas)
    }
    
    return basicDataRows && basicDataRows.map((row, index) => {
      let ticker_symbol = row?.ticker_symbol && pluralBasicDataSymbol[row?.ticker_symbol] > 1?(<>{row?.ticker_symbol}<span className="text-danger">**</span></>):row?.ticker_symbol
      if (model=="Option"){
        ticker_symbol = (
          <h4 className=" text-dark m-0">
          {
           <Input 
              name="ticker_symbol" 
              onChange={ handleSymbolInputChange(index)} 
              value={row?.ticker_symbol}
              onWheel={(e) => e.target.blur()}
              disabled={false}
              placeholder={ t("-")}
            />
              
          }
          </h4>
        )
      }

      return {
        ...row,
        // ticker_symbol: ticker_symbol,
        name: (
            <h4 className=" text-dark m-0">
            {
             <Input 
             name="name" 
             onChange={ handleNameInputChange(index)} 
             value={row?.name}
             onWheel={(e) => e.target.blur()}
             disabled={false}
             placeholder={ t("-")}
           />
            
            }
            </h4>
        ),
        ticker_symbol: ticker_symbol,
        security_type: (<InvestmentTypeSelectSub label =" " value={row?.security_type} defaultValue="private_stock" onChange={ handleInvestmentTypeChange(index)}/>),
        currency_label: (<CurrencySelect label =" " noDefault={true} value={row?.currency_label} defaultValue="EUR" onChange={ handleCurrencySelectChange(index)}/>),
        size: (   <Input 
            name="size" 
            onChange={ handleSizeInputChange(index)} 
            defaultValue={0}
            value={row?.size}
            type="number"
            onWheel={(e) => e.target.blur()}
            disabled={false}
            placeholder={t("unmade_split_transactions_list_new_quantity", t("Koko"))}
          />),
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("edit_button","Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [basicDataRows])
  

  
  // Modal: New/Edit company
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <div className="p-2">
        </div>
      </div>
    
      <PageHeader className="text-dark mb-0" title={t("Luo arvopaperit")}>  <i className="fas fa-check-circle text-secondary mr-2"></i>{t("Luo arvopaperit")}</PageHeader>
      <Typography variant="small" className="mb-3">{t("Lähetetyissä tiedoissa oleville yrityksille ei löytynyt vastaavuutta. Määrittele tiedot ja luo yritykset. Mikäli löytyi useampi arvopaperi samalla symbolilla (**) tarkista tilanne ja luo vain tarvittavat.")} </Typography>
      <TableAuto
        showRowNumber={true}
        showId={false}
        checkboxes={false}
        headers={getHeaders()}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={false}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={null}
        setFilters={setFilters}
      />
      
      <Modal
        title={t("companies_list_company_title","Yritys")}
        isOpen={modalEditOpen}
        toggleModal={toggleModalEdit}
      >
        <CompanyEdit
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
      </Modal>
    </>
  );
}

export default ImportNoBasicDataTable;
import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { useAuthContext } from "contexts/AuthContext"

// Storybook
import { Row, Col } from "stories/layout";
import { Input, DatePicker } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"



// App
import OptionEdit from "views/app/OptionEdit/OptionEdit"
import { getDate } from "services/datetime"
import { useAppContext } from "contexts/AppContext"
import { round, thousandFormat } from "services/helpers"
import ValueColor from "components/ValueColor"
import OptionAssetTypeInput from "components/Forms/OptionAssetTypeInput/OptionAssetTypeInput"
import OptionSecuritySelect from "components/Forms/OptionSecuritySelect/OptionSecuritySelect"
import OptionStateInput from "components/Forms/OptionStateInput/OptionStateInput"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import { httpGetOptionsExcel } from "services/options"
import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"
import ImportExcelOptions from 'views/app/ImportExcel/ImportExcelOptions'



const rights = [
  {key: 2, value: "Call option"},
  {key: 3, value: "Put option"},
]

const defaultFilters = {
  offset: 0,
  limit: 100,
  ordering: "-id",
}

var date = new Date();

const OptionsList = ({match, history}) => {
  
  const { t } = useTranslation();
  const { enums, getOptionsAssetType, getDerivativeType } = useAppContext();
  const account = useStoreState((state) => state.account?.account);
  const account_loading = useStoreState((state) => state.account?.loading);

  const {accountUserType} = useAuthContext()
  
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  
  const [rows, setRows] = useState()
  const [statistics, setStatistics] = useState({})
  const [loadingExport, setLoadingExport] = useState(false)
  
  const loading = useStoreState((state) => state.options?.loading);
  const options = useStoreState((state) => state.options?.options);
  const getOptions = useStoreActions((actions) => actions.options.getOptions);
  const setOptions = useStoreActions((actions) => actions.options.setOptions);
  const count = useStoreState((state) => state.options?.count);
  const setCount = useStoreActions((actions) => actions.options.setCount);
  
  const [headers, setHeaders] = useState([
    { label: t("options_list_id", "ID" ), key: "id", sortable: true, sortingKey: "id"},
    { label: t("options_list_trade_id", "Trade id" ), key: "trade_id", sortable: true, sortingKey: "trade_id"},
    { label: t("options_list_derivative_type", "Derivative type" ), key: "derivative_type", sortable: true, sortingKey: "derivative_type"},
    { label: t("options_list_asset_type", "Asset type" ), key: "asset_type", visible: false, sortable: true, sortingKey: "asset_type"},
    { label: t("options_list_asset", "Asset" ), key: "asset", sortable: true, sortingKey: "asset_name"},
    // { label: t("options_list_right", "Right" ), key: "right", sortable: true, sortingKey: "option_right"},
    { label: t("options_list_owner", "Owner" ), key: "owner", sortable: true, sortingKey: "owner_name"},
    { label: t("options_list_writer", "Writer" ), key: "writer", sortable: true, sortingKey: "writer_name"},
    { label: t("options_list_lot_size", "Lot size" ), key: "lot_size", sortable: true, sortingKey: "lot_size"},
    { label: t("options_list_lot_amount", "Lots amount" ), key: "lot_amount", sortable: true, sortingKey: "lot_amount"},
    { label: t("options_list_quantity", "Total quantity" ), key: "quantity", sortable: true, sortingKey: "quantity"},
    { label: t("options_list_start_date", "Start date" ), key: "start_date", sortable: true, sortingKey: "start_date"},
    { label: t("options_list_end_date", "End date" ), key: "end_date", sortable: true, sortingKey: "end_date"},
    { label: t("options_list_premium", "Premium/ Share" ), key: "premium", sortable: true, sortingKey: "premium"},
    { label: t("options_list_premium_total", "Premium total" ), key: "premium_total", sortable: true, sortingKey: "premium_total"},
    { label: t("options_list_other_expenses", "Other exp." ), key: "other_expenses", sortable: true, sortingKey: "other_expenses"},
    { label: t("options_list_strike_price", "Strike price" ), key: "strike_price", sortable: true, sortingKey: "strike_price"},
    { label: t("options_list_spot_price", "Spot price" ), key: "spot_price"},
    { label: t("options_list_current_outcome", "Current outcome" ), key: "current_outcome", visible: false },
    { label: t("options_list_state", "State" ), key: "state", sortable: true, sortingKey: "option_state"},
    { label: t("options_list_option_state_date", "State date" ), key: "option_state_date", sortable: true, sortingKey: "option_state_date"},
    { label: t("options_list_exercise_price", "Exercise price" ), key: "exercise_price", sortable: true, sortingKey: "exercise_price"},
    { label: t("options_list_termination_price", "Termination price" ), key: "termination_fee", sortable: true, sortingKey: "termination_fee"},
    { label: t("options_list_exercise_outcome", "Exercise outcome" ), key: "exercise_outcome", sortable: true, sortingKey: "exercise_outcome"},
    { label: t("options_list_notes", "Notes" ), key: "notes"},
    { label: t("options_list_actions", " " ), key: "actions"},
  ]);
  
  // BEGIN
  
  const [currentDate, setCurrentDate] = useState(date.setDate(date.getDate() - 1)); // default: yesterday
  
  const handleDateChange = event => {
    setCurrentDate(event.target.value)
  }
  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  const getOption = (id) => {
    return options && options.reduce((result, item) => {
      if (item.id === id) { result = item }
      return result
    }, null)
  }
  
  const getSpotPrice = (id) => {
    const _option = getOption(id)
    return _option?.spot_price ? _option?.spot_price : 0
  }
  
  const getCurrentOutcome = (id) => {
    let row = getOption(id)
    let spotPrice = getSpotPrice(id)
    let option_right = row?.option_right
    let lot_amount = row?.lot_amount ? parseFloat(row?.lot_amount) : 0
    let quantity = row?.quantity ? parseFloat(row?.quantity) : 0
    let strike_price = row?.strike_price ? parseFloat(row?.strike_price) : 0
    let premium_total = row?.premium_total ? round(parseFloat(row?.premium_total), 2) : 0
    // premium = premium * lot_amount
    let other_expenses = row?.other_expenses ? parseFloat(row?.other_expenses) : 0
    
    let return_value = 0;
    
    if (option_right === 2) {
      if (spotPrice > strike_price) {
        return_value = (quantity * spotPrice) - (quantity * strike_price) - premium_total - other_expenses;
      }
      else {
        return_value = -premium_total - other_expenses
      }
    }
    else if (option_right === 3) {
      return_value = (quantity * strike_price) - (quantity * spotPrice) - premium_total - other_expenses;
    }
    return round(return_value, 2)
  }
  
  const getRightValue = (key) => {
    if (!key) {
      return ""
    }
    const obj = rights && rights.filter((item) => {
        return item?.key == key
      })[0]
    if (obj) {
      return t(obj?.value)
    }
    return "";
  }
  
  const getStateValue = (key) => {
    if (!key) {
      return ""
    }
    const option_states = enums?.options?.option?.option_state;
    const obj = option_states && option_states.filter((item) => {
        return item?.key == key
      })[0]
    if (obj) {
      return t(obj?.value)
    }
    return "";
  }
  
  // END

  // Update subscription BEGIN

    const openUpdateUp = useCallback(() => {
      return (<>{account?.license?.limit_feature_options && <UpdateUp redirectToFrontpage={true}/>}</>)
    }, [account?.license])
  // Update subscription END

  // Table
  const [filters, setFilters] = useState({
    ...defaultFilters,
    initialSearchDone: false,
  })
  
  const handleClearFilters = () => {
    setFilters(defaultFilters)
  }
  
  const handleGetOptions = () => {
    const params = {
      ...filters,
      start_date_from: filters?.start_date_from ? moment(filters?.start_date_from).format("YYYY-MM-DD") : undefined,
      start_date_to: filters?.start_date_to ? moment(filters?.start_date_to).format("YYYY-MM-DD") : undefined,
      end_date_from: filters?.end_date_from ? moment(filters?.end_date_from).format("YYYY-MM-DD") : undefined,
      end_date_to: filters?.end_date_to ? moment(filters?.end_date_to).format("YYYY-MM-DD") : undefined,
      stock_date: filters?.stock_date ? moment(filters?.stock_date).format("YYYY-MM-DD") : undefined,
      asset_name: filters?.option_security,
    }
    if (params?.portfolio && params?.stock_date) {
      getOptions(params)
    }
  }
  
  const handleSubmit = () => {
    handleGetOptions()
  }
  
  
  useEffect(() => {
    if (filters?.initialSearchDone === false && filters?.portfolio && filters?.portfolio > 0 && filters?.stock_date) {
      handleGetOptions()
      setFieldValue("initialSearchDone", true)
    }
  }, [filters])
  
  
  
  
  useEffect(() => {
    setFilters(s => ({
      ...s,
      portfolio: portfolio?.id,
    }))
  }, [portfolio])
  
  useEffect(() => {
    setFilters(s => ({
      ...s,
      stock_date: currentDate
    }))
    
  }, [currentDate])
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  useEffect(() => {
    let _statistics = {
      current_outcome: 0,
      current_open_outcome: 0,
      completed_outcome: 0,
      rows_count: 0,
      rows_open: 0,
      rows_open_pct: 0,
      rows_exercised: 0,
      rows_exercised_pct: 0,
      rows_expired: 0,
      rows_expired_pct: 0,
      rows_terminated: 0,
      rows_terminated_pct: 0,
      completed_count: 0, // Exercised or Expired
      completed_won: 0,
      completed_won_pct: 0,
      completed_lost: 0,
      completed_lost_pct: 0,
    }
    
    setRows(
      options && options.map((row, index) => {
        const optionId = row?.id;
        const optionDerivativetype = getDerivativeType(row?.derivative_type)
        const optionAssetType = getOptionsAssetType(row?.asset_type)
        
        const current_outcome = round(getCurrentOutcome(optionId), 4)
        _statistics.current_outcome += current_outcome
        const exercise_outcome = row?.exercise_outcome ? row?.exercise_outcome : 0.0 // round(getExcerciseOutcome(optionId), 4)
        _statistics.completed_outcome += parseFloat(exercise_outcome);
        
        _statistics.rows_count += 1
        if (row?.option_state === 2) {
          _statistics.rows_open += 1
          _statistics.current_open_outcome += current_outcome
        }
        else if (row?.option_state === 3) {
          _statistics.rows_exercised += 1
        }
        else if (row?.option_state === 4) {
          _statistics.rows_expired += 1
        }
        else if (row?.option_state === 5) {
          _statistics.rows_terminated += 1
        }
        
        if (row?.option_state === 3 || row?.option_state === 4 || row?.option_state === 5) {
          _statistics.completed_count += 1
        
          if (exercise_outcome >= 0) {
            _statistics.completed_won += 1
          }
          else if (exercise_outcome < 0) {
            _statistics.completed_lost += 1
          }
        }
        
        return {
          ...row,
          current_outcome: round(current_outcome, 2),
          // exercise_outcome: round(exercise_outcome, 2), // now from backend
        }
      })
    )
    
    _statistics.rows_open_pct = round((_statistics.rows_open / _statistics.rows_count * 100.0), 2) 
    _statistics.rows_exercised_pct = round((_statistics.rows_exercised / _statistics.rows_count * 100.0), 2) 
    _statistics.rows_expired_pct = round((_statistics.rows_expired / _statistics.rows_count * 100.0), 2)
    _statistics.rows_terminated_pct = round((_statistics.rows_terminated / _statistics.rows_count * 100.0), 2)
    
    _statistics.completed_won_pct = round((_statistics.completed_won / _statistics.completed_count * 100.0), 2) 
    _statistics.completed_lost_pct = round((_statistics.completed_lost / _statistics.completed_count * 100.0), 2) 
    
    _statistics.current_open_outcome = round(_statistics.current_open_outcome, 2)
    _statistics.current_outcome = round(_statistics.current_outcome, 2)
    _statistics.completed_outcome = round(_statistics.completed_outcome, 2)
    
    setStatistics(_statistics)
  }, [options, currentDate])
  
  const getTableRows = useCallback(() => {
    return rows && rows.map((row, index) => {
      const optionId = row?.id;
      const optionAssetType = getOptionsAssetType(row?.asset_type)
      const derivativeType = getDerivativeType(row?.derivative_type)
      const spotPrice = getSpotPrice(optionId);
      const strikePrice = row?.strike_price ? thousandFormat(round(row?.strike_price, 4).toFixed(4)) : '0.0000'
      
      return {
        ...row,
        id: optionId,
        derivative_type: derivativeType ? derivativeType?.value : "-",
        right: getRightValue(row?.option_right),
        asset: row?.asset ? row?.asset?.name : row?.asset_name,
        asset_type: optionAssetType ? optionAssetType?.value : "-",
        owner: row?.owner_name,
        writer: row?.writer_name,
        lot_size: {
          content: thousandFormat(row?.lot_size),
          className: "text-right",
        },
        lot_amount: {
          content: thousandFormat(row?.lot_amount),
          className: "text-right",
        },
        quantity: {
          content: thousandFormat(row?.quantity),
          className: "text-right",
        },
        start_date: row?.start_date ? getDate(row?.start_date) : "-",
        end_date: row?.end_date ? getDate(row?.end_date) : "-",
        premium: {
          content: thousandFormat(row?.premium),
          className: "text-right",
        },
        premium_total: {
          content: row?.premium_total ? thousandFormat(round(row?.premium_total, 2).toFixed(2)) : '0.00',
          className: "text-right",
        },
        other_expenses: {
          content: row?.other_expenses ? thousandFormat(round(row?.other_expenses, 2).toFixed(2)) : '0.00',
          className: "text-right",
        },
        termination_fee: {
          content: row?.termination_fee ? thousandFormat(round(row?.termination_fee, 2).toFixed(2)) : '0.00',
          className: "text-right",
        },
        strike_price: {
          content: strikePrice,
          className: "text-right",
        },
        spot_price: {
          content: spotPrice ? thousandFormat(round(spotPrice, 4).toFixed(4)) : '0.0000',
          className: "text-right",
        },
        current_outcome: <ValueColor value={row?.current_outcome} />,
        state: getStateValue(row?.option_state),
        option_state_date: row?.option_state_date ? getDate(row?.option_state_date) : "-",
        exercise_date: row?.exercise_date ? getDate(row?.exercise_date) : "-",
        exercise_price: row?.option_state > 2 ? (row?.exercise_price ? row?.exercise_price : "-") : "",
        exercise_outcome: {
          content: (<ValueColor value={row?.exercise_outcome} />),
          className: "text-right",
        }, 
        notes: row?.notes && row?.notes.length > 0 ? t("sk_yes","Yes") : t("sk_yes","No"),
        actions: (
          <Button className="btn-sm" onClick={() => openModalEditOption(optionId)}>{t("edit_button", "Edit")}</Button>
        ),
      }
    })
  }, [rows])

  // Modal: subscription options info
    
  const [modalEditOpenSubscriptionOptions, setModalEditOpenSubscriptionOptions] = useState(true)
  const [modalEditIdSubscriptionOptions, setModalEditIdSubscriptionOptions] = useState()
  
  const toggleModalEditSubscriptionOptions = () => {
      setModalEditOpenSubscriptionOptions(s => !s);
  }
  
  const openModalEditSubscriptionOptions = (id) => {
      setModalEditOpenSubscriptionOptions(true)
      setModalEditIdSubscriptionOptions(id);
  }
  
  const modalEditResolveSubscriptionOptions = () => {
      setModalEditOpenSubscriptionOptions(false);
      setModalEditIdSubscriptionOptions(null);
  }
  
  const modalEditRejectSubscriptionOptions = () => {
      setModalEditOpenSubscriptionOptions(false);
      setModalEditIdSubscriptionOptions(null);
  }
  
  // Modal: New/Edit option
  
  const [modalEditOptionOpen, setModalEditOptionOpen] = useState(false)
  const [modalEditOptionId, setModalEditOptionId] = useState()
  
  const toggleModalEditOption = () => {
    setModalEditOptionOpen(s => !s);
  }
  
  const openModalEditOption = (id) => {
    setModalEditOptionOpen(true)
    setModalEditOptionId(id);
  }
  
  const modalEditOptionResolve = () => {
    setModalEditOptionOpen(false);
    setModalEditOptionId(null);
    handleGetOptions()
  }
  
  const modalEditOptionReject = () => {
    setModalEditOptionOpen(false);
    setModalEditOptionId(null);
  }



  // Modal: import

  const [modalEditImportOpen, setModalEditImportOpen] = useState(false)
  const [modalEditImportId, setModalEditImportNordeaId] = useState()
  
  const toggleModalEditImport = () => {
    setModalEditImportOpen(s => !s);
  }
  
  const openModalEditImport = (id, related_model_type, ticker_symbol) => {
    setModalEditImportOpen(true)
    setModalEditImportNordeaId(id);
  }
  
  const modalEditImportResolve = () => {
    setModalEditImportOpen(false);
    setModalEditImportNordeaId(null);
    handleGetOptions()
  }
  
  const modalEditImportReject = () => {
    setModalEditImportOpen(false);
    setModalEditImportNordeaId(null);
  
  }
  
  const handleExcelExport = () => {
    
    const params = {
      ...filters,
      start_date_from: filters?.start_date_from ? moment(filters?.start_date_from).format("YYYY-MM-DD") : undefined,
      start_date_to: filters?.start_date_to ? moment(filters?.start_date_to).format("YYYY-MM-DD") : undefined,
      end_date_from: filters?.end_date_from ? moment(filters?.end_date_from).format("YYYY-MM-DD") : undefined,
      end_date_to: filters?.end_date_to ? moment(filters?.end_date_to).format("YYYY-MM-DD") : undefined,
      stock_date: filters?.stock_date ? moment(filters?.stock_date).format("YYYY-MM-DD") : undefined,
      ordering: '-id',
      client_timezone_offset: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    
    let fileName = "investime_derivatives_export.xlsx";
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    
    setLoadingExport(true)
    httpGetOptionsExcel(params).then(response => {
      let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      let fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    })
    .finally(() => {
      setLoadingExport(false)
    })
  }

  if (account_loading) {
    return(
      <div className="m-5"></div>
    )
  }

  const handleEmailClick = () => {
      window.location.href = 'mailto:support@investime.fi';
  };
  
  
  if (account?.license?.limit_feature_options && false) {
    
    return(
      <>
      <div className="d-flex justify-content-between">
        <div className="p-3">
    
          <Typography bold variant="h3" className="text-dark mt-3 mb-5">{t("Toiminto ei ole käytössä tilauksellanne.")}</Typography>
    
        <Typography variant="medium" className="text-dark mt-3 mb-1">{t("Muuttaaksesi tilausta ota yhteyttä tukipalveluumme.")}</Typography>
        <Button variant="secondary" >
            <Link href="https://www.investime.fi/ota-yhteytta/" target="_blank" className="text-dark">{t("subscription_options_info_change_subcription","Vaihda tilausta")}</Link>
        </Button>

        </div>
      </div>
      <Modal
        title={t("Derivative")}
        isOpen={modalEditOpenSubscriptionOptions}
        toggleModal={toggleModalEditSubscriptionOptions}
        size="modal-xl"
        showHeader={false}
        classNameBody="p-0 padding-0"
      >
        <SubscriptionOptionsInfo
          objectId={modalEditIdSubscriptionOptions}
          handleResolve={modalEditResolveSubscriptionOptions}
          handleReject={modalEditRejectSubscriptionOptions}
          custom_info_text= {t("subscription_options_info_text", "Tämä toiminto ei ole käytössä tilauksellanne")}
        />
      </Modal>
    </>
    )
    
    /*
    return(
      <>
        <div className="d-flex justify-content-between">
          <div className="p-3">
       
            <Typography bold variant="h3" className="text-dark mt-3 mb-5">{t("Toiminto ei ole käytössä tilauksellanne.")}</Typography>
      
          <Typography variant="medium" className="text-dark mt-3 mb-1">{t("Muuttaaksesi tilausta ota yhteyttä tukipalveluumme.")}</Typography>
          <Typography bold variant="h4" className="text-dark mt-2 mb-1">
            <a href="mailto:support@investime.fi" onClick={handleEmailClick}>
              {t("support@investime.fi")}
            </a>
          </Typography>

          </div>
        </div>
      </>          
    )
    */
   
  }



  return (
    <>
      {openUpdateUp()}

      <div className="d-flex justify-content-between">
        <div className="p-3">
          <Button color="secondary" disabled={accountUserType==="read_only" } onClick={() => openModalEditOption(null)}>{t("new_option_button", "New derivative")}</Button>
        </div>
        <div className="text-right">
          {t("Portfolio", "Portfolio")}: { portfolio?.name }
          
          <div className="p-2">
            <label className="mb-1 pr-1 header-bold">
              {t("options_list_spot_date", "Spot date")}
            </label>
            <DatePicker
              className="form-control"
              selected={currentDate}
              name="stock_date"
              onChange={(date) => setCurrentDate(date)}
              showYearDropdown
              dateFormat="d.M.yyyy"
            />
            
            {/*
              <Input label={t("options_list_spot_date", "Spot date")} name="stock_date" type="date" value={currentDate} onChange={handleDateChange} />
            */}
          </div>
          
        </div>
      </div>
      
      <div className="d-flex flex-wrap align-items-start align-self-start">

          <div className="p-2 pr-4">
            <OptionAssetTypeInput
              name="asset_type"
              onChange={handleChange}
              label={t("options_list_asset_type", "Asset type")}
              allOption={true}
              value={filters?.asset_type}
            />
          </div>

          <div className="p-2 pr-4">
            <OptionSecuritySelect
              name="option_security"
              onChange={handleChange}
              label={t("options_list_security", "Kohde etuus")}
              allOption={true}
              options={options}
              asset_type= {filters?.asset_type}
              payload={{portfolio: filters?.portfolio, stock_date: filters?.stock_date, asset_type: filters?.asset_type }}
              value={filters?.option_security}
            />
          </div>

          
          <div className="p-2 pr-4">
            <OptionStateInput
              name="option_state"
              onChange={handleChange}
              label={t("option_edit_state", "State")}
              allOption={true}
              value={filters?.option_state}
            />
          </div>
          
          <div className="p-2 pr-4">
            <Input
              name="trade_id"
              label={t("options_list_trade_id", "Trade id")}
              value={filters?.trade_id}
              onChange={handleChange} 
            />
          </div>
            
          <div className="p-2">
            <label className="mb-1 pr-1 header-bold">
              {t("options_list_start_date_from", "Start date (from)")}
            </label>
            <DatePicker
              className="form-control input-group-alternative"
              selected={filters?.start_date_from}
              name="start_date_from"
              onChange={(date) => setFieldValue("start_date_from", date)}
              showYearDropdown
              dateFormat="d.M.yyyy"
            />
          </div>
          <div className="p-2 pr-4">
            <label className="mb-1 pr-1 header-bold">
              {t("options_list_start_date_to", "Start date (to)")}
            </label>
            <DatePicker
              className="form-control input-group-alternative"
              selected={filters?.start_date_to}
              name="start_date_to"
              onChange={(date) => setFieldValue("start_date_to", date)}
              showYearDropdown
              dateFormat="d.M.yyyy"
            />
          </div>
          
          <div className="p-2">
            <label className="mb-1 pr-1 header-bold">
              {t("options_list_end_date_from", "End date (from)")}
            </label>
            <DatePicker
              className="form-control input-group-alternative"
              selected={filters?.end_date_from}
              name="end_date_from"
              onChange={(date) => setFieldValue("end_date_from", date)}
              showYearDropdown
              dateFormat="d.M.yyyy"
            />
          </div>
          <div className="p-2 pr-4">
            <label className="mb-1 pr-1 header-bold">
              {t("options_list_end_date_to", "End date (to)")}
            </label>
            <DatePicker
              className="form-control input-group-alternative"
              selected={filters?.end_date_to}
              name="end_date_to"
              onChange={(date) => setFieldValue("end_date_to", date)}
              showYearDropdown
              dateFormat="d.M.yyyy"
            />
          </div>
          
          {/*
          <div className="p-2">
            <Input label={t("options_list_start_date_from", "Start date (from)")} name="start_date_from" type="date" value={filters?.start_date_from} onChange={handleChange} />
          </div>
          <div className="p-2 pr-4">
            <Input label={t("options_list_start_date_to", "Start date (to)")} name="start_date_to" type="date" value={filters?.start_date_to} onChange={handleChange} />
          </div>
          
          <div className="p-2">
            <Input label={t("options_list_end_date_from", "End date (from)")} name="end_date_from" type="date" value={filters?.end_date_from} onChange={handleChange} />
          </div>
          <div className="p-2 pr-4">
            <Input label={t("options_list_end_date_to", "End date (to)")} name="end_date_to" type="date" value={filters?.end_date_to} onChange={handleChange} />
          </div>
          */}
          

          
      </div>

      <div className="mb-3">
        <div className="px-2">
          <Button variant="secondary" onClick={handleSubmit}>{t("web_search_button","Search")}</Button>
        </div>
        <div className="px-2">
          <Button variant="link" onClick={handleClearFilters}>{t("web_clear_filters_button","Clear filters")}</Button>
        </div>
      </div>
      
      <TableAuto
        showRowNumber={false}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        filters={filters}
        setFilters={setFilters}
      />
      
      <div className="d-flex flex-row-reverse">
        <div className="p-2">
          <Button color="secondary" size="sm" disabled={loadingExport} onClick={handleExcelExport}>
            <i className="fas fa-file-excel"></i>&nbsp;
            {t("web_export_to_excel","Export to Excel")}
          </Button>
        </div>

        <div className="p-2">
          {/* <Button color="secondary" size="sm" disabled={loadingExport} onClick={handleExcelExport}> */}
          <Button className="btn-sm" color="secondary" onClick={() => openModalEditImport(null)}>
            <i className="fas fa-file-excel"></i>&nbsp;
            {t("web_import_excel","Tuo Excel")}
          </Button>
        </div>
      </div>
      
      <>
        <Row>
          <Col>{t("web_summary","Summary")}</Col>
        </Row>
        
        { loading ? (
          <Spinner />
        ) : (
          <Row>
            <Col className="col-sm-12 col-md-7">
              <Typography variant="small">
                <Row>
                  <Col>{t("web_derivatives_open","Derivatives open")}</Col>
                  <Col>{statistics?.rows_open} ({statistics?.rows_open_pct}%)</Col>
                </Row>
                
                <Row>
                  <Col>{t("web_derivatives_exercised","Derivatives exercised")}</Col>
                  <Col>{statistics?.rows_exercised} ({statistics?.rows_exercised_pct}%)</Col>
                </Row>
                
                <Row>
                  <Col>{t("web_derivatives_expired","Derivatives expired")}</Col>
                  <Col>{statistics?.rows_expired} ({statistics?.rows_expired_pct}%)</Col>
                </Row>
                
                <Row>
                  <Col>{t("web_derivatives_terminated","Derivatives terminated")}</Col>
                  <Col>{statistics?.rows_terminated} ({statistics?.rows_terminated_pct}%)</Col>
                </Row>
                
                <Row>
                  <Col>{t("web_derivative_rows_total","Derivative rows total")}</Col>
                  <Col>{statistics?.rows_count} (100%)</Col>
                </Row>
                
                {/*
                <Row>
                  <Col>{t("web_current_outcome_all","Current outcome (all)")}</Col>
                  <Col><ValueColor value={statistics?.current_outcome} /></Col>
                </Row>
                
                <Row>
                  <Col>{t("web_current_outcome_open","Current outcome (open)")}</Col>
                  <Col><ValueColor value={statistics?.current_open_outcome} /></Col>
                </Row>
                */}
                
                <Row>
                  <Col>{t("web_completed_outcome","Completed outcome (exercised, expired and terminated)")}</Col>
                  <Col><ValueColor value={statistics?.completed_outcome} /></Col>
                </Row>
                
                <Row>
                  <Col>{t("web_completed_won","Completed won")}</Col>
                  <Col>{statistics?.completed_won} ({statistics?.completed_won_pct}%)</Col>
                </Row>
                
                <Row>
                  <Col>{t("web_completed_lost","Completed lost")}</Col>
                  <Col>{statistics?.completed_lost} ({statistics?.completed_lost_pct}%)</Col>
                </Row>
              </Typography>
            </Col>
          </Row>
        )}
      </>

      
      <Modal
        title={t("web_derivative_title","Derivative")}
        isOpen={modalEditOptionOpen}
        toggleModal={toggleModalEditOption}
        size="modal-xl"
      >
        <OptionEdit
          objectId={modalEditOptionId}
          handleResolve={modalEditOptionResolve}
          handleReject={modalEditOptionReject}
        />
      </Modal>

      <Modal
        title={t("Excel tuonti")}
        showHeader={true}
        isOpen={modalEditImportOpen}
        // isOpen={true}
        toggleModal={toggleModalEditImport}
        closeModal={modalEditImportReject}
        size="modal-xl"
      >
        {/* <ImportTransactionsNordnet
          objectId={modalEditImportId}
          handleResolve={modalEditImportNordeaResolve}
          handleReject={modalEditImportNordeaReject}
         
          defaultStockMarket={filters?.stock_market}
          defaultInvestmentType={investmentType}
          setFilters={setFilters}
        /> */}
        <ImportExcelOptions
          objectId={modalEditImportId}
          handleResolve={modalEditImportResolve}
          handleReject={modalEditImportReject}
         
          defaultStockMarket={filters?.stock_market}
          // defaultInvestmentType={investmentType}
          setFilters={setFilters}
        />
      </Modal>
      
    </>
  );
}

export default OptionsList;

